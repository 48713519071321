import gql from "graphql-tag";

const SMALL_WHOLESALE = gql`
    query userProfile{
        userProfile {
            id
            customer {
                id
                basises {
                    items {
                        id
                        name
                        address
                        assortment {
                            id
                            trader {
                                id
                                name
                                shortName
                            }
                            product {
                                id
                                code
                                name
                                fullName
                            }
                            price
                            delta
                            updatedAt
                        }
                    }
                    total
                }
            }
        }
    }
`;

export default SMALL_WHOLESALE;
