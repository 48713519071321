import "./style.scss";

import {
    CircleLayoutProps,
    FeaturesMappingResult,
    GasStationMapProps,
    GasStationYandexMapData,
    GetDistanceBetweenCoordinates,
    GetOptimalZoomProps,
    ToRadians,
} from "../../lib/graphql/queries/GasStations/gasStations";
import {
    GeolocationControl,
    Map,
    Placemark,
    YMaps,
    ZoomControl,
} from "react-yandex-maps";
import React, { useCallback, useEffect, useState } from "react";

import MapTooltip from "./tooltip/MapTooltip";
import { mapFeaturesMapping } from "./dataMapping";
import { useYandexMapbox } from "lib/hooks/useYandexMapBox";

const GasStationYandexMapComponent: React.FC<GasStationMapProps> = ({
    points,
    FilterByRegion,
    ymaps,
}) => {
    const { data }: FeaturesMappingResult = mapFeaturesMapping(points);
    const [mapState, setMapState] = useState({
        center: [55.76, 37.64],
        zoom: 8,
    });
    const { getMarkData, tooltipVisible, tooltipData, setTooltipVisible } =
    useYandexMapbox();
    const [selectedMark, setSelectedMark] = useState<string | null>(null);

    const tooltip = (
        <MapTooltip
            tooltipData={tooltipData}
            setTooltipVisible={setTooltipVisible}
        />
    );

    const handleClick = useCallback(
        (id: string, coordinates: number[]) => {
            if(id === selectedMark) {
                getMarkData(null);
                setSelectedMark(null);
            } else {
                getMarkData(id);
                setMapState({ ...mapState, center: coordinates });
                setSelectedMark(id);
            }
        },
        [getMarkData, mapState]
    );
    const toRadians: ToRadians = (angle) => angle * (Math.PI / 180);

    const getDistanceBetweenCoordinates: GetDistanceBetweenCoordinates = (
        lat1,
        lng1,
        lat2,
        lng2
    ) => {
        const R = 6371; // радиус Земли в километрах
        const dLat = toRadians(lat2 - lat1);
        const dLng = toRadians(lng2 - lng1);

        const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) *
          Math.cos(toRadians(lat2)) *
          Math.sin(dLng / 2) *
          Math.sin(dLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c;
    };

    const getOptimalZoom: GetOptimalZoomProps = (
        minLat,
        maxLat,
        minLng,
        maxLng
    ) => {
        const WORLD_WIDTH = 256;
        const WORLD_HEIGHT = 256;

        const latDiff = maxLat - minLat;
        const lngDiff = maxLng - minLng;

        const mapWidth = window.innerWidth;
        const mapHeight = window.innerHeight;

        const zoomX = Math.log2((mapWidth * 360) / lngDiff / WORLD_WIDTH);
        const zoomY = Math.log2((mapHeight * 180) / latDiff / WORLD_HEIGHT);

        const minZoom = Math.min(zoomX, zoomY);

        const centerLat = (maxLat + minLat) / 2;
        const centerLng = (maxLng + minLng) / 2;

        const maxDistance = Math.max(
            getDistanceBetweenCoordinates(minLat, minLng, centerLat, centerLng),
            getDistanceBetweenCoordinates(maxLat, maxLng, centerLat, centerLng)
        );

        return maxDistance > 500
            ? Math.floor(minZoom * 0.8)
            : Math.ceil(minZoom - 1);
    };

    useEffect(() => {
        if (data.length > 0) {
            const bounds = data.reduce(
                (acc, mark) => {
                    const [lat, lng] = mark.geometry.coordinates;
                    acc.minLat = Math.min(acc.minLat, lat);
                    acc.maxLat = Math.max(acc.maxLat, lat);
                    acc.minLng = Math.min(acc.minLng, lng);
                    acc.maxLng = Math.max(acc.maxLng, lng);
                    return acc;
                },
                {
                    minLat: Infinity,
                    maxLat: -Infinity,
                    minLng: Infinity,
                    maxLng: -Infinity,
                }
            );

            const zoom = getOptimalZoom(
                bounds.minLat,
                bounds.maxLat,
                bounds.minLng,
                bounds.maxLng
            );
            const center = [
                (bounds.minLat + bounds.maxLat) / 2,
                (bounds.minLng + bounds.maxLng) / 2,
            ];

            if (data.length > 1) {
                setMapState({ center, zoom });
            } else {
                setMapState({ center, zoom: 15 });
            }
        }
    }, [data.length]);

    const apiKey = process.env.REACT_APP_YANDEX_API_KEY;

    const markCompetitors = useCallback(
        (mark: GasStationYandexMapData) => {
            return tooltipData.competitors?.find((elem) => elem.id === mark.id)
                ? `border: 3px solid #1890ff; width: 46px; height: 46px; z-index:1000`
                : `border: 3px solid #FFFFFF; z-index:5`;
        },
        [tooltipData]
    );

    const getImagePath = (mark: GasStationYandexMapData): { img: string; size: number } => {
        const isCompetitor = tooltipData.competitors?.find((elem) => elem.id === mark.id)
        if (selectedMark !== mark.id && !isCompetitor) {
            try {
                return {
                    img: require(`../../assets/logos/лого__${mark?.properties.brand}_1.svg`),
                    size: 85,
                };
            } catch (error) {
                return {
                    img: require(`../../assets/logos/лого__Бензоколонка_1.svg`),
                    size: 65,
                };
            }
        }
        try {
            return {
                img: require(`../../assets/logos/лого__${mark?.properties.brand}_2.svg`),
                size: 85,
            };
        } catch (error) {
            return {
                img: require(`../../assets/logos/лого__Бензоколонка_2.svg`),
                size: 65,
            };
        }
    };

    const circleLayout = ({ ymaps, mark }: CircleLayoutProps) => {
        const { img, size } = getImagePath(mark);
        const selectedMarkStyle = selectedMark !== mark.id ? markCompetitors(mark) : `border: 3px solid #53B178; width: 46px; height: 46px; z-index: 1000`;
        return ymaps?.templateLayoutFactory?.createClass(
            `<div class="placemark_layout_container"><div class="circle_layout" style="${selectedMarkStyle}"><img width="${size}%" height="${size - 10
            }%" src="${img}" alt=""/></div></div>`
        );
    };

    return (
        <div style={{ position: "relative" }}>
            <div className="map__picker__wrapper">{FilterByRegion}</div>
            {tooltipVisible && tooltip}
            <YMaps
                query={{
                    apikey: apiKey,
                }}
            >
                <Map style={{ width: "100%", height: "100vh" }} state={mapState}>
                    {data?.map((mark, i: number) => {
                        return (
                            <Placemark
                                onClick={() => handleClick(mark.id, mark.geometry.coordinates)}
                                key={i}
                                modules={["geoObject.addon.balloon"]}
                                geometry={mark.geometry.coordinates.reverse()}
                                options={{
                                    zIndex: mark.properties.zIndex,
                                    iconLayout: circleLayout({ ymaps, mark }),
                                    iconShape: {
                                        type: "Rectangle",
                                        coordinates: [
                                            [-15, -15],
                                            [15, 15],
                                        ],
                                    },
                                }}
                            />
                        );
                    })}
                    <ZoomControl options={{ float: "left" }} />
                    <GeolocationControl options={{ float: "left" }} />
                </Map>
            </YMaps>
        </div>
    );
};

export default GasStationYandexMapComponent;
