import React, { useEffect } from "react";
import {Col, Row} from "antd";
import ChangeLang from "components/_common/ChangeLang";

import "./style.scss";
import { useTranslation } from "react-i18next";

const AuthLayout: React.FC = ({children}) => {
    const { i18n } = useTranslation();

    useEffect( () => {
        if(!i18n.language){
            void i18n.changeLanguage('ru');
        }
    }, []);

    return (
        <main>
            <Row className="authorization" justify="center" align="top">
                <div className="authorization__lang">
                    <ChangeLang />
                </div>
                <Col className="authorization__background" span={14}/>

                <Col className="authorization__form" xs={24} md={10}>
                    {children}
                </Col>
            </Row>
        </main>
    )
};

export default AuthLayout;
