import { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";

import { defaultTooltipData } from "containers/GasStationsMapContainer/mapSettings";
import GAS_STATION from "lib/graphql/queries/GasStation";
import {
    GasStationProfile,
    GasStationVariables,
} from "lib/graphql/queries/GasStation/gasStation";
import { getGasStationTitle } from "../helpers";
import { FeatureProperties } from "components/GasStationsYandexMapComponent/tooltip/types";

interface MapboxHook {
  tooltipVisible: boolean;
  tooltipData: FeatureProperties;
  setTooltipVisible: (
    value: ((prevState: boolean) => boolean) | boolean
  ) => void;
  getMarkData: (id: string | null) => void;
}

export function useYandexMapbox(): MapboxHook {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipData, setTooltipData] =
    useState<FeatureProperties>(defaultTooltipData);

    const [getGasStation, { data }] = useLazyQuery<
    GasStationProfile,
    GasStationVariables
  >(GAS_STATION);

    useEffect(() => {
        if (data && tooltipVisible) {
            const { gasStation } = data;
            setTooltipData({
                brand: gasStation.name,
                number: gasStation.number,
                prices: gasStation.fuelPrices,
                address: gasStation.address,
                competitors: gasStation.competitors.map((competitor) => {
                    return {
                        id: competitor.id,
                        address: competitor.address,
                        brand: getGasStationTitle(competitor.name, competitor.number),
                        prices: competitor.fuelPrices,
                    };
                }),
            });
        }
    }, [data, tooltipVisible]);

    const getMarkData = (id: string | null) => {
        if(id === null) {
            setTooltipData(defaultTooltipData);
            setTooltipVisible(false);
        } else {
            getGasStation({
                variables: {
                    id: id,
                },
            });
            setTooltipVisible(true);    
        }
    };

    return {
        getMarkData,
        tooltipVisible,
        tooltipData,
        setTooltipVisible,
    };
}
