import GasStationMapItem from "lib/graphql/fragments/GasStation/GasStationMapItems"
import gql from "graphql-tag";

const GAS_STATIONS_LIST = gql`
    query userProfile {
        userProfile {
            id
            customer {
                id
                gasStations {
                    items {
                        ...GasStationMapItem
                        competitors {
                            name
                            ...GasStationMapItem
                        }
                        region {
                            id
                            name
                        }
                        name
                    }
                    total
                }
            }
        }
    }
    ${GasStationMapItem}
`;

export default GAS_STATIONS_LIST;



