import React, {useEffect, useState} from "react";
import {useLazyQuery} from "@apollo/react-hooks";
import {Table} from "antd";
import {ColumnGroupType, ColumnType} from "antd/lib/table";

import {useGasStationColumns} from "lib/hooks/useGasStationColumns";
import {ownGasStationClass} from "lib/helpers/actualPrices";
import GAS_STATION from "lib/graphql/queries/GasStation";
import {GasStationProfile, GasStationVariables} from "lib/graphql/queries/GasStation/gasStation";
import {ActualPriceTableRow} from "components/ActualPriceComponent/types";
import {actualPrices} from "containers/ActualPriceContainer/dataMapping";

import "./style.scss";

type columnsType = ColumnGroupType<ActualPriceTableRow> | ColumnType<ActualPriceTableRow>;

interface ExpandedTableProps {
    gasStationId: string;
    expanded: boolean;
    tableHeaders: Array<string>;
}

const ExpandedTable: React.FC<ExpandedTableProps> = ({
    expanded,
    gasStationId,
    tableHeaders,
}) => {
    const [onFilters, setOnFilters] = useState('');

    const [tableData, setTableData] = useState<ActualPriceTableRow[]>([]);
    const [tableColumns, setTableColumns] = useState<columnsType[]>([]);
    const [getGasStation, {data, loading}] = useLazyQuery<GasStationProfile, GasStationVariables>(GAS_STATION);

    const {columns} = useGasStationColumns({
        headers: tableHeaders,
        translateString: "reports.actualPrice.fuel",
        onFilters,
        zeroHeader: "brand",
        sorter: false
    });


    useEffect(() => {
        setTableColumns([...columns].map(
            (item) => {
                item.sorter = true;

                return item;
            })
        )

    }, [columns]);

    useEffect(() => {
        if (!expanded) return;

        getGasStation({
            variables: {
                id: gasStationId
            }
        });

    }, [expanded, gasStationId]);

    useEffect(() => {
        if (data) {
            setTableData([]);

            setTableData(actualPrices([data.gasStation]));
        }
    }, [data]);


    const sortPrices = (
        items: Array<ActualPriceTableRow>,
        field: string,
        sortDirection: 'descend' | 'ascend' | undefined | null,
    ): Array<ActualPriceTableRow> => {
        const compare = (a: ActualPriceTableRow, b: ActualPriceTableRow) => {
            let direction = 1;

            if (sortDirection === 'ascend') {
                direction = -1;
            }

            if (field === 'updatedAt' && a.updatedAt && b.updatedAt) {
                return a.updatedAt.diff(b.updatedAt) * direction;
            }

            const fieldA = a.products.hasOwnProperty(field) ? a.products[field].price : 0;

            const fieldB = b.products.hasOwnProperty(field) ? b.products[field].price : 0;

            return (fieldA - fieldB) * direction;
        };

        if (sortDirection === undefined && data) {
            return actualPrices([data.gasStation]);
        }

        return items.sort(compare);
    };

    return (
        <Table
            className="expanded-table"
            dataSource={tableData}
            columns={tableColumns}
            showSorterTooltip={false}
            scroll={{
                x: 1000,
            }}
            rowClassName={ownGasStationClass}
            pagination={false}
            loading={loading}
            onChange={(_pagination, _filters, sorter) => {

                if (!Array.isArray(sorter) && sorter.columnKey !== undefined) {
                    setTableData(sortPrices(tableData, sorter.columnKey.toString(), sorter.order));

                    setOnFilters(sorter.columnKey.toString());
                    if (sorter.order === undefined) {
                        setOnFilters('');
                    }

                }

            }}
        />
    )
};

export default ExpandedTable
