import React from "react";
import {Avatar, Dropdown, Menu, Spin} from "antd";
import {useTranslation} from "react-i18next";
import { useQuery } from "@apollo/react-hooks";
import {MenuInfo} from "rc-menu/lib/interface"
import { LogoutOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

import TokenStorage from 'lib/TokenStorage';
import USER_PROFILE from "lib/graphql/queries/UserProfile";
import {userProfile as userProfileData} from "lib/graphql/queries/UserProfile/__generated__/userProfile";
import { isEnvForDebug } from "lib/helpers/index";

import "./style.scss";

const AvatarDropdown: React.FC = () => {
    const {t} = useTranslation();
    const { data, loading } = useQuery<userProfileData>(USER_PROFILE);

    if (loading || !data || !data.userProfile) {
        return (
            <span className="avatar-dropdown__account avatar-dropdown__overlay">
                <Spin
                    size="small"
                    style={{
                        marginLeft: 8,
                        marginRight: 8,
                    }}
                />
            </span>
        )
    }

    const onMenuClick = (event: MenuInfo) => {
        if (event.key == "handleLogout") {
            TokenStorage.clearToken()
        }
    };

    const menuHeaderDropdown = (
        <Menu className="avatar-dropdown" selectedKeys={[]} onClick={onMenuClick}>
            <Menu.Item key="handleLogout">
                <LogoutOutlined />
                {" "}
                {t('user.headerMenu.signOut')}
            </Menu.Item>
            {
                (isEnvForDebug()) ?
                    <Menu.Item>
                        <Link to="/user-settings">
                            <SettingOutlined />
                            {" "}
                            {t('user.headerMenu.settings')}
                        </Link>
                    </Menu.Item> : null
            }
        </Menu>
    );

    const {firstName, lastName, email} = data.userProfile;

    const userName = (firstName && lastName) ? `${firstName || ""} ${lastName || ""}` : email;

    return (
        <Dropdown overlay={menuHeaderDropdown}>
            <span className="avatar-dropdown__account avatar-dropdown__overlay">
                <Avatar
                    className="avatar-dropdown__account__avatar"
                    size="small"
                    icon={<UserOutlined />}
                    alt="avatar"
                />
                {
                    <span className="avatar-dropdown__account__user-name">{userName}</span>
                }
            </span>
        </Dropdown>
    );
};

export default AvatarDropdown
