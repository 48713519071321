import React, {Fragment, ReactNode, useState} from "react";
import {useTranslation} from "react-i18next";
import {Row} from "antd";

import {ChartTranslateStrings} from "./helper";
import PriceStatisticChart from "./PriceStatisticChart";
import {LineChartData} from "lib/graphql/queries/PriceStatistic/types";
import PageMainHeader from "components/_common/PageMainHeader";
import CardWrapper from "components/_common/CardWrapper";

import "./style.scss";

interface AnalyticsComponentProps {
    loading: boolean;
    fuelTypes: Array<string>;
    ownerStation: string;
    Filters: ReactNode;
    analytics: Array<LineChartData>;
}

export interface ScrollBarValue {
    start: number | null;
    end: number | null;
}

const AnalyticsComponent: React.FC<AnalyticsComponentProps> = ({
    loading,
    fuelTypes,
    analytics,
    ownerStation,
    Filters,
}) => {
    const {t} = useTranslation();
    const title = t('analytics.title');
    const translate: ChartTranslateStrings = {
        firstInput: "analytics.chart.wholesaleFilter.myWholesalePlaceholder",
        secondInput: "",
        thirdInput: "analytics.chart.filter.ownGasStation"
    };
    const [scrollBarValue, setScrollBarValue] = useState<ScrollBarValue>({
        start: null,
        end: null,
    } as ScrollBarValue);



    return (
        <Fragment>
            <PageMainHeader
                title={title}
            />

            <CardWrapper title={t('analytics.wrapperTitle')}>
                <Row className="analytics__row">
                    {Filters}

                    <PriceStatisticChart
                        data={analytics}
                        loading={loading}
                        ownerStation={ownerStation}
                        fuelTypes={fuelTypes}
                        translateStr={translate}
                        scrollBarValue={scrollBarValue}
                        // @ts-ignore
                        setScrollBarValue={setScrollBarValue}
                    />
                </Row>
            </CardWrapper>
        </Fragment>
    );
};

export default AnalyticsComponent;
