import gql from "graphql-tag";

const ActualPricesGasStationFragment = gql`
    fragment ActualPricesGasStationFragment on GasStation {
        id
        name
        number
        address
        status
        dynamicPricing
        fuelPrices {
            price
            delta
            updatedAt
            product {
                id
                code
                name
                fullName
            }
        }
        location {
            latitude
            longitude
        }
        region {
            id
            name
        }
        hasIndicator
    }
`;

export default ActualPricesGasStationFragment;
