import React, { useCallback, useEffect, useState } from "react";
import { Layout } from "antd";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import { MenuKeys } from "lib/helpers/menu";
import MainMenu from "./MainMenu";
import MainHeader from "./MainHeader";
import MainLogo from "./MainLogo";

import "./style.scss";

const { Header, Content, Sider, Footer } = Layout;

const MainLayout: React.FC = ({ children }) => {
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const [collapsedWidth, setCollapsedWidth] = useState(80);
    const [defaultOpenKeys, setDefaultOpenKeys] = useState<Array<string>>(
        Object.values(MenuKeys)
    );

    useEffect(() => {
        if (location) {
            const pathName =
        location.pathname === "/" ? "/actual-prices" : location.pathname;
            ReactGA.set({ page: pathName });
            ReactGA.pageview(pathName);
        }
    }, [location]);

    useEffect(() => {
        const width = window.innerWidth;

        if (width <= 1280) {
            setCollapsed(true);
        }

        if (width <= 578) {
            setCollapsedWidth(0);
        }

        if (width <= 1200) {
            setDefaultOpenKeys([]);
        }
    }, []);

    const handleMenuFold = useCallback(() => {
        setCollapsed(!collapsed);
    }, [collapsed]);

    const menuFold = collapsed ? (
        <MenuFoldOutlined onClick={handleMenuFold} />
    ) : (
        <MenuUnfoldOutlined onClick={handleMenuFold} />
    );

    return (
        <main>
            <Layout className="main-layout">
                <Sider
                    breakpoint="xl"
                    collapsedWidth={collapsedWidth}
                    collapsed={collapsed}
                    trigger={null}
                    onCollapse={(coll) => {
                        setCollapsed(coll);
                    }}
                >
                    <div className="main-layout__logo">
                        <MainLogo collapsed={collapsed} />
                    </div>

                    <MainMenu defaultKeys={defaultOpenKeys} />
                </Sider>
                <Layout>
                    <Header className="main-layout__header">
                        {menuFold}
                        <MainHeader collapsed={collapsed} />
                    </Header>

                    <Content
                        className={
                            location.pathname !== "/map" ? "main-layout__content" : ""
                        }
                    >
                        {children}
                    </Content>

                    {location.pathname !== "/map" && (
                        <Footer className="main-layout__footer">
              BenzUp ©2021 Created by OMT-consult
                        </Footer>
                    )}
                </Layout>
            </Layout>
        </main>
    );
};

export default MainLayout;
