import gql from "graphql-tag";

const UPDATE_PRICING_PRESETS = gql`
    mutation updatePricingPresets($input: PricingPresetsInput!) {
        updatePricingPresets(input: $input) {
            state
            strategy
            priority
            rounding
        }
    }
`;

export default UPDATE_PRICING_PRESETS;
