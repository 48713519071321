export const petrol = ['A0100', 'A0980', 'A098B', 'A0950', 'A095B', 'A0920', 'A092B', 'A0800'];

export const gas = ['P0SUG', 'G0KPG', 'P0SPG'];

export const diesel = ['D0DT0', 'D0DTL', 'D0DTE', 'D0DTZ', 'D0DTA', 'D0DTX', 'D0DTB'];

export const dieselFull = ['D0DT0', 'D0DTL', 'D0DTB', 'D0DTZ', 'D0DTA', 'D0DTE', 'D0DTK', 'D0DTX'];

export const dateHeader = 'updatedAt';

export const allFuelTypes = [...petrol, ...diesel, ...gas, dateHeader];

export const defaultActualPriceHeaders = [
    'A0100', 'A0980', 'A0950', 'A095B', 'A0920', 'A092B', 'A0800',
    ...diesel,
    ...gas,
    dateHeader,
];
export const actualPriceHeaders = ['A0980', 'A0950', 'A095B', 'A0920', 'A092B', 'A0800', ...diesel, ...gas];

export const basisesHeaders = ['A0100', 'A0950', 'A0920', 'A0800', 'D0DTL', 'D0DTE', 'D0DTZ', 'D0DTA', 'PPCB2', 'P0SUG'];
export const defaultBasisesHeaders = [
    'A0100', 'A0950', 'A0920', 'A0800', 'D0DTL', 'D0DTE', 'D0DTZ', 'D0DTA', 'P0SUG', 'PPCB2',
    dateHeader
];

export const getDefaultHeaders = (name: string): Array<string> => {
    switch (name) {
    case 'selectedFuels':
        return defaultActualPriceHeaders;
    case 'selectedFuelsTanks':
        return defaultBasisesHeaders;
    }

    return [];
};

export const getTranslateString = (type: string): string => {
    switch (type) {
    case 'selectedFuels':
        return 'reports.actualPrice.settings.column';
    case 'selectedFuelsTanks':
        return 'reports.basises.settings.column'
    }

    return 'reports.actualPrice.settings.column';
};

export const defaultFuelsObject = (fuelTypes: string[]): {[key: string]: boolean} => fuelTypes.reduce((accumulator, value) => {
    return {...accumulator, [value]: true};
}, {});
