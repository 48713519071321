import React from "react";
import {ProductData} from "../../../ActualPriceComponent/types";
import ActualPriceCell from "./";


interface RecommendedPriceCellProps {
    value: ProductData;
    header: string;
}

const RecommendedPriceCell: React.FC<RecommendedPriceCellProps> = ({
    value
}) => {
    return (
        <ActualPriceCell
            value={value.price.toFixed(2)}
            changed={false}
        >
            <span style={{color: "#52C41A"}}>
                {value.recommendedPrice && value?.recommendedPrice.toFixed(2)}
            </span>
        </ActualPriceCell>
    )
};

export default RecommendedPriceCell;
