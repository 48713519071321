import "./styles.scss";

import {
    createdAtDateFormat,
    periodDateFormat,
} from "lib/helpers/actualPriceHistory";

import { ColumnsType } from "antd/lib/table";
import { HourglassTwoTone } from "@ant-design/icons";
import PriceHistoryDownloadButton from "./PriceHistoryDownloadButton";
import React from "react";
import { StatisticReport } from "lib/graphql/queries/StatisticReport/types";
import { Table } from "antd";
import { bytesToSize } from "lib/helpers/actualPrices";
import { useTranslation } from "react-i18next";

interface Props {
  loading: boolean;
  tableData: StatisticReport[];
  pagination: JSX.Element;
}

const PriceHistoryModalTable: React.FC<Props> = ({
    loading,
    tableData,
    pagination,
}) => {
    const { t } = useTranslation();

    const tableColumns: ColumnsType<StatisticReport> = [
        {
            align: "left",
            title: t("reports.actualPrice.priceHistory.table.document"),
            width: "150",
            key: "document",
            render: (_value, item: StatisticReport) => {
                if (item.status == "PENDING") {
                    return "";
                }

                if (item.format === "CSV") {
                    return `История цен за период с ${periodDateFormat(
                        item?.from
                    )} по ${periodDateFormat(item?.to)}`;
                } else {
                    return `Актуальные цены на ${createdAtDateFormat(item.createdAt)}`;
                }
            },
        },
        {
            align: "left",
            title: t("reports.actualPrice.priceHistory.table.format"),
            dataIndex: "format",
            width: "20",
            key: "format",
            render: (value: string) => {
                return value;
            },
        },
        {
            align: "left",
            title: t("reports.actualPrice.priceHistory.table.size"),
            dataIndex: "fileSize",
            width: "25",
            key: "fileSize",
            render: (value: number, item: StatisticReport) => {
                return item.status == "READY" ? bytesToSize(value) : "";
            },
        },
        {
            align: "left",
            title: "",
            width: "25",
            key: "extras",
            render: (_value: number, item: StatisticReport) => {
                if (item.status == "PENDING") {
                    return (
                        <span
                            className="grey-tag"
                            style={{
                                fontSize: "18px",
                                transition: "background 20s",
                            }}
                        >
                            <HourglassTwoTone style={{ fontSize: "18px" }} />
                        </span>
                    );
                }
                return <PriceHistoryDownloadButton id={item.id} />;
            },
        },
    ];

    return (
        <>
            <Table
                className="actual-price-history-modal__table"
                dataSource={tableData}
                columns={tableColumns}
                showSorterTooltip={false}
                pagination={false}
                loading={loading}
            />

            {pagination}
        </>
    );
};

export default PriceHistoryModalTable;
