import gql from "graphql-tag";

export const ANALYTICS_GAS_STATIONS = gql`
    query AnalyticGasStations {
        userProfile {
            id
            customer {
                id
                name
                gasStations {
                    items {
                        id
                        name
                        number
                        address
                        status
                        dynamicPricing
                        fuelPrices {
                            product {
                                id
                                code
                            }
                        }
                    }
                    total
                }
            }
        }
    }
`;

export default ANALYTICS_GAS_STATIONS;
