import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { Organization } from "lib/graphql/queries/SmallWholesale/types";

const { Option } = Select;

interface ActualPriceRegionPickerProps {
  pickedCompany: Array<string>;
  regions: Organization[];
  setPickedCompany: (value: string[] | ((prev: string[]) => string[])) => void;
}

const SmallWholeSaleCompanyPicker: React.FC<ActualPriceRegionPickerProps> = ({
    regions,
    pickedCompany,
    setPickedCompany,
}) => {
    const { t } = useTranslation();

    return (
        <Select
            mode="multiple"
            className="region-picker__dropdown"
            value={pickedCompany}
            placeholder={t("reports.actualPrice.companySelect")}
            onChange={(val) => setPickedCompany(val)}
            allowClear
            showSearch={false}
            virtual={false}
        >
            {regions.map((item) => {
                return (
                    <Option value={item.name} key={item.id}>
                        {`${item.name}`}
                    </Option>
                );
            })}
        </Select>
    );
};

export default SmallWholeSaleCompanyPicker;
