import gql from "graphql-tag";

export const ANALYTICS_COMPETITORS = gql`
    query AnalyticGasStations($id: ID!) {
        gasStation(id: $id) {
           id
           competitors {
               id
               name
               number
               address
               status
               dynamicPricing
           }
           
        }
    }
`;

export default ANALYTICS_COMPETITORS;
