import React, {Fragment, useState} from "react";
import {Tooltip, Typography} from "antd";

import {getGasStationTitle} from "lib/helpers";
import {ActualPriceTableRow} from "components/ActualPriceComponent/types";
import StationStatusIcon from "components/_common/StationStatusIcon";

interface ActualPriceBrandProps {
    item: ActualPriceTableRow;
    value: string;
}

const {Paragraph} = Typography;

const ActualPriceBrand: React.FC<ActualPriceBrandProps> = ({item, value}) => {
    const [truncatedAdress, setTruncatedAdress] = useState(false);

    return (
        <div className="brand__cell">
            <div className="brand__cell-name">
                <Tooltip
                    placement="topLeft"
                    title={getGasStationTitle(value, item.internalNumber)}
                    arrowPointAtCenter
                >
                    <Paragraph
                        ellipsis={true}
                    >
                        {getGasStationTitle(value, item.internalNumber)}
                    </Paragraph>
                </Tooltip>
                <div className="brand__cell-status-dynamic-logo">
                    <StationStatusIcon dynamicPrice={item.dynamicPrice} status={item.status} />
                </div>
            </div>
            <Tooltip
                placement="topLeft"
                title={truncatedAdress ? item.address : undefined}
                arrowPointAtCenter
            >
                <Paragraph
                    ellipsis={{onEllipsis: (value) => setTruncatedAdress(value)}}
                    className="brand__address"
                >
                    <Fragment>{item.address}</Fragment>
                </Paragraph>
            </Tooltip>
        </div>
    )
};

export default ActualPriceBrand;
