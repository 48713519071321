import mapboxgl, {AnySourceData} from "mapbox-gl";

export const MAP_SOURCE_ID = 'places';
export const MAIN_LAYER_ID = `unclustered-${MAP_SOURCE_ID}`;

export const mainLayerProps: mapboxgl.AnyLayer = {
    'id': MAIN_LAYER_ID,
    'type': 'symbol',
    'source': MAP_SOURCE_ID,
    'filter': ['!', ['has', 'point_count']],
    'layout': {
        "text-field": String.fromCharCode(0xF3C5),
        'text-line-height': 1, // this is to avoid any padding around the "icon"
        'text-padding': 0,
        'text-anchor': 'center', // center, so when rotating the map, the "icon" stay on the same location
        'text-offset': [0, -0.3], // give it a little offset on y, so when zooming it stay on the right place
        'text-allow-overlap': true,
        'text-ignore-placement': true,
        'icon-optional': true, // since we're not using an icon, only text.
        'text-font': ['Font Awesome 5 Free Solid'],
        'text-size': 30
    },
    'paint': {
        'text-translate-anchor': 'viewport',
        'text-color': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            ['get', 'hoverColor'],
            ['get', 'color']
        ]
    }
};

export const defaultSourceProps: AnySourceData = {
    'type': 'geojson',
    'data': {
        'type': 'FeatureCollection',
        'features': []
    }
};

export const defaultTooltipData = {
    brand: "",
    number: "",
    prices: [],
    address: ""
};
