import React, {ReactNode} from "react";
import { Card } from 'antd';

import "./style.scss";

interface CardWrapperProps {
    title?: string | ReactNode;
}

const CardWrapper: React.FC<CardWrapperProps> = ({
    children,
    title
}) => {
    return (
        <Card title={title}  className="card-table">
            {children}
        </Card>
    )
};

export default CardWrapper;
