import dayjs from "dayjs";

import {GasStationFuelPrice, GasStationsItem} from "lib/graphql/queries/GasStations/gasStations";
import {ActualPriceTableRow, ProductDataIndex} from "components/ActualPriceComponent/types";

let groupId = 100;
let currentStation: ActualPriceTableRow;

//: (data:Array<GasStationsItem>) => Array<ActualPriceTableRow>
export const actualPrices = (data: Array<GasStationsItem>): Array<ActualPriceTableRow> => {
    const result: Array<ActualPriceTableRow> = [];
    data.forEach((station, index) => {
        currentStation = mapGasStation(station, index);
        result.push(currentStation);

        station.competitors && station.competitors.forEach((competitor, competitorIndex) => {
            result.push(
                mapCompetitorGasStation(
                    currentStation,
                    competitor,
                    competitorIndex === (station.competitors.length - 1)
                )
            );

        });

        groupId += 10;
    });

    return result;
};

const mapGasStation = (
    station: GasStationsItem,
    index: number,
): ActualPriceTableRow => {
    const {prices, maxUpdatedAt} = mapFuelPrices(station.fuelPrices);

    const record: ActualPriceTableRow = {
        key: station.id,
        groupId,
        brand: station.name,
        internalNumber: station.number,
        own: true,
        address: station.address,
        dynamicPrice: station.dynamicPricing,
        status: station.status,
        region: station.region.id,
        hasIndicator: station.hasIndicator,
        products: {}
    };

    record.products = prices;
    record.updatedAt = maxUpdatedAt;


    if (index || index === 0) {
        record.border = 'top';
    }

    if (station.competitors && station.competitors.length === 0) {
        record.border = 'both';
    }

    return record;
};

const mapCompetitorGasStation = (
    station: ActualPriceTableRow,
    competitor: GasStationsItem,
    lastItem: boolean
): ActualPriceTableRow => {
    const {prices, maxUpdatedAt} = mapCompetitorFuelPrices(competitor.fuelPrices, station);

    const record: ActualPriceTableRow = {
        key: `${competitor.id}${Math.floor(Math.random() * (3000 - 50) + 50)}`,
        groupId,
        brand: competitor.name,
        internalNumber: competitor.number,
        own: false,
        address: competitor.address,
        dynamicPrice: competitor.dynamicPricing,
        status: competitor.status,
        region: competitor.region.id,
        hasIndicator: competitor.hasIndicator,
        products: {}
    };

    record.products = prices;
    record.updatedAt = maxUpdatedAt;

    if (lastItem) {
        record.border = 'bottom';
    }

    return record;
};

const mapFuelPrices = (fuelPrices: Array<GasStationFuelPrice>) => {
    let maxUpdatedAt: dayjs.Dayjs | null = null;
    const prices: ProductDataIndex = {};

    fuelPrices.forEach((fuel) => {
        if (fuel.product.code === null) {
            return;
        }

        const updatedAt = dayjs(fuel.updatedAt);

        if (maxUpdatedAt === null || maxUpdatedAt.isBefore(updatedAt)) {
            maxUpdatedAt = updatedAt
        }

        prices[fuel.product.code] = {
            price: fuel.price,
            delta: fuel.delta,
            recommendedAt: fuel.recommendedAt,
            recommendedPrice: fuel.recommendedPrice,
            changed: false
        };

    });

    return {
        prices,
        maxUpdatedAt
    };
};

const mapCompetitorFuelPrices = (fuelPrices: Array<GasStationFuelPrice>, station: ActualPriceTableRow) => {
    let maxUpdatedAt: dayjs.Dayjs | null = null;
    const prices: ProductDataIndex = {};

    fuelPrices.forEach((fuel) => {
        if (fuel["product"].code === null) {
            return;
        }

        const updatedAt = dayjs(fuel.updatedAt);

        if (maxUpdatedAt === null || maxUpdatedAt.isBefore(updatedAt)) {
            maxUpdatedAt = updatedAt
        }

        prices[fuel.product.code] = {
            price: fuel.price,
            delta: fuel.delta,
            recommendedAt: fuel.recommendedAt,
            recommendedPrice: fuel.recommendedPrice,
            changed: false
        };

        if (station.products[fuel.product.code]) {
            prices[fuel.product.code].diff = fuel.price - currentStation.products[fuel.product.code].price;
        }

    });

    return {
        prices,
        maxUpdatedAt
    };
};
