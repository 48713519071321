import React from "react";

import InfoIconTooltip from "components/_common/InfoIconTooltip";

interface FilterFormItemLabelProps {
    title: string;
    tooltipText: string;
}

const FilterFormItemLabel: React.FC<FilterFormItemLabelProps> = ({title, tooltipText}) => {
    return (
        <>
            {title}
            <InfoIconTooltip title={tooltipText}/>
        </>
    )
};

export default FilterFormItemLabel;
