import gql from "graphql-tag";
import PersonalGasStationFragment from 'lib/graphql/fragments/GasStation/personalGasStation';

const PERSONAL_GAS_STATIONS_LIST= gql`
    query userProfile($pagination: PaginationInput!) {
        userProfile {
            id
            customer {
                id
                gasStations(pagination: $pagination) {
                    items {
                        ...PersonalGasStationFragment
                    }
                    total
                }
            }
        }
    }
    ${PersonalGasStationFragment}
`;

export default PERSONAL_GAS_STATIONS_LIST;
