import React, {Fragment} from 'react';

import ColumnFilter from "./ColumnFilter";

import "./actual-price-extras.scss";

interface ActualPriceExtrasProps {
    fuelStorageName: string;
    setTableHeaders: (value: (((prevState: Array<string>) => Array<string>) | Array<string>)) => void
}

const ActualPriceExtras: React.FC<ActualPriceExtrasProps> = ({
    setTableHeaders,
    fuelStorageName
}) => {
    return (
        <Fragment>

            <ColumnFilter
                fuelStorageName={fuelStorageName}
                setTableHeaders={setTableHeaders}
            />

        </Fragment>
    );
};

export default ActualPriceExtras;
