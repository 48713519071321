import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Tooltip, Typography} from "antd";
import {CloseOutlined, PaperClipOutlined, UploadOutlined} from "@ant-design/icons";

const {Text} = Typography;

interface Props {
    file?: File;
    clearFile: () => void;
}

const SizeOfSalesButton: React.FC<Props> = ({
    file,
    clearFile
}) => {
    const {t} = useTranslation();

    if (!file) {
        return (
            <Button icon={<UploadOutlined/>}>
                {t("recommendedPrices.form.upload")}
            </Button>
        );
    }

    return (
        <Button
            className="file-button__selected"
            icon={<PaperClipOutlined/>}
        >

            <Tooltip
                placement="topLeft"
                title={file?.name}
                arrowPointAtCenter
            >
                <Text
                    ellipsis={true}
                >
                    {file?.name}
                </Text>
            </Tooltip>

            <CloseOutlined onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
                event.stopPropagation();
                clearFile();
            }}
            />
        </Button>
    )
};

export default SizeOfSalesButton;
