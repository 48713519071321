import React, {Fragment} from "react"

interface MainLogoProps {
    collapsed: boolean;
}

const MainLogo: React.FC<MainLogoProps> = ({collapsed}) => {
    const logoClass = collapsed ? "main-logo" : "main-logo__collapsed";
    const fullLogoClass = collapsed ? "main-logo-full__collapsed" : "main-logo-full";

    return (
        <Fragment>
            {collapsed ?
                <img
                    className={logoClass}
                    src={require('../../assets/main_logo.png')}
                    alt=""
                /> :
                <img
                    className={fullLogoClass}
                    src={require('../../assets/main_logo_full2.png')}
                    alt=""
                />
            }

        </Fragment>
    )
};

export default MainLogo;
