import React from "react";
import {Select, Typography} from "antd";
import {useTranslation} from "react-i18next";

import {filterSelect} from "lib/helpers";
import {actualPriceHeaders} from "lib/helpers/fuelTypes";

const {Title, Paragraph} = Typography;
const {Option} = Select;

interface FuelTypesFilterProps {
    loading: boolean;
    fuelTypes: Array<string>;
    setFuelTypes: (value: (((prevState: Array<string>) => Array<string>) | Array<string>)) => void
}

const FuelTypesFilter: React.FC<FuelTypesFilterProps> = ({
    loading,
    fuelTypes,
    setFuelTypes
}) => {
    const {t} = useTranslation();
    const selectData = ['A0100', ...actualPriceHeaders];

    return (
        <div>
            <Title level={5}>
                {t("analytics.chart.filter.fuelTypes")}
            </Title>

            <Select
                mode="multiple"
                className="fuel-types-select"
                loading={loading}
                value={fuelTypes}
                placeholder={t("analytics.chart.filter.fuelTypesSecondary")}
                onChange={setFuelTypes}
                defaultActiveFirstOption
                filterOption={filterSelect}
                showSearch={false}
            >
                {selectData.map((item) =>
                    <Option
                        value={item}
                        key={item}
                    >
                        {t(`reports.actualPrice.analyticsFuel.${item}`)}
                    </Option>
                )}
            </Select>

            <Paragraph
                className="gas-station-filter__desc"
                type="secondary"
            >
                {t("analytics.chart.filter.fuelTypesSecondary")}
            </Paragraph>
        </div>
    )
};

export default FuelTypesFilter;
