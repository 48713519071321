import React from "react";
import {CaretDownFilled, CaretUpFilled} from '@ant-design/icons/lib';
import ActualPriceCell from "./";

import {parsePrice} from "lib/helpers";

import "./style.scss";

interface ActualPriceDeltaCellProps {
    delta: number
    formatDelta: boolean;
    value: string;
    changed: boolean;
}

const ActualPriceDeltaCell: React.FC<ActualPriceDeltaCellProps> = ({
    delta,
    formatDelta,
    value,
    changed
}) => {
    const deltaSign = Math.sign(delta);
    const cellClasses = ['price-cell-left'];
    const formattedDelta = formatDelta ? parsePrice(delta) : delta.toFixed(2);
    const currentSymbol = deltaSign > 0 ?
        <CaretUpFilled className="positive-caret"/> : <CaretDownFilled className="negative-caret"/>;

    const deltaFragment = delta ? <div>
        {formattedDelta} {' '} {currentSymbol}
    </div> : null;

    return (
        <ActualPriceCell
            changed={changed}
            value={value}
            classes={cellClasses}
        >
            {deltaFragment}
        </ActualPriceCell>
    )
};

export default ActualPriceDeltaCell;
