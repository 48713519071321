import gql from "graphql-tag";

const NEW_STATISTIC_REPORT = gql`
   query statisticReport($pagination: PaginationInput!) {
       statisticReport {
           list(pagination: $pagination) {
               items {
                  ...on StatisticReport{
                   id
                   from
                   to
                   status
                   format
                   fileSize
                   createdAt
                 }
                 ...on PricesReport {
                   id
                   status
                   format
                   fileSize
                   createdAt
                 }
               }
               total
           }
       }
      
   }

`;

export default NEW_STATISTIC_REPORT;