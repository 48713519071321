import React, {ReactNode} from "react";
import {Empty} from "antd";

interface NoContentProps {
    description?: ReactNode;
}

const NoContent: React.FC<NoContentProps> = ({description}) => {
    return (
        <Empty
            description={description}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
    )
};

export default NoContent;
