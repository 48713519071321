import jwt_decode from "jwt-decode";
import ReactGA from "react-ga";

import {ConstructorType, TokenType, StorageType, JwtToken} from './types';
import { copyDefaultToken, isExpired, jwtExp } from './helpers';
import {RefreshToken} from "lib/graphql/mutations/RefreshToken/types";

const ACCESS_TOKEN_KEY = 'token';
const refreshMutation = `
    mutation refreshToken {
        refreshToken
    }
`;

export default class TokenStorage {
    storage: StorageType;

    tokenExpTimeOut = 0;

    // eslint-disable-next-line no-console
    onTokenExpiredCallback = (timer: number): void => {console.log(timer)};

    token: TokenType = copyDefaultToken();

    init = true;

    timer = 0;

    constructor({ storage, tokenExpTimeout, onTokenExpiredCallback }: ConstructorType) {
        this.storage = storage;
        this.tokenExpTimeOut = tokenExpTimeout; // random 1-2-3 min
        this.onTokenExpiredCallback = onTokenExpiredCallback;
        const accessToken = storage.getItem(ACCESS_TOKEN_KEY);

        if (accessToken) {
            this.setToken({ accessToken });
        } else {
            this.init = false;
        }

        window.addEventListener("storage", (event) => {
            event.key === ACCESS_TOKEN_KEY && this.updateToken({accessToken: event.newValue});
        });

        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === "visible") {
                const token = this.getAccessToken();
                const isTokenExpired = this.checkIsTokenExpired(token);

                isTokenExpired && this.refreshToken();
            }
        }, false)
    }

    saveToStorage({ accessToken }: TokenType): void {
        try {
            if (this.storage === null) {
                throw new Error('не найдено storage');
            }

            if (accessToken) {
                this.storage.setItem(ACCESS_TOKEN_KEY, accessToken);
            } else {
                this.storage.removeItem(ACCESS_TOKEN_KEY);
            }

        } catch (e) {
            console.error('Не удалось сохранить токен в storage', e); // eslint-disable-line no-console
        }
    }

    updateToken({ accessToken }: TokenType): void {
        this.token.accessToken = accessToken;
        this.startTimer();
    }

    setToken({ accessToken }: TokenType): string | null {
        if (this.checkIsTokenExpired(accessToken)) {
            return null;
        }

        this.token.accessToken = accessToken;
        this.init = false;

        // start timer
        this.startTimer();

        // save To Storage
        this.saveToStorage({ accessToken });

        // set Google Analytics user id
        this.setGA();

        return accessToken;
    }

    setGA(): void {
        if (!this.decodedToken) {
            return;
        }

        const {sub} = this.decodedToken;

        if (sub) {
            setTimeout(() => {
                ReactGA.set({
                    userId: sub,
                });
            }, 0)
        }
    }

    checkIsTokenExpired(token: string | null): boolean {
        const expireDate = jwtExp(token);

        if (!expireDate) {
            this.clearToken();

            return true;
        }

        if (expireDate && isExpired(expireDate)) {
            this.refreshToken();

            return true;
        }

        return false;
    }

    startTimer(): void {
        if (!this.decodedToken) {
            return;
        }

        const { exp } = this.decodedToken;

        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = window.setTimeout(
            this.refreshToken.bind(this),
            exp * 1000 - new Date().getTime() - this.tokenExpTimeOut,
        );
    }

    clearToken(): void {
        this.token = copyDefaultToken();

        this.saveToStorage(this.token);
        this.onTokenExpiredCallback(this.timer);
    }

    refreshToken(): void {
        if (document.visibilityState === "hidden") {
            return;
        }

        const token = this.getAccessToken();
        const expireDate = jwtExp(token);
        const url = process.env.REACT_APP_BENZUP_GRAPH_API_URL;

        if (!expireDate) {
            this.clearToken();

            return;
        }

        try {
            if (!url) {
                return;
            }

            void fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: refreshMutation
                }),
            })
                .then(res => res.json())
                .then(res => {
                    const {data} = res;

                    if (!data) {
                        return this.clearToken();
                    }

                    const {refreshToken} = data as RefreshToken;
                    if (data && refreshToken) {
                        this.setToken({accessToken: refreshToken})
                    }
                });

        } catch (e) {
            throw new Error(e);
        }

    }

    getAccessToken(): string | null {
        const tokenFromProperty = this.token.accessToken;
        if (tokenFromProperty) {
            return tokenFromProperty;
        }

        const tokenFromStorage = this.storage.getItem(ACCESS_TOKEN_KEY);
        if (tokenFromStorage) {
            return tokenFromStorage;
        }

        return null;
    }

    get accessToken(): string | null {
        return this.token.accessToken;
    }

    get decodedToken(): JwtToken | null {
        const token = this.getAccessToken();

        if (!token) {
            return null;
        }

        return jwt_decode(token);
    }

}
