import React, {useEffect} from "react";
import {Select, Typography} from "antd";
import {useTranslation} from "react-i18next";

import NoContent from "components/_common/NoContent";
import StationStatusIcon from "components/_common/StationStatusIcon";
import {filterAnalyticsSelect} from "lib/helpers";
import {GasStationsItem} from "lib/graphql/queries/GasStations/gasStations";

interface FuelTypesFilterProps {
    owner: string;
    loading: boolean;
    showSearch: boolean;
    gasStations: Array<string>;
    data: Array<GasStationsItem>;
    setGasStations: (value: (((prevState: Array<string>) => Array<string>) | Array<string>)) => void;
}

const {Option} = Select;
const {Paragraph, Title} = Typography;

const GasStationsFilter: React.FC<FuelTypesFilterProps> = ({
    data,
    owner,
    loading,
    showSearch,
    gasStations,
    setGasStations
}) => {
    const {t} = useTranslation();
    const noContent = (
        <NoContent description={<div>{t("analytics.chart.noInfo")}</div>}/>
    );

    useEffect(() => {
        setGasStations([]);
    }, [owner]);

    return (
        <div className="gas-station-filter">
            <Title level={5}>
                {t("analytics.chart.filter.gasStations")}
            </Title>

            <Select
                mode="multiple"
                loading={loading}
                disabled={!owner.length}
                value={gasStations}
                placeholder={t("analytics.chart.filter.gasStationsSecondary")}
                style={{width: "100%"}}
                onChange={(val) => {
                    setGasStations(val)
                }}
                notFoundContent={noContent}
                showSearch={showSearch}
                filterOption={filterAnalyticsSelect}
                optionLabelProp="label"
            >
                {data.map((item) => {
                    const label = `${item.name} ${item.number ? `№${item.number}` : ''}`;

                    return (
                        <Option
                            key={item.id}
                            value={item.id}
                            label={label}
                        >
                            {label}

                            <StationStatusIcon dynamicPrice={item.dynamicPricing} status={item.status} />

                            {" "}

                            <span className="analytics-select-item__address">({item.address})</span>
                        </Option>
                    )
                })}
            </Select>

            <Paragraph
                className="gas-station-filter__desc"
                type="secondary"
            >
                {t("analytics.chart.filter.gasStationsSecondary")}
            </Paragraph>
        </div>
    )
};

export default GasStationsFilter;
