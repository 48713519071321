import React from 'react';
import { BreadcrumbProps } from 'antd/lib/breadcrumb';
import { PageHeader } from 'antd';

import './index.scss';

interface HeaderProps {
    title: string;
    breadcrumbs?: BreadcrumbProps;
    footer?: React.ReactNode

}

const PageMainHeader: React.FC<HeaderProps> = ({
    title,
    breadcrumbs,
    footer,
    children
}) => {
    const headerBreadcrumbs = breadcrumbs || undefined;

    return (
        <PageHeader
            className="page-main-header"
            title={title}
            breadcrumb={headerBreadcrumbs}
            footer={footer}
        >
            {children}
        </PageHeader>
    );
};

export default PageMainHeader;
