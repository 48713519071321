import {ActualPriceTableRow} from "components/ActualPriceComponent/types";
import {diesel, gas} from "./fuelTypes";

export const eachRowClass = (record: ActualPriceTableRow): string => {
    const rowClass: Array<string> = [];

    switch (record.border) {
    case 'bottom':
        rowClass.push('micro-market-line__bottom');
        break;
    case 'top':
        rowClass.push('micro-market-line__top');
        break;
    case 'both':
        rowClass.push('micro-market-line__top');
        rowClass.push('micro-market-line__bottom');
    }

    if (record.own) {
        rowClass.push('micro-market-own-station');
    } else {
        rowClass.push('micro-market-competitor')
    }

    return rowClass.join(' ');
};

export const ownGasStationClass = (record: ActualPriceTableRow): string => {
    if (record.own) {
        return 'micro-market-own-station';
    }

    return '';
};

export const headerWidth = (header: string): number | string => {
    const excludeArr = [...gas, ...diesel];
    if (excludeArr.includes(header) && header !== 'D0DTB') {
        return 70;
    }

    return 100;
};

export function bytesToSize(bytes: number): string {
    const sizes = ['byte', 'KB', 'MB', 'GB']

    if (bytes === 0) return 'n/a'

    const i = Math.floor(Math.log(bytes) / Math.log(1024))

    if (i === 0) return `${bytes} ${sizes[i]}`

    return `${(bytes / (1024 ** i)).toFixed(2)} ${sizes[i]}`
}
