import gql from "graphql-tag";

const STATISTIC_REPORT_MUTATION_CREATE = gql`
   mutation requestReport($input: StatisticReportInput!) {
 statisticReport{
   create(input: $input) {
     __typename
     ...on PricesReport{
       id
       format
	   status
     }
     ...on StatisticReport {
       id
       format
	   status
     }
   }
 }
}
`;

export default STATISTIC_REPORT_MUTATION_CREATE;
