import React from "react";
import {Badge} from "antd";

import "./style.scss";

interface ActualPriceCellProps {
    value: string;
    changed: boolean;
    classes?: Array<string>
}

const ActualPriceCell: React.FC<ActualPriceCellProps> = ({
    children,
    value,
    changed,
    classes
}) => {
    const cellClasses = ['price-cell'];
    const badge = (
        <Badge
            className="actual-price-cell__badge"
            color="green"
        />
    );

    if (classes) {
        cellClasses.concat(classes);
    }

    return (
        <div className={cellClasses.join(' ')}>
            <div>
                {value}
                {changed && badge}
            </div>
            {children}
        </div>
    );
};

export default ActualPriceCell;
