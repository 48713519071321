import React from "react";
import {Typography} from "antd";
import {useTranslation} from "react-i18next";
import {InfoCircleFilled} from "@ant-design/icons"

import "./style.scss";

const { Title } = Typography;

const DataLoading: React.FC = () => {
    const {t} = useTranslation();

    return (
        <div className="data-loading">
            <InfoCircleFilled />
            <Title level={3}>
                {t("recommendedPrices.loading")}
            </Title>
        </div>
    )
};

export default DataLoading;
