import currency from "currency.js";
import {FilterFunc} from "rc-select/lib/interface/generator";
import {OptionGroupData} from "rc-select/lib/interface";

interface FilterFuncProps {
    value: string;
    children: string
}
interface FilterSelectProps {
    value: string;
    label: string
}

export const getGasStationNumber = (num: string | null): string => {
    if (num === null) {
        return '';
    }

    const regex = /\d+/g;
    const matched = num.match(regex);

    if (matched !== null) {
        return `№${matched[0]}`
    }

    return num;
};

export const getGasStationTitle = (name: string, num: string | null): string => {
    const gasStationNumber = num ? `, ${num}` : "";

    return `${name}${gasStationNumber}`
};

export const DEFAULT_PAGINATION_SIZE = 10;

export const parsePrice = (price: number): string => {
    return currency(price, {separator: " ", symbol: "", precision: 0}).format();
};

export const isEnvForDebug = (): boolean => {
    return process.env.NODE_ENV === 'development';
};

export const filterSelect: FilterFunc<OptionGroupData[number]> = (
    input,
    option: OptionGroupData & FilterFuncProps
) => {
    if (!option) return false;

    return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        || option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};


export const filterAnalyticsSelect: FilterFunc<OptionGroupData[number]> = (
    input,
    option: OptionGroupData & FilterSelectProps
) => {
    if (!option) return false;

    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
};


export const filterBasisSelect: FilterFunc<OptionGroupData[number]> = (
    input,
    option: OptionGroupData & FilterFuncProps
) => {
    if (!option) return false;

    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
};
