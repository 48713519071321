import React,{Fragment} from "react";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";

import ActualPriceContainer from "containers/ActualPriceContainer";

const ActualPricePage: React.FC = () => {
    const {t} = useTranslation();

    return (
        <Fragment>
            <Helmet>
                <title>{`${t(`title.pages.actualPrices`)} - BenzUp`}</title>
                <meta
                    name="description"
                    content="Аналитический сервис с актуальной информацией АЗС. Цены и бренды топлива, адреса АЗС,
                    сравнительная информация."
                />
                <meta
                    name="keywords"
                    content="цены цена на азс бензин бензина татнефть пропан дизель аи-92 аи-95 аи-98
                    аи-100 лукойл газпромнефть роснефть газпром башнефть газ газа 92 95 98 100 дизельное дизельного шелл
                    дт ростов новосибирска бензин топливо топлива агзс агнкс"
                />
            </Helmet>
            <ActualPriceContainer />
        </Fragment>
    )
};

export default ActualPricePage;
