import React, {ReactNode, useEffect, useState} from "react";
import {Menu} from "antd";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    TableOutlined,
    AreaChartOutlined,
    PushpinOutlined,
    CarOutlined
} from '@ant-design/icons';

import {MenuKeys} from "lib/helpers/menu";

interface MenuItem {
    key: number;
    title: string;
    link: string;
    icon: ReactNode
}

interface MainMenuProps {
    defaultKeys: Array<string>;
}

const { SubMenu } = Menu;
const {ACTUAL_PRICES, WHOLESALE} = MenuKeys;

const MainMenu: React.FC<MainMenuProps> = ({defaultKeys}) => {
    const location = useLocation();
    const {t} = useTranslation();
    const [selectedKey, setSelectedKey] = useState(1);

    const actualPricesMenuItems: Array<MenuItem> = [
        {
            key: 1,
            title: t('menu.menuItem.ActualPriceReport'),
            link: '/',
            icon: <TableOutlined/>
        },
        {
            key: 2,
            title: t('menu.menuItem.Analytics'),
            link: '/analytics',
            icon: <AreaChartOutlined />
        },
        {
            key: 3,
            title: t('menu.menuItem.PersonalGasStations'),
            link: '/my-stations',
            icon: <TableOutlined/>
        },
        {
            key: 4,
            title: t('menu.menuItem.RecommendedPrices'),
            link: '/recommended-prices',
            icon: <TableOutlined/>
        },
        {
            key: 5,
            title: t('menu.menuItem.GasStationMap'),
            link: '/map',
            icon: <PushpinOutlined />
        }
    ];

    const wholesaleMenuItems: Array<MenuItem> = [
        {
            key: 15,
            title: t('menu.menuItem.ActualPriceReport'),
            link: '/wholesale',
            icon: <TableOutlined/>
        },
        {
            key: 16,
            title: t('menu.menuItem.Analytics'),
            link: '/wholesale-analytics',
            icon: <AreaChartOutlined />
        },
    ];

    useEffect(() => {
        const menus = [...actualPricesMenuItems, ...wholesaleMenuItems];
        const route = menus.find(item => item.link === location.pathname);

        if (route && route.key !== selectedKey) {
            setSelectedKey(route.key);
        }

    }, [location]);

    const menuRender = actualPricesMenuItems.map((item) => (
        <Menu.Item key={item.key} >
            <Link to={item.link}>{item.title}</Link>
        </Menu.Item>
    ));

    const wholesaleRender = wholesaleMenuItems.map((item) => (
        <Menu.Item key={item.key}>
            <Link to={item.link}>{item.title}</Link>
        </Menu.Item>
    ));

    return (
        <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={defaultKeys}
            selectedKeys={[`${selectedKey}`]}
        >
            <SubMenu
                key={ACTUAL_PRICES}
                icon={<CarOutlined/>}
                title={t("menu.submenu.gasStationPrices")}
            >
                {menuRender}
            </SubMenu>

            <SubMenu
                key={WHOLESALE}
                icon={<TableOutlined/>}
                title={t("menu.submenu.tankFarmPrices")}
            >
                {wholesaleRender}
            </SubMenu>
        </Menu>
    )
};

export default MainMenu;
