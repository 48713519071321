import React from "react";

import PriceStatisticBasisContainer from "containers/PriceStatisticBasisContainers";

const PriceStatisticBasisPage: React.FC = () => {
    return (
        <PriceStatisticBasisContainer />
    );
};

export default PriceStatisticBasisPage;
