/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserProfile
// ====================================================

export interface Point {
    latitude: number;
    longitude: number;
}

export enum GasStationStatus {
    ACTIVE = 'ACTIVE',
    RECONSTRUCTION = 'RECONSTRUCTION',
    UNDER_CONSTRUCTION = 'UNDER_CONSTRUCTION',
    CLOSED = 'CLOSED'
}

export interface GasStation {
    id: number;
    name: string;
    number: number;
    address: string;
    status: GasStationStatus
    location: Point;
}

export interface GasStationList {
    items: Array<GasStation>;
    total: number;
}

export interface UserProfile_Customer {
    id: string;
    name: string;
    gasStations: [GasStationList];
}

export interface userProfile_userProfile {
    __typename: 'User';
    id: string;
    firstName?: string;
    lastName?: string;
    email: string;
    authority: Array<string>;
    customer: UserProfile_Customer;
}

export interface userProfile {
    userProfile: userProfile_userProfile;
}
