import React, { Fragment, ReactNode } from "react";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { allFuelTypes, defaultFuelsObject } from "../../lib/helpers/fuelTypes";
import { useTranslation } from "react-i18next";
import { CustomStorage, SELECTED_FUELS } from "../../lib/helpers/Storage";

const { Button: RadioButton } = Radio;

const ActualPricePicker: React.FC = () => {
    let key = 0;

    const { t } = useTranslation();
    const buttons: Array<ReactNode> = [];
    const buttonsText = [...allFuelTypes];
    const checkedTypes = defaultFuelsObject(
        CustomStorage.getValue(SELECTED_FUELS)
    );

    const handleRadioGroupChange = (event: RadioChangeEvent) => {
        const header = document.getElementById(event.target.value);

        if (header) {
            header.scrollIntoView({
                block: "end",
                inline: "end",
            });
        }
    };

    buttonsText.forEach((text) => {
        buttons.push(
            <RadioButton
                key={(key += 1)}
                value={text}
                checked={checkedTypes[text] || false}
            >
                {t(`reports.actualPrice.settings.column.${text}`)}
            </RadioButton>
        );
    });

    return (
        <Fragment>
            <Radio.Group
                onChange={handleRadioGroupChange}
                defaultValue={buttonsText[0]}
                buttonStyle="solid"
            >
                {buttons}
            </Radio.Group>
        </Fragment>
    );
};

export default ActualPricePicker;
