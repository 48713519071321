import {GasStationYandexMapData, GasStationsItem} from "lib/graphql/queries/GasStations/gasStations";

import {LngLatLike} from "mapbox-gl";
import {getGasStationTitle} from "lib/helpers";

interface FeaturesMappingResult {
   coordinates: Array<LngLatLike>;
   data: GasStationYandexMapData[]
}

export const mapFeaturesMapping = (points: Array<GasStationsItem>): FeaturesMappingResult => {
    const coordinates: Array<LngLatLike> = [];
    const data: GasStationYandexMapData[] = [];

    for (let i = 0; i < points.length; i++) {
        coordinates.push([points[i].location.longitude, points[i].location.latitude]);

        data.push({
            id: points[i].id,
            type: 'Feature',
            properties: {
                region: points[i]?.region,
                brand: points[i].name,
                number: points[i].number || "",
                address: points[i].address,
                prices: +points[i].fuelPrices,
                color: "#000",
                zIndex: 9000,
                hoverColor: "#ff6f00",
                competitors: points[i].competitors.map(
                    (competitor) => getGasStationTitle(competitor.name, competitor.number)
                )
            },
            geometry: {
                type: 'Point',
                coordinates: [points[i].location.longitude, points[i].location.latitude]
            }
        });

        // выводим конкурентов
        const competitor = points[i].competitors;

        for (let j = 0; j < competitor.length; j++) {
            coordinates.push([competitor[j].location.longitude, competitor[j].location.latitude]);

            const concurrent: GasStationYandexMapData = {
                id: competitor[j].id,
                type: 'Feature',
                properties: {
                    region:  points[j]?.region,
                    brand: competitor[j].name,
                    number: competitor[j].number || "",
                    address: competitor[j].address,
                    prices: +competitor[j].fuelPrices,
                    color: "#b5b3b4",
                    zIndex:0,
                    hoverColor: "#0163f5",
                },
                geometry: {
                    type: 'Point',
                    coordinates: [competitor[j].location.longitude, competitor[j].location.latitude]
                }
            }

            data.push(concurrent);
        }
    }

    return {
        coordinates,
        data
    }
}