import { Basises } from './../graphql/queries/SmallWholesale/types';
import { RegionSmallWholeSaleSelect } from "containers/SmallWholesaleContainer";

export const actualRegionsCount = (basises: Basises[]):RegionSmallWholeSaleSelect[] => {
    const tempRegions: { [key: string]: RegionSmallWholeSaleSelect } = {};

    basises?.forEach((station) => {
        const { address } = station;

        if (tempRegions[address]) {
            tempRegions[address].count += 1;
        } else {
            tempRegions[address] = Object.assign(station, {
                count: 1,
            });
        }
    });
    return Object.values(tempRegions)
}
