import {basisesHeaders, defaultActualPriceHeaders, defaultFuelsObject} from "./fuelTypes";

export const SELECTED_FUELS = 'selectedFuels';
export const SELECTED_FUELS_TANKS = 'selectedFuelsTanks';
const defaultValue = '[]';

type SelectedFuelsUnion = typeof SELECTED_FUELS | typeof SELECTED_FUELS_TANKS;

const defaultValueByName = {
    [SELECTED_FUELS]: defaultActualPriceHeaders,
    [SELECTED_FUELS_TANKS]: basisesHeaders
}

export class CustomStorage {
    static getValue(name: string): Array<string> {
        const item = localStorage.getItem(name);

        if (item !== null) {
            return JSON.parse(item) as [];
        }

        return JSON.parse(defaultValue) as [];
    }

    static setValue(name: string, value: Array<string | number>): void {
        localStorage.setItem(name, JSON.stringify(value));
    }

    static setUpdatedValue(name: SelectedFuelsUnion): void {
        const selectedFuels = this.getValue(name);
        const defaultActualPricesObject = defaultFuelsObject(defaultValueByName[name]);

        if (!selectedFuels.length) {
            this.setValue(name, defaultValueByName[name])

            return;
        }

        this.setValue(name, selectedFuels.filter((selectedFuel) => defaultActualPricesObject[selectedFuel]))
    }
}
