import ruModules from "./modules";

export default {
    ...ruModules,
    gasStation: {
        types: {
            title: "Топливо",
        },
        list: {
            title: "Список АЗС",
            tabs: {
                catalog: "Список",
                map: "Карта",
                brand: "Бренд",
                managementCompany: "Управляющая компания",
                status: "Статус",
                fuelTypes: "Виды топлива",
            },
        },
        personalArea: {
            titles: {
                microMarket: "Микрорынок АЗС",
                photos: "Фотографии",
                actualPrices: "Актуальные цены",
                actualPricesDiscount: "Цены со скидкой",
                info: "Информация",
                news: "Новости",
            },
            services: {
                title: "Услуги",
                parking: "Парковка",
                restroom: "Туалет",
                atm: "Банкомат",
                tireFitting: "Шиномонтаж",
                carWash: "Автомойка",
                shop: "Магазин",
                service: "Сервис",
                pharmacy: "Аптека",
                cafe: "Кафе",
            },
            fuelCard: {
                title: "Топливные карты",
                lickard: "Ликард",
                tinkoff: "Тинькофф",
                bpPartner: "BP Partner",
                tatneftPartner: "Партнер-Татнефть",
                taif: "ТАИФ",
            },
            personalInfo: {
                owner: "Владелец АЗС",
                inn: "ИНН владельца",
                address: "Адрес компании владельца",
                ownerPhoneNumber: "Телефон владельца АЗС",
                phoneNumber: "Телефон АЗС",
            },
            management: {
                company: "УК АЗС",
                inn: "ИНН УК",
                address: "Адрес УК",
                managementPhoneNumber: "Телефон УК",
                count: "Количество АЗС в сети",
                gasStationPhoneNumber: "Телефон АЗС",
                countByManagement: "Количество АЗС в сети по УК",
            },
            map: {
                loading: "Карта загружаются. Пожалуйста, подождите.",
            },
            news: {
                all: "Все",
                marketing: "Маркетинг",
                statuses: "Статусы",
                prices: "Цены",
            },
            statuses: {
                active: "Действующая",
                reconstruction: "Реконструкция",
                underConstruction: "Строится",
                closed: "Закрыто",
            },
        },
    },
    login: {
        pleaseAuth: "Пожалуйста, авторизуйтесь",
        loginPlaceholder: "E-mail, логин, телефон",
        passwordPlaceholder: "Пароль",
        submitText: "Войти",
        rememberMeText: "Запомнить меня",
        forgotPasswordText: "Забыли пароль?",
        didNotHaveAccount: "Ещё нет аккаунта?",
        registration: "Регистрация",
        requiredField: "Обязательное поле!",
        emailMinLength: "Email должен быть не короче 6 символов",
    },
    notification: {
        authorizationError: "Произошла ошибка авторизации",
        notAuthorized: "Вы не авторизованы",
        "notAuthorized.text":
      "Пожалуйста, авторизуйтесь на сайте, чтобы получить доступ.",
        welcome: "Добро пожаловать",
        welcomeText: "Авторизация прошла успешно",
        error: "Ошибка!",
        "invalid credentials": "Неправильные логин или пароль",
        networkError: {
            500: "Ошибка на стороне сервера!",
            503: "Сервер не доступен!",
            other: "Что-то пошло не так",
        },
    },
    reports: {
        actualPrice: {
            title: "Актуальные цены",
            regionSelect: "Выберите регион",
            companySelect: "Выберите компанию",
            tooltip: {
                closed: "АЗС на реконструкции.",
                dynamic: "АЗС с динамическим ценообразованием.",
            },
            extras: {
                search: "Поиск",
                pdf: "Экспорт в Excel",
                excel: "Экспорт в Pdf",
                print: "Печать",
                createReport: "Сформировать отчет",
                priceHistory: "История цен",
                perPage: "На странице",
            },
            priceHistory: {
                reports: "Отчёты",
                loading: "Загрузка",
                download: "Скачать",
                create: "Сформировать отчёт",
                table: {
                    from: "C",
                    to: "По",
                    created: "Дата запроса",
                    document: "Документ",
                    format: "Формат",
                    size: "Размер",
                },
            },
            tabs: {
                all: "Все",
                petrol: "Бензин",
                gas: "Газ",
                diesel: "Дизель",
            },
            fuel: {
                brand: "Бренд и адрес АЗС",
                tankFarm: "НБ/оператор",
                A0100: "АИ-100<br/>АИ-98Б",
                A0980: "АИ-98",
                A098B: "АИ-98Б",
                A0950: "АИ-95",
                A095B: "АИ-95Б",
                A0920: "АИ-92",
                A092B: "АИ-92Б",
                A0800: "АИ-80",
                D0DT0: "ДТ",
                D0DTB: "ДТ-Б",
                D0DTZ: "ДТ-З",
                D0DTK: "ДТ-П",
                G0KPG: "КПГ",
                P0SUG: "СУГ",
                P0SPG: "СПГ",
                D0DTL: "ДТ-Л",
                D0DTE: "ДТ-Е",
                D0DTA: "ДТ-А",
                D0DTX: "Прочее ДТ",
                PPCB2: "СУГ-1",
                "D0DTB-full": "ДТ-Брендовые",
                "D0DTZ-full": "ДТ зимний",
                "D0DTK-full": "ДТ-Прочие",
                "D0DTL-full": "ДТ-Летнее",
                "D0DTE-full": "ДТ-Межсезонное",
                "D0DTA-full": "ДТ-Арктика",
                checkDate: "Дата проверки",
            },
            analyticsFuel: {
                A0100: "АИ-100/АИ-98Б",
                A0980: "АИ-98",
                A098B: "АИ-98Б",
                A0950: "АИ-95",
                A095B: "АИ-95Б",
                A0920: "АИ-92",
                A092B: "АИ-92Б",
                A0800: "АИ-80",
                D0DT0: "ДТ",
                D0DTB: "ДТ-Б",
                D0DTZ: "ДТ-З",
                D0DTK: "ДТ-П",
                D0DTX: "Прочее ДТ",
                G0KPG: "КПГ",
                P0SUG: "СУГ",
                D0DTL: "ДТ-Л",
                D0DTE: "ДТ-Е",
                D0DTA: "ДТ-А",
                PPCB2: "СУГ-1",
            },
            settings: {
                column: {
                    A0100: "АИ-100/АИ-98Б",
                    A0980: "АИ-98",
                    A098B: "98Б",
                    A0950: "АИ-95",
                    A095B: "АИ-95Б",
                    A0920: "АИ-92",
                    A092B: "АИ-92Б",
                    A0800: "АИ-80",
                    D0DT0: "ДТ",
                    D0DTB: "ДТ Брендовые",
                    D0DTZ: "ДТ Сезонное",
                    D0DTK: "ДТ Прочие",
                    G0KPG: "КПГ",
                    D0DTL: "ДТ-Летнее",
                    D0DTE: "ДТ-Межсезонное",
                    D0DTA: "ДТ-Арктика",
                    D0DTX: "Прочее ДТ",
                    PPCB2: "СУГ-1",
                    P0SUG: "СУГ",
                    P0SPG: "СПГ",
                    updatedAt: "Дата проверки",
                },
            },
            analyticsFilters: {
                A0100: "AИ-100",
                A0980: "AИ-98",
                A098B: "AИ-98Б",
                A0950: "AИ-95",
                A095B: "AИ-95Б",
                A0920: "AИ-92",
                A092B: "AИ-92Б",
                A0800: "AИ-80",
                D0DT0: "ДТ",
                D0DTB: "ДТ-Б",
                D0DTZ: "ДТ-З",
                D0DTK: "ДГК",
                D0DTL: "ДТЛ",
                D0DTE: "ДТЕ",
                D0DTA: "ДТА",
                D0DTX: "Прочее ДТ",
                G0KPG: "КПГ",
                P0SUG: "СУГ",
                PPCPS: "СПБТ",
                DTZK5: "ДТ-З-К5",
                DDEC3: "ДТ Евро C-III",
                DDEE3: "ДТ Евро E-III",
                DDE23: "ДТ Евро 2-III",
                DDE33: "ДТ Евро 2-III",
                DDWK2: "ДТ класс 2 (ДТ-З-К5) минус 32",
                DDWF5: "ДТ сорт F (ДТ-З-К5) минус 20",
                DTEK5: "ДТ-Е-К5",
                DDTZ3: "ДТ (ДТ-А-К5)",
                DDEF3: "ДТ Евро F-III",
                PPCPB: "Пропан бутан технический",
            },
        },
        basises: {
            fuel: {
                brand: "НБ/оператор",
                A0100: "АИ-100<br/>АИ-98",
                A0950: "АИ-95",
                A0920: "АИ-92",
                A0800: "АИ-80",
                D0DTL: "ДТ-Л",
                D0DTE: "ДТ-М",
                D0DTZ: "ДТ-З",
                D0DTA: "ДТ-А",
                PPCB2: "ПБТ",
                P0SUG: "ПБА",
                "D0DTL-full": "ДТ-Л (Сорта A, B, C)",
                "D0DTE-full": "ДТ-М (Сорта D, E, F)",
                "D0DTZ-full": "ДТ-З (Классы 1, 2)",
                "D0DTA-full": "ДТ-А (Классы 3, 4)",
                "PPCB2-full": "",
                "P0SUG-full": "",
                checkDate: "Дата обновления",
            },
            settings: {
                column: {
                    A0100: "АИ-100 АИ-98",
                    A0980: "АИ-98",
                    A098B: "98Б",
                    A0950: "АИ-95",
                    A095B: "АИ-95Б",
                    A0920: "АИ-92",
                    A092B: "АИ-92Б",
                    A0800: "АИ-80",
                    D0DT0: "ДТ",
                    D0DTB: "ДТ-Б",
                    D0DTZ: "ДТ-З",
                    D0DTK: "ДТ-П",
                    G0KPG: "КПГ",
                    D0DTL: "ДТ-Л",
                    D0DTE: "ДТ-М",
                    D0DTA: "ДТ-А",
                    PPCB2: "ПБТ",
                    P0SUG: "ПБА",
                    updatedAt: "Дата обновления",
                },
            },
        },
        personalPrice: {
            fuel: {
                A0100: "АИ-100",
                A0980: "АИ-98",
                A098B: "АИ-98Б",
                A0950: "АИ-95",
                A095B: "АИ-95Б",
                A0920: "АИ-92",
                A092B: "АИ-92Б",
                A0800: "АИ-80",
                D0DT0: "ДТ",
                D0DTB: "ДТ-Б",
                D0DTZ: "ДТ-З",
                D0DTK: "ДТ-П",
                G0KPG: "КПГ",
                P0SUG: "СУГ",
                P0SPG: "СПГ",
                D0DTL: "ДТ-Л",
                D0DTE: "ДТ-М",
                D0DTA: "ДТ-А",
                PPCB2: "СУГ-1",
            },
        },
        gasStationYandexMap: {
            regionSelect: "Выберите регион",
        },
    },
    analytics: {
        title: "Аналитика",
        wrapperTitle: "График изменения цен",
        chart: {
            axisLeftLegend: "Цена",
            axisBottomLegend: "Дата",
            noInfo: "Нет данных для отображения",
            filter: {
                myStation: "Моя АЗС",
                ownGasStation: "Выбери свою АЗС",
                gasStations: "Конкуренты",
                gasStationsSecondary: "Выберите конкурентов",
                fuelTypes: "Виды топлива",
                noContent: "Выберите АЗС",
                fuelTypesSecondary: "Выберите один или несколько видов",
            },
            wholesaleFilter: {
                myWholesale: "Нефтебаза",
                myWholesalePlaceholder: "Выберите НБ",
                seller: "Продавец",
                sellerPlaceholder: "Выберите продавца",
                fuelTypes: "Типы номенклатур",
                fuelTypesPlaceholder: "Выберите номенклатуру",
            },
        },
        datePresets: {
            day: "За день",
            week: "За неделю",
            month: "За месяц",
            quarter: "За квартал",
            year: "За год",
        },
    },
    map: {
        tooltip: {
            fuelPricesDate: "Цена на топливо на",
        },
        ownGasStations: "Мои АЗС",
    },
    menu: {
        submenu: {
            gasStationPrices: "Цены на АЗС",
            tankFarmPrices: "Цены на НБ",
        },
        menuItem: {
            ActualPriceReport: "Актуальные цены",
            SmallWholesale: "Цены на НБ",
            Analytics: "Аналитика",
            PriceStatistic: "График изменения цен",
            GasStationMap: "Карта АЗС",
            PersonalGasStations: "Список моих АЗС",
            RecommendedPrices: "Рекомендованные цены",
        },
    },
    user: {
        headerMenu: {
            signOut: "Выйти",
            settings: "Настройки",
        },
        settingsMenu: {
            basicSettings: "Основные настройки",
            otherSettings: "Доплнительные настройки",
        },
        settingsItem: {
            mail: "Адрес электронной почты",
            notification: "Уведомления",
            on: "Вкл",
            off: "Выкл",
            btnSubmit: "Сохранить",
        },
    },
    title: {
        pages: {
            login: "Авторизация",
            actualPrices: "Актуальные цены на АЗС",
        },
    },
    utils: {
        loading: "Загрузка...",
    },
};
