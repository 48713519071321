import React, {useState} from 'react';
import * as Yup from 'yup';
import {FormikHelpers} from "formik";
import {notification} from "antd";
import {useMutation} from '@apollo/react-hooks';
import {useTranslation} from "react-i18next";

import {
    createToken as CreateTokenMutation,
    createTokenVariables
} from "lib/graphql/mutations/CreateToken/__generated__/createToken";
import TokenStorage from "lib/TokenStorage";
import {LoginFormValues} from "lib/types";
import LoginComponent from "components/LoginComponent";
import CREATE_TOKEN from "lib/graphql/mutations/CreateToken";
import {CustomStorage, SELECTED_FUELS, SELECTED_FUELS_TANKS} from "lib/helpers/Storage";
import {CreateTokenInput} from "lib/graphql/globalTypes";


const LoginContainer: React.FC = () => {
    const {t} = useTranslation();
    const [rememberMe, setRememberMe] = useState(true);
    const [createToken, {loading}] = useMutation<CreateTokenMutation, createTokenVariables>(
        CREATE_TOKEN,
    );
    // TODO: нужно подобрать тим данных для функции

    const handleSubmit = async (params: CreateTokenInput, {setStatus}: FormikHelpers<CreateTokenInput>) => {
        try {
            await createToken({
                variables: {
                    input: {
                        email: params.email,
                        password: params.password,
                        remember: rememberMe
                    },
                },
            }).then(
                (response) => {
                    if (response && response.data) {
                        const token = response.data.createToken;
                        TokenStorage.setToken({accessToken: token});

                        CustomStorage.setUpdatedValue(SELECTED_FUELS);
                        CustomStorage.setUpdatedValue(SELECTED_FUELS_TANKS);

                        notification.success({
                            message: t('notification.welcome'),
                            description: t('notification.welcomeText'),
                        });
                        document.location.pathname = '/';
                    } else {
                        throw new Error(t('notification.authorizationError'));
                    }

                },
            );


        } catch (err) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const message = 'graphQLErrors' in err ? err.graphQLErrors[0].message : err.message;
            const tMessage = t(`notification.${String(message)}`);
            setStatus(tMessage);
        }
    };

    const initialValues: LoginFormValues = {
        email: '',
        password: '',
    };

    const validationSchema: Yup.ObjectSchema = Yup.object({
        email: Yup.string().required(t('login.requiredField')),
        password: Yup.string().required(t('login.requiredField')),
    });

    return (
        <LoginComponent
            loading={loading}
            handleSubmit={handleSubmit}
            validationSchema={validationSchema}
            initialValues={initialValues}
            setRememberMe={setRememberMe}
        />
    );
};

export default LoginContainer;
