import React, { ReactNode, useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import { smallWholesalePrices } from "./dataMapping";
import SmallWholesaleComponent from "components/SmallWholesaleComponent";
import { ActualPriceTableRow } from "components/ActualPriceComponent/types";
import { DEFAULT_PAGINATION_SIZE } from "lib/helpers";
import { basisesHeaders } from "lib/helpers/fuelTypes";
import SMALL_WHOLESALE from "lib/graphql/queries/SmallWholesale";
import { UserProfileGasStations } from "lib/graphql/queries/GasStations/gasStations";
import { useSmallWholesaleColumns } from "lib/hooks/useSmallWholesale";
import {
    Basises,
    BasisListPaginationInput,
    Organization,
} from "lib/graphql/queries/SmallWholesale/types";
import InfoMessage from "containers/ActualPriceContainer/InfoMessage";
import SmallWholeSaleCompanyPicker from "components/SmallWholesaleComponent/SmallWholeSaleCompanyPicker";
import SmallWholeSaleRegionPicker from "components/SmallWholesaleComponent/SmallWholeSaleRegionPicker";
import { actualRegionsCount } from "lib/helpers/actualRegionsCount";

export type RegionSmallWholeSaleSelect = Basises & {
  count: number;
};

const SmallWholesaleContainer: React.FC = () => {
    const [onFilters, setOnFilters] = useState("");
    const [pageTotal, setPageTotal] = useState(0);
    const [listOffset, setListOffset] = useState(0);
    const [defaultPageSize, setDefaultPageSize] = useState(
        DEFAULT_PAGINATION_SIZE
    );
    const [actualPriceData, setActualPriceData] = useState<
    Array<ActualPriceTableRow>
  >([]);

    const [gasStationItems, setGasStationItems] = useState<Array<Basises>>([]);
    const [tableHeaders, setTableHeaders] = useState<Array<string>>([
        ...basisesHeaders,
    ]);
    const [regions, setRegions] = useState<Array<RegionSmallWholeSaleSelect>>([]);
    const [companies, setCompanies] = useState<Organization[]>([]);
    const [pickedRegion, setPickedRegion] = useState<Array<string>>([]);
    const [pickedCompany, setPickedCompany] = useState<Array<string>>([]);
    const [filteredRegions, setFilteredRegions] = useState<Array<Basises>>([]);
    const [filteredAllData, setFilteredAllData] = useState<Array<Basises>>([]);
    const { data, loading } = useQuery<
    UserProfileGasStations,
    BasisListPaginationInput
  >(SMALL_WHOLESALE);

    useEffect(() => {
        if (gasStationItems) {
            const filteredRegion = gasStationItems.filter((item) => {
                return item.address && pickedRegion.includes(item.address);
            });
            setFilteredRegions(filteredRegion);
        }
    }, [pickedRegion, defaultPageSize, pageTotal]);

    function getUniqueTraders(regions: Basises[]) {
        return Array.from(
            new Set(
                regions.flatMap((region) =>
                    region.assortment.flatMap((assortment) => assortment.trader)
                )
            )
        );
    }

    useEffect(() => {
        const uniqueTradersAll = getUniqueTraders(gasStationItems);
        const uniqueTraders = getUniqueTraders(filteredRegions);

        if (pickedCompany.length === 0) {
            setCompanies(uniqueTradersAll);
        }

        if (pickedRegion.length > 0) {
            setCompanies(uniqueTraders);
        }
    }, [pageTotal, pickedRegion, pickedCompany]);

    useEffect(() => {
        const filteredByAllCompany = gasStationItems.filter((item) => {
            if (pickedCompany.length === 0 && pickedRegion.length === 0) {
                return true;
            }

            if (pickedCompany.length > 0 && pickedRegion.length > 0) {
                if (filteredRegions.includes(item)) {
                    return item.assortment.some((a) =>
                        pickedCompany.includes(a.trader.name)
                    );
                } else return;
            }

            if (pickedRegion.length > 0) {
                return filteredRegions.includes(item);
            }

            if (pickedCompany.length > 0) {
                return item.assortment.some((a) =>
                    pickedCompany.includes(a.trader.name)
                );
            }
        });

        setFilteredAllData(filteredByAllCompany);
        setListOffset(0);
        setPageTotal(filteredByAllCompany.length);
    }, [pickedCompany, pickedRegion, filteredRegions, gasStationItems]);

    useEffect(() => {
        setPickedCompany([]);
    }, [pickedRegion]);

    useEffect(() => {
        const actualPrice = smallWholesalePrices(
            filteredAllData.slice(listOffset, listOffset + defaultPageSize)
        ).filter((item) => {
            if (item.own) {
                return true;
            }
            if (pickedCompany.length === 0) {
                return true;
            }
            return pickedCompany.includes(item.brand);
        });
        setActualPriceData(actualPrice);
    }, [filteredAllData, listOffset]);

    useEffect(() => {
        if (!loading && data && data.userProfile.customer) {
            const { basises } = data?.userProfile.customer;
            const filteredEmptyBasises = basises.items.filter(it=> it.assortment.length)
            setGasStationItems(filteredEmptyBasises);
            setActualPriceData(
                smallWholesalePrices(data.userProfile.customer.basises.items)
            );
            setPageTotal(filteredEmptyBasises.length);
            setRegions(actualRegionsCount(filteredEmptyBasises));
        }
    }, [data]);

    const { columns } = useSmallWholesaleColumns({
        headers: tableHeaders,
        translateString: "reports.basises.fuel",
        onFilters,
        zeroHeader: "tankFarm",
    });

    const FilterByRegion: ReactNode = (
        <SmallWholeSaleRegionPicker
            regions={regions}
            pickedRegion={pickedRegion}
            setPickedRegion={setPickedRegion}
        />
    );

    const FilterByCompany: ReactNode = (
        <SmallWholeSaleCompanyPicker
            regions={companies}
            pickedCompany={pickedCompany}
            setPickedCompany={setPickedCompany}
        />
    );

    if (data?.userProfile.customer === null) {
        return <InfoMessage />;
    }

    return (
        <SmallWholesaleComponent
            data={actualPriceData}
            total={pageTotal}
            FilterByRegion={FilterByRegion}
            FilterByCompany={FilterByCompany}
            loading={loading}
            columns={columns}
            listOffset={listOffset}
            defaultPageSize={defaultPageSize}
            setDefaultPageSize={setDefaultPageSize}
            setListOffset={setListOffset}
            setOnFilters={setOnFilters}
            setTableHeaders={setTableHeaders}
        />
    );
};

export default SmallWholesaleContainer;
