import React from "react";
import {Select, Typography} from "antd";
import {useTranslation} from "react-i18next";

import {filterAnalyticsSelect, getGasStationTitle} from "lib/helpers";
import {GasStationsItem} from "lib/graphql/queries/GasStations/gasStations";
import StationStatusIcon from "components/_common/StationStatusIcon";

import "./style.scss";

const {Title} = Typography;
const {Option} = Select;

interface OwnerStationFilterProps {
    data: Array<GasStationsItem>;
    loading: boolean;
    showSearch: boolean;
    ownerStation: string;
    setOwnerStation: (value: (((prevState: string) => string) | string)) => void
}

const OwnStationFilter: React.FC<OwnerStationFilterProps> = ({
    data,
    loading,
    showSearch,
    setOwnerStation,
    ownerStation
}) => {
    const {t} = useTranslation();

    return (
        <div className="own-station-filter">
            <Title level={5}>
                {t("analytics.chart.filter.myStation")}
            </Title>

            <Select
                loading={loading}
                value={ownerStation}
                placeholder={t("analytics.chart.filter.ownGasStation")}
                style={{width: "100%"}}
                optionLabelProp="label"
                showSearch={showSearch}
                filterOption={filterAnalyticsSelect}
                onChange={(val: string) => setOwnerStation(val.toString())}
            >
                {data.map((item) => {
                    const label = getGasStationTitle(item.name, item.number);

                    return (
                        <Option
                            key={item.id}
                            value={item.id}
                            label={label}
                        >
                            {label}
                            <StationStatusIcon dynamicPrice={item.dynamicPricing} status={item.status} />

                            {" "}
                            <span className="analytics-select-item__address">({item.address})</span>
                        </Option>
                    )
                }
                )}
            </Select>
        </div>
    )
};

export default OwnStationFilter;
