import gql from "graphql-tag";

const PriceStatisticPage = gql`
    query statistic($input: StatisticInput!) {
        statistic(input: $input) {
            gasStation {
                id
                name
                number
                competitors {
                    id
                    name
                    number
                }
            }
            product {
                id
                code
                name
                fullName
            }
            data {
                date
                value
            }
        }
    }
`;

export default PriceStatisticPage;
