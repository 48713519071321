import React from "react";
import RecommendedPricesContainer from "containers/RecommendedPricesContainer";


const RecommendedPricesPage: React.FC = () => {
    return (
        <RecommendedPricesContainer />
    )
};

export default RecommendedPricesPage;
