import "./styles.scss";

import { Button, DatePicker, Modal, Pagination, Select } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    StatisticReport,
    StatisticReportPaginationInput,
    StatisticReportQuery,
} from "lib/graphql/queries/StatisticReport/types";
import {
    StatisticReportCreate,
    StatisticReportMutationInput,
} from "lib/graphql/mutations/StatisticReport/types";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

import { ACTUAL_PRICE_HISTORY_PAGINATION } from "lib/helpers/actualPriceHistory";
import type { Moment } from "moment";
import NEW_STATISTIC_REPORT from "../../../lib/graphql/queries/StatisticReport/newStatic";
import PriceHistoryModalTable from "./PriceHistoryModalTable";
import STATISTIC_REPORT_MUTATION_CREATE from "lib/graphql/mutations/StatisticReport";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

type RangeValue = [Moment | null, Moment | null] | null;

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  data: StatisticReportQuery | undefined;
  loading: boolean;
}

const ActualPricePriceHistoryModal: React.FC<Props> = ({
    isModalVisible,
    setIsModalVisible,
}) => {
    const { t } = useTranslation();
    const intervalRef = useRef<number | null>(null);
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [dates, setDates] = useState<RangeValue>(null);
    const [tableData, setTableData] = useState<StatisticReport[]>([]);
    const [selectValue, setSelectValue] = useState<number>(0);
    const [total, setTotal] = useState(0);
    const [loadData, { loading, data, refetch }] = useLazyQuery<
    StatisticReportQuery | undefined,
    StatisticReportPaginationInput
  >(NEW_STATISTIC_REPORT);
    const [createReport, { loading: reportLoading }] = useMutation<
    StatisticReportCreate,
    StatisticReportMutationInput
  >(STATISTIC_REPORT_MUTATION_CREATE);
    const onModalClose = () => {
        setIsModalVisible(false);
    };

    const onPaginationChange = (page: number) => {
        setCurrentPage(page);
        setOffset(ACTUAL_PRICE_HISTORY_PAGINATION * (page - 1));
    };

    const pagination = (
        <Pagination
            total={total}
            showSizeChanger={false}
            current={currentPage}
            onChange={onPaginationChange}
            pageSize={ACTUAL_PRICE_HISTORY_PAGINATION}
            defaultPageSize={ACTUAL_PRICE_HISTORY_PAGINATION}
        />
    );
    useEffect(() => {
        loadData({
            variables: {
                pagination: {
                    limit: ACTUAL_PRICE_HISTORY_PAGINATION,
                    offset: offset,
                },
            },
        });
    }, [offset, currentPage]);

    useEffect(() => {
        const responseList = data?.statisticReport.list;
        const responseData = responseList?.items;
        const responseTotal = responseList?.total;

        responseTotal && setTotal(responseTotal);
        responseData && setTableData(responseData);
    }, [data]);

    useEffect(() => {
        const isAnyWithPendingStatus = tableData.some(
            (item) => item.status === "PENDING"
        );

        if (isAnyWithPendingStatus) {
            intervalRef.current = window.setInterval(() => {
                void refetch();
            }, 7000);
        } else {
            intervalRef.current && clearInterval(intervalRef.current);
        }
        return () => {
            intervalRef.current && clearInterval(intervalRef.current);
        };
    }, [tableData, loadData]);

    const disabledDate = (date: Moment) => {
        if (!dates) {
            return false;
        }

        const tooLate = dates[0] && date.diff(dates[0], "days") > 365;
        const tooEarly = dates[1] && dates[1].diff(date, "days") > 365;

        return !!tooEarly || !!tooLate;
    };

    const onReportCreate = useCallback(() => {
        if (selectValue === 0) {
            void createReport({
                variables: {
                    input: {
                        layout: "ACTUAL",
                    },
                },
            }).then((response) => {
                const data = response.data?.statisticReport.create;
                data &&
          setTableData((prev) => [
              data,
              ...prev.slice(0, tableData.length - 1),
          ]);

                data && setTotal((prev) => prev + 1);
            });
        }
        if (selectValue === 1) {
            if (dates && dates[0] && dates[1]) {
                void createReport({
                    variables: {
                        input: {
                            layout: "HISTORY",
                            from: dates[0]?.toISOString(),
                            to: dates[1]?.toISOString(),
                        },
                    },
                }).then((response) => {
                    const data = response.data?.statisticReport.create;

                    const newData = [...tableData].slice(0, tableData.length - 1);

                    data && setTableData([data, ...newData]);

                    data && setTotal(total + 1);
                });
            }
        }
    }, [dates, selectValue]);

    const handleChange = (value: number) => {
        setSelectValue(value);
    };

    const select = (
        <Select defaultValue={0} style={{ width: "33%" }} onChange={handleChange}>
            <Select.Option value={0}>{t("reports.actualPrice.title")}</Select.Option>
            <Select.Option value={1}>
                {t("reports.actualPrice.extras.priceHistory")}
            </Select.Option>
        </Select>
    );
    return (
        <Modal
            title={t("reports.actualPrice.priceHistory.reports")}
            className="actual-price-history-modal"
            visible={isModalVisible}
            footer={null}
            onCancel={onModalClose}
            maskClosable
            centered
        >
            <div className="actual-price-history-modal__range">
                {select}
                {!!selectValue && (
                    <RangePicker
                        placeholder={[
                            t("reports.actualPrice.priceHistory.table.from"),
                            t("reports.actualPrice.priceHistory.table.to"),
                        ]}
                        onCalendarChange={(val) => setDates(val)}
                        disabledDate={disabledDate}
                    />
                )}
                <Button
                    className="actual-price-history-modal__button"
                    onClick={onReportCreate}
                >
                    {t("reports.actualPrice.priceHistory.create")}
                </Button>
            </div>

            <PriceHistoryModalTable
                tableData={tableData}
                loading={loading && reportLoading}
                pagination={pagination}
            />
        </Modal>
    );
};

export default ActualPricePriceHistoryModal;
