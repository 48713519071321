import gql from "graphql-tag";

const PersonalGasStationFragment = gql`
    fragment PersonalGasStationFragment on GasStation {
        id
        name
        number
        address
        status
        fuelPrices {
            product {
                id
                code
                name
                fullName
            }
        }
        location {
            latitude
            longitude
        }
        
    }
    `;

export default PersonalGasStationFragment;
