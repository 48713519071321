import React, { Fragment, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "antd";
import { ColumnsType } from "antd/es/table";

import CardWrapper from "components/_common/CardWrapper";
import PageMainHeader from "components/_common/PageMainHeader";
import { ActualPriceTableRow } from "components/ActualPriceComponent/types";
import ActualPriceExtras from "components/ActualPriceComponent/ActualPriceExtras";
import ActualPriceTable from "components/_common/ActualPriceTable";
import PaginationSize from "components/_common/Pagination/PaginationSize";
import ActualPricePicker from "../ActualPriceComponent/ActualPricePicker";

interface WholesaleProps {
  total: number;
  loading: boolean;
  FilterByRegion: ReactNode;
  FilterByCompany: ReactNode;
  defaultPageSize: number;
  listOffset: number;
  columns: ColumnsType<ActualPriceTableRow>;
  data: ActualPriceTableRow[];
  setDefaultPageSize: (value: ((prevState: number) => number) | number) => void;
  setListOffset: (value: ((prevState: number) => number) | number) => void;
  setOnFilters: (value: ((prevState: string) => string) | string) => void;
  setTableHeaders: (
    value: ((prevState: Array<string>) => Array<string>) | Array<string>
  ) => void;
}

const SmallWholesaleComponent: React.FC<WholesaleProps> = ({
    data,
    total,
    loading,
    columns,
    listOffset,
    FilterByRegion,
    FilterByCompany,
    defaultPageSize,
    setDefaultPageSize,
    setListOffset,
    setOnFilters,
    setTableHeaders,
}) => {
    const { t } = useTranslation();
    const title = t("reports.actualPrice.title");
    const [currentPage, setCurrentPage] = useState(1);

    const paginationChange = (page: number) => {
        setCurrentPage(page);
        setListOffset(defaultPageSize * (page - 1));
    };

    useEffect(() => {
        setCurrentPage(listOffset / defaultPageSize + 1);
    }, [listOffset]);

    const pagination = (
        <Pagination
            total={total}
            showSizeChanger={false}
            current={currentPage}
            pageSize={defaultPageSize}
            onChange={paginationChange}
            defaultPageSize={defaultPageSize}
        />
    );

    return (
        <Fragment>
            <PageMainHeader title={title} />

            <CardWrapper>
                <div className="card-table__control-panel">
                    <div className="card-table__selects">
                        <div className="card-table__region-select">{FilterByRegion}</div>
                        <div className="card-table__region-select">{FilterByCompany}</div>
                    </div>
                    <div className="card-table__control-panel-right">
                        <div className="card-table__control-panel-item">
                            <ActualPriceExtras
                                fuelStorageName="selectedFuelsTanks"
                                setTableHeaders={setTableHeaders}
                            />
                        </div>
                    </div>
                </div>

                <div className="card-table__mobile-picker">
                    <ActualPricePicker />
                </div>

                <div className="actual-price-table">
                    <ActualPriceTable
                        data={data}
                        zeroHeader="tankFarm"
                        loading={loading}
                        columns={columns}
                        setOnFilters={setOnFilters}
                    />
                </div>

                <div className="gas-station-table-pagination">
                    <PaginationSize
                        setListOffset={setListOffset}
                        setCurrentPage={setCurrentPage}
                        setDefaultPageSize={setDefaultPageSize}
                    />
                    {pagination}
                </div>
            </CardWrapper>
        </Fragment>
    );
};

export default SmallWholesaleComponent;
