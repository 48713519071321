import enModules from "./modules";

export default {
    ...enModules,
    gasStation: {
        types: {
            title: "Fuel",
        },
        list: {
            title: "Gas stations list",
            tabs: {
                catalog: "List",
                map: "Map",
            },
            table: {
                brand: "Brand",
                managementCompany: "Management",
                status: "Status",
                fuelTypes: "Types of fuel",
            },
        },
        personalArea: {
            titles: {
                microMarket: "Gas station micro market",
                photos: "Photos",
                actualPrices: "Actual prices",
                actualPricesDiscount: "Discounted prices",
                info: "Information",
                news: "News",
            },
            services: {
                title: "Services",
                parking: "Parking",
                restroom: "Restroom",
                atm: "ATM",
                tireFitting: "Tire fitting",
                carWash: "Car wash",
                shop: "Score",
                service: "Service",
                pharmacy: "Pharmacy",
                cafe: "A cafe",
            },
            fuelCard: {
                title: "Fuel Cards",
                lickard: "Lickard",
                tinkoff: "Tinkoff",
                bpPartner: "BP Partner",
                tatneftPartner: "Partner-Tatneft",
                taif: "TAIF",
            },
            personalInfo: {
                owner: "Владелец АЗС",
                inn: "ИНН владельца",
                address: "Адрес компании владельца",
                ownerPhoneNumber: "Телефон владельца АЗС",
                phoneNumber: "Телефон АЗС",
            },
            management: {
                company: "УК АЗС",
                inn: "ИНН УК",
                address: "Адрес УК",
                managementPhoneNumber: "Телефон УК",
                count: "Количество АЗС в сети",
                gasStationPhoneNumber: "Телефон АЗС",
                countByManagement: "Количество АЗС в сети по УК",
            },
            map: {
                loading: "Maps are loading. Please, wait.",
            },
            news: {
                all: "All",
                marketing: "Marketing",
                statuses: "Statuses",
                prices: "Prices",
            },
            statuses: {
                active: "Active",
                reconstruction: "Reconstruction",
                underConstruction: "Under Construction",
                closed: "Closed",
            },
        },
    },
    login: {
        pleaseAuth: "Please, sign in",
        loginPlaceholder: "E-mail, login, phone number",
        passwordPlaceholder: "Password",
        submitText: "Sign In",
        rememberMeText: "Remember me",
        forgotPasswordText: "Forgot password?",
        didNotHaveAccount: "Forgot your password?",
        registration: "Registration",
        requiredField: "Required field!",
        emailMinLength: "Email might be 6 characters or more",
    },
    notification: {
        notAuthorized: "Some authorization error",
        "notAuthorized.text": "Please, sign in to gain access",
        welcome: "Welcome",
        "welcome.text": "You have successfully logged in",
        error: "Error",
        "invalid credentials": "Invalid credentials",
        networkError: {
            500: "Server error",
            503: "Server not respond",
            other: "Something goes wrong",
        },
    },
    reports: {
        actualPrice: {
            title: "Actual prices",
            regionSelect: "Choose region",
            companySelect: "Choose company",
            tooltip: {
                closed: "Gas station under reconstruction.",
                dynamic: "Gas station with dynamic pricing.",
            },
            extras: {
                search: "Search",
                pdf: "Export to Excel",
                excel: "Export to Pdf",
                print: "Print",
                createReport: "Generate report",
                priceHistory: "Price history",
                perPage: "Per page",
            },
            priceHistory: {
                reports: "Reports",
                loading: "Loading",
                download: "Download",
                create: "Generate a report",
                table: {
                    from: "From",
                    to: "To",
                    created: "Request date",
                    document: "Document",
                    format: "Format",
                    size: "Size",
                },
            },
            tabs: {
                all: "All",
                petrol: "Patrol",
                gas: "Gas",
                diesel: "Diesel",
            },
            fuel: {
                brand: "Brand & address",
                tankFarm: "TF/Trader",
                A0100: "100/98B",
                A0980: "98",
                A098B: "98B",
                A0950: "95",
                A095B: "95B",
                A0920: "92",
                A092B: "92B",
                A0800: "80",
                D0DT0: "D",
                D0DTB: "DB",
                D0DTZ: "DW",
                D0DTK: "DO",
                G0KPG: "CNG",
                P0SUG: "LPG",
                D0DTL: "ДТ-Л",
                D0DTX: "Other Diesel",
                D0DTE: "ДТ-М",
                D0DTA: "ДТ-А",
                PPCB2: "СУГ-1",
                "D0DTB-full": "Branded Diesel",
                "D0DTZ-full": "Winter/Arctic Diesel",
                "D0DTK-full": "Other Diesel",
                "D0DTL-full": "ДТ-Летнее",
                "D0DTE-full": "ДТ-Межсезонное",
                "D0DTA-full": "ДТ-Арктика",
                checkDate: "Update",
            },
            analyticsFuel: {
                A0100: "100/98B",
                A0980: "98",
                A098B: "98B",
                A0950: "95",
                A095B: "95B",
                A0920: "92",
                A092B: "92B",
                A0800: "80",
                D0DT0: "D",
                D0DTB: "DB",
                D0DTZ: "DW",
                D0DTK: "DO",
                G0KPG: "CNG",
                P0SUG: "LPG",
                D0DTX: "Other Diesel",
                D0DTL: "ДТ-Л",
                D0DTE: "ДТ-М",
                D0DTA: "ДТ-А",
                P0SPG: "LNG",
                PPCB2: "СУГ-1",
            },
            settings: {
                column: {
                    A0100: "100/98B",
                    A0980: "98",
                    A098B: "98B",
                    A0950: "95",
                    A095B: "95B",
                    A0920: "92",
                    A092B: "92B",
                    A0800: "80",
                    D0DT0: "D",
                    D0DTB: "Branded Diesel",
                    D0DTZ: "Winter/Arctic Diesel",
                    D0DTK: "Other Diesel",
                    G0KPG: "CNG",
                    D0DTL: "ДТ-Летнее",
                    D0DTE: "ДТ-Межсезонное",
                    D0DTA: "ДТ-Арктика",
                    D0DTX: "Other Diesel",
                    PPCB2: "СУГ-1",
                    P0SUG: "LPG",
                    P0SPG: "LNG",
                    updatedAt: "Update",
                },
            },
            analyticsFilters: {
                A0100: "100",
                A0980: "98",
                A098B: "98B",
                A0950: "95",
                A095B: "95B",
                A0920: "92",
                A092B: "92B",
                A0800: "80",
                D0DT0: "D",
                D0DTB: "Branded Diesel",
                D0DTL: "Summer Diesel",
                D0DTE: "Normal Diesel",
                D0DTA: "Arctic Diesel ",
                D0DTZ: "Winter/Arctic Diesel",
                D0DTX: "Other Diesel",
                D0DTK: "Other Diesel",
                G0KPG: "CNG",
                P0SUG: "LPG",

                PPCPS: "СПБТ",
                DTZK5: "ДТ-З-К5",
                DDEC3: "ДТ Евро C-III",
                DDEE3: "ДТ Евро E-III",
                DDE23: "ДТ Евро 2-III",
                DDE33: "ДТ Евро 2-III",
                DDWK2: "ДТ класс 2 (ДТ-З-К5) минус 32",
                DDWF5: "ДТ сорт F (ДТ-З-К5) минус 20",
                DTEK5: "ДТ-Е-К5",
                DDTZ3: "ДТ (ДТ-А-К5)",
                DDEF3: "ДТ Евро F-III",
                PPCPB: "Technical propane butane",
            },
        },
        basises: {
            fuel: {
                brand: "TF/Trader",
                A0100: "100<br/>98",
                A0950: "95",
                A0920: "92",
                A0800: "80",
                D0DTL: "DS",
                D0DTE: "DN",
                D0DTZ: "DW",
                D0DTA: "DA",
                PPCB2: "LPGD",
                P0SUG: "LPGA ",
                "D0DTL-full": "Summer Diesel -5°C",
                "D0DTE-full": "Normal Diesel -15°C",
                "D0DTZ-full": "Winter Diesel -29°C",
                "D0DTA-full": "Arctic Diesel -40°C",
                "PPCB2-full": "Domestic LPG",
                "P0SUG-full": "Auto LPG",
                checkDate: "Update",
            },
            settings: {
                column: {
                    A0100: "100 98",
                    A0980: "98",
                    A098B: "98B",
                    A0950: "95",
                    A095B: "95B",
                    A0920: "92",
                    A092B: "92B",
                    A0800: "80",
                    D0DT0: "ДТ",
                    D0DTB: "ДТ-Б",
                    D0DTZ: "Winter Diesel ",
                    D0DTK: "ДТ-П",
                    G0KPG: "КПГ",
                    D0DTL: "Summer Diesel",
                    D0DTE: "Normal Diesel",
                    D0DTA: "Arctic Diesel ",
                    PPCB2: "Domestic LPG",
                    P0SUG: "Auto LPG",
                    updatedAt: "Update",
                },
            },
        },
        personalPrice: {
            fuel: {
                A0100: "100",
                A0980: "98",
                A098B: "98B",
                A0950: "95",
                A095B: "95B",
                A0920: "92",
                A092B: "92B",
                A0800: "80",
                D0DT0: "D",
                D0DTB: "DB",
                D0DTZ: "DW",
                D0DTK: "DO",
                G0KPG: "CNG",
                P0SUG: "LPG",
                P0SPG: "LNG",
                D0DTL: "ДТ-Л",
                D0DTE: "ДТ-М",
                D0DTA: "ДТ-А",
                PPCB2: "СУГ-1",
            },
        },
        gasStationYandexMap: {
            regionSelect: "Choose region",
        },
    },
    analytics: {
        title: "Analytics",
        wrapperTitle: "Price change schedule",
        chart: {
            axisLeftLegend: "Price",
            axisBottomLegend: "Date",
            noInfo: "No data to show",
            filter: {
                myStation: "My Gas Station",
                ownGasStation: "Choose your Gas Station",
                gasStations: "Competitors",
                gasStationsSecondary: "Choose competitors",
                noContent: "Choose Gas Station",
                fuelTypes: "Fuel types",
                fuelTypesSecondary: "Choose one or more fuel types",
            },
            wholesaleFilter: {
                myWholesale: "Wholesale",
                myWholesalePlaceholder: "Choose a wholesale",
                seller: "Seller",
                sellerPlaceholder: "Choose a seller",
                fuelTypes: "Type of nomenclature",
                fuelTypesPlaceholder: "Choose a nomenclature",
            },
        },
        datePresets: {
            day: "Day",
            week: "Week",
            month: "Month",
            quarter: "Quarter",
            year: "Year",
        },
    },
    map: {
        tooltip: {
            fuelPricesDate: "Fuel prices for",
        },
        ownGasStations: "Own Gas Stations",
    },
    menu: {
        submenu: {
            gasStationPrices: "Gas station prices",
            tankFarmPrices: "Tank farm prices",
        },
        menuItem: {
            ActualPriceReport: "Actual Prices",
            SmallWholesale: "Small Wholesale",
            Analytics: "Analytics",
            PriceStatistic: "Price change graph",
            GasStationMap: "Gas station map",
            PersonalGasStations: "My gas stations",
            RecommendedPrices: "Recommended prices",
        },
    },
    user: {
        headerMenu: {
            signOut: "Sign out",
            settings: "Settings",
        },
        settingsMenu: {
            basicSettings: "Basic Settings",
            otherSettings: "Other Settings",
        },
        settingsItem: {
            mail: "Email",
            notification: "Notifications",
            on: "yes",
            off: "no",
            btnSubmit: "Save changes",
        },
    },
    title: {
        pages: {
            login: "Login",
            actualPrices: "Actual prices for gas station",
        },
    },
    utils: {
        loading: "Loading...",
    },
};
