import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

import { RegionSelect } from "lib/graphql/queries/GasStations/gasStations";
import { RegionSmallWholeSaleSelect } from "containers/SmallWholesaleContainer";

const { Option } = Select;

interface GasStationYandexMapRegionPickerProps {
  pickedRegion: Array<string>;
  regions: Array<RegionSelect | RegionSmallWholeSaleSelect>;
  setPickedRegion: (
    value: ((prevState: Array<string>) => Array<string>) | Array<string>
  ) => void;
}

const GasStationYandexMapRegionPicker: React.FC<
  GasStationYandexMapRegionPickerProps
> = ({ pickedRegion, setPickedRegion, regions }) => {
    const { t } = useTranslation();
    return (
        <Select
            mode="multiple"
            className="region-picker__dropdown"
            value={pickedRegion}
            placeholder={t("reports.gasStationYandexMap.regionSelect")}
            onChange={(val) => setPickedRegion(val)}
            allowClear
            showSearch={false}
            virtual={false}
        >
            {regions.map((item) => (
                <Option value={item.id} key={item.id}>
                    {`${item.name} (${item.count})`}
                </Option>
            ))}
        </Select>
    );
};

export default GasStationYandexMapRegionPicker;
