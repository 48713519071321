import React, {useMemo} from "react";
import {Tooltip} from "antd";
import dayjs from "dayjs";
import {ColumnsType} from "antd/lib/table";
import {useTranslation} from "react-i18next";
import parse from "html-react-parser";

import {parsePrice} from "lib/helpers";
import {headerWidth} from "lib/helpers/actualPrices";
import {ActualPriceTableRow, ProductData} from "components/ActualPriceComponent/types";
import ActualPriceDeltaCell from "components/_common/ActualPriceTable/ActualPriceCell/ActualPriceDeltaCell";
import ActualPriceBrand from "components/_common/ActualPriceTable/ActualPriceCell/ActualPriceBrand";

interface Props {
    onFilters: string;
    zeroHeader: string;
    translateString: string;
    headers: Array<string>;
}

interface SmallWholesaleColumnsHook {
    columns: ColumnsType<ActualPriceTableRow>;
}

export function useSmallWholesaleColumns({
    headers,
    onFilters,
    translateString,
    zeroHeader,
}: Props): SmallWholesaleColumnsHook {
    const {t} = useTranslation();
    const isTankFarm = zeroHeader === 'tankFarm';

    const renderValue = (value: ProductData, header: string) => {
        // если filter, то рендер diff, иначе delta
        if (!value) {
            return null;
        }
        const converted = value.price ? value.price.toFixed(2) : "";
        const price = isTankFarm ? parsePrice(value.price) : converted;
        const delta = onFilters === header ? value.diff || 0 : value.delta;

        return <ActualPriceDeltaCell
            value={price}
            formatDelta={isTankFarm}
            delta={delta}
            changed={value.changed}
        />;
    };

    const columns = useMemo(() => {
        const columns: ColumnsType<ActualPriceTableRow> = [];

        headers.forEach((header) => {
            switch (header) {
            case 'A0100':
                columns.push({
                    align: 'left',
                    title: (<div id="A0100">{parse(t(`${translateString}.A0100`))}</div>),
                    width: 100,
                    dataIndex: ['products', 'A0100'],
                    key: 'A0100',
                    sorter: true,
                    render: (value: ProductData, item) => {
                        const data = value
                            || item.products['A098B']
                            || item.products['A0980']
                            || item.products['AA106'];

                        return renderValue(data, header);
                    },
                });
                break;
            case 'A0950':
                columns.push({
                    align: 'left',
                    title: (<div id="A0950">{parse(t(`${translateString}.A0950`))}</div>),
                    width: 100,
                    dataIndex: ['products', 'A0950'],
                    key: 'A0950',
                    sorter: true,
                    render: (value: ProductData, item) => {
                        const data = value || item.products['AA956'];

                        return renderValue(data, header);
                    },
                });
                break;
            case 'A0920':
                columns.push({
                    align: 'left',
                    title: (<div id="A0920">{parse(t(`${translateString}.A0920`))}</div>),
                    width: 100,
                    dataIndex: ['products', 'A0920'],
                    key: 'A0920',
                    sorter: true,
                    render: (value: ProductData, item) => {
                        const data = value || item.products['A092B'];

                        return renderValue(data, header);
                    },
                });
                break;
            case 'D0DTL':
                columns.push({
                    align: 'left',
                    title: (<div id="D0DTL">{parse(t(`${translateString}.D0DTL`))}</div>),
                    width: 100,
                    dataIndex: ['products', 'D0DTL'],
                    key: 'D0DTL',
                    sorter: true,
                    render: (value: ProductData, item) => {
                        const data = value
                            || item.products['DTLK5']
                            || item.products['DDEC3'];

                        return renderValue(data, header);
                    },
                });
                break;
            case 'D0DTE':
                columns.push({
                    align: 'left',
                    title: (<div id="D0DTE">{parse(t(`${translateString}.D0DTE`))}</div>),
                    width: 100,
                    dataIndex: ['products', 'D0DTE'],
                    key: 'D0DTE',
                    sorter: true,
                    render: (value: ProductData, item) => {
                        const data = value
                            || item.products['DTEK5']
                            || item.products['DDEF3']
                            || item.products['DDE05']
                            || item.products['DDEE3']
                            || item.products['DDED3'];

                        return renderValue(data, header);
                    },
                });
                break;
            case 'D0DTZ':
                columns.push({
                    align: 'left',
                    title: (<div id="D0DTZ">{parse(t(`${translateString}.D0DTZ`))}</div>),
                    width: 100,
                    dataIndex: ['products', 'D0DTZ'],
                    key: 'D0DTZ',
                    sorter: true,
                    render: (value: ProductData, item) => {
                        const data = value
                            || item.products['DTZK5']
                            || item.products['DDE23']
                            || item.products['DDE13'];

                        return renderValue(data, header);
                    },
                });
                break;
            case 'D0DTA':
                columns.push({
                    align: 'left',
                    title: (<div id="D0DTA">{parse(t(`${translateString}.D0DTA`))}</div>),
                    width: 100,
                    dataIndex: ['products', 'D0DTA'],
                    key: 'D0DTA',
                    sorter: true,
                    render: (value: ProductData, item) => {
                        const data = value
                            || item.products['DTAK5']
                            || item.products['DDTZ3']
                            || item.products['DDE43']
                            || item.products['DDE33'];

                        return renderValue(data, header);
                    },
                });
                break;
            case 'PPCB2':
                columns.push({
                    align: 'left',
                    title: (<div id="PPCB2">{parse(t(`${translateString}.PPCB2`))}</div>),
                    width: 100,
                    dataIndex: ['products', 'PPCB2'],
                    key: 'PPCB2',
                    sorter: true,
                    render: (value: ProductData, item) => {
                        const data = value
                                || item.products['PPCPS']
                                || item.products['PPCPB']
                                || item.products['PPPBA']
                                || item.products['PPCPC'];

                        return renderValue(data, header);
                    },
                });
                break;
            case 'updatedAt':
                columns.push({
                    align: 'center',
                    title: (<div id="checkDate">{t(`${translateString}.checkDate`)}</div>),
                    width: 150,
                    dataIndex: 'updatedAt',
                    key: 'updatedAt',
                    sorter: true,
                    render: (updatedAt?: dayjs.Dayjs) => (
                        updatedAt ? updatedAt.utc(false).format("DD.MM.YYYY") : ''
                    ),
                });
                break;
            default: {
                const title = t(`${translateString}.${header}-full`, '');

                columns.push({
                    align: 'left',
                    title: (
                        <Tooltip placement="top" title={title.length ? parse(title) : undefined}>
                            <div id={header}>
                                {parse(t(`${translateString}.${header}`))}
                            </div>
                        </Tooltip>
                    ),
                    width: headerWidth(header),
                    dataIndex: ['products', header],
                    key: header,
                    sorter: true,
                    render: (value: ProductData) => {
                        return renderValue(value, header);
                    },
                });
                break;
            }
            }
        });

        columns.unshift({
            title: (<div id="brand">{t(`reports.actualPrice.fuel.${zeroHeader}`)}</div>),
            width: 200,
            dataIndex: 'brand',
            key: 'brand',
            fixed: 'left',
            render: (value: string, item) => (
                <ActualPriceBrand
                    value={value}
                    item={item}
                />
            )
        });

        return columns;

    }, [headers, isTankFarm, headers, onFilters]);

    return {columns};
}
