import React, { Fragment } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons/lib";
import { useTranslation } from "react-i18next";

interface Props {
  setSearchValue: (value: ((prevState: string) => string) | string) => void;
}

const ActualPriceSearch: React.FC<Props> = ({ setSearchValue }) => {
    const { t } = useTranslation();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    return (
        <Fragment>
            <Input
                onChange={handleInputChange}
                placeholder={t("reports.actualPrice.extras.search")}
                prefix={<SearchOutlined className="grey-icon" />}
            />
        </Fragment>
    );
};

export default ActualPriceSearch;
