import React, {Fragment} from "react";

import {GasStationStatus} from "lib/graphql/queries/UserProfile/__generated__/userProfile";
import DynamicGasStationTooltip from "components/_common/ActualPriceTable/Tooltips/DynamicGasStationTooltip";
import ClosedGasStationTooltip from "components/_common/ActualPriceTable/Tooltips/ClosedGasStationTooltip";

interface Props {
    dynamicPrice: boolean;
    status?: GasStationStatus
}

const StationStatusIcon: React.FC<Props> = ({status, dynamicPrice}) => {
    const dynamic = dynamicPrice ? <DynamicGasStationTooltip/> : null;
    const closed = status === GasStationStatus.RECONSTRUCTION ? <ClosedGasStationTooltip/> : null;

    return (
        <Fragment>
            {dynamic} {closed}
        </Fragment>
    )
};

export default StationStatusIcon;
