import React from "react";
import {Select, Typography} from "antd";
import {useTranslation} from "react-i18next";

import {filterBasisSelect} from "lib/helpers";
import {FilterBasis} from "lib/graphql/queries/WholesaleStats/types";

const {Title} = Typography;
const {Option} = Select;

interface TankFarmFilterProps {
    data: Array<FilterBasis>;
    loading: boolean;
    showSearch: boolean;
    setBasis: (value: (((prevState: string) => string) | string)) => void
}

const TankFarmFilter: React.FC<TankFarmFilterProps> = ({
    data,
    loading,
    showSearch,
    setBasis
}) => {
    const {t} = useTranslation();

    return (
        <div className="own-station-filter">
            <Title level={5}>
                {t("analytics.chart.wholesaleFilter.myWholesale")}
            </Title>

            <Select
                loading={loading}
                placeholder={t("analytics.chart.wholesaleFilter.myWholesalePlaceholder")}
                style={{width: "100%"}}
                onChange={(val: string) => setBasis(val.toString()) }
                showSearch={showSearch}
                filterOption={filterBasisSelect}
            >
                {data.map((item) =>
                    <Option
                        value={item.value}
                        key={item.value}
                    >
                        { item.label }
                    </Option>
                )}
            </Select>
        </div>
    )
};

export default TankFarmFilter;
