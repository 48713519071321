import gql from "graphql-tag";

const WHOLESALE_STATS = gql`
    query wholesaleStats($input: WholesaleStatsInput!){
        wholesaleStats(input: $input) {
            trader {
                id
                name
                shortName
            }
            product {
                id
                code
                name
                fullName
            }
            data {
                date
                value
            }
        }
    }
`;

export default WHOLESALE_STATS;
