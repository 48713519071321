import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { RegionSmallWholeSaleSelect } from "containers/SmallWholesaleContainer";

const { Option } = Select;

interface ActualPriceRegionPickerProps {
  pickedRegion: Array<string>;
  regions: Array<RegionSmallWholeSaleSelect>;
  setPickedRegion: (
    value: ((prevState: Array<string>) => Array<string>) | Array<string>
  ) => void;
}

const SmallWholeSaleRegionPicker: React.FC<ActualPriceRegionPickerProps> = ({
    regions,
    pickedRegion,
    setPickedRegion,
}) => {
    const { t } = useTranslation();

    return (
        <Select
            mode="multiple"
            className="region-picker__dropdown"
            value={pickedRegion}
            placeholder={t("reports.actualPrice.regionSelect")}
            onChange={(val) => setPickedRegion(val)}
            allowClear
            showSearch={false}
            virtual={false}
        >
            {regions.map((item) => (
                <Option value={item.address} key={item.id}>
                    {`${item.address} (${item.count})`}
                </Option>
            ))}
        </Select>
    );
};

export default SmallWholeSaleRegionPicker;
