import dayjs from "dayjs";

import {ActualPriceTableRow} from "components/ActualPriceComponent/types";
import {BasisAssortment, Basises} from "lib/graphql/queries/SmallWholesale/types";

let groupId = 500;

export const smallWholesalePrices = (data: Array<Basises>): Array<ActualPriceTableRow> => {
    let result: Array<ActualPriceTableRow> = [];
    const filteredEmptyDate = data.filter(it => it.assortment.length)

    filteredEmptyDate.forEach((station) => {
        const mainStation: ActualPriceTableRow = {
            key: station.id,
            groupId,
            brand: station.name,
            internalNumber: '', //такого свойства просто нет
            own: true,
            address: station.address,
            products: {},
            border: "top",
            dynamicPrice: false,
            hasIndicator: false,
        };

        const mainStationAssortment = mapAssortment(station.assortment, station.id);

        if (!mainStationAssortment.length) {
            mainStation.border = "both";
        }

        result.push(mainStation);

        result = [...result, ...mainStationAssortment];

        groupId += 50
    } );

    return result;
};


const mapAssortment = (stations: Array<BasisAssortment>, stationId: string): Array<ActualPriceTableRow> => {
    // Вот тут держим массив всех "отработанных" assortimentov
    const temp: Array<ActualPriceTableRow> = [];

    stations.forEach((station) => {
        const assortmentKey = `${stationId}.${station.trader.id}`;
        const existing = temp.find((item) => item.key === assortmentKey);

        if (existing === undefined) {
            // создаем новый элемент
            const record: ActualPriceTableRow = {
                key: assortmentKey,
                groupId,
                brand: station.trader.name,
                internalNumber: '', //такого свойства просто нет
                own: false,
                address: '', //такого свойства просто нет
                updatedAt: dayjs(station.updatedAt),
                products: {},
                dynamicPrice: false,
                hasIndicator: false
            };

            record.products[station.product.code] = {
                price: station.price,
                delta: station.delta,
                changed: false
            };

            temp.push(record);

        } else {
            // работаем с текущим
            existing.products[station.product.code] = {
                price: station.price,
                delta: station.delta,
                changed: false
            };

            if (existing.updatedAt && existing.updatedAt.isBefore(station.updatedAt)) {
                existing.updatedAt = dayjs(station.updatedAt);
            }

        }
    });

    if (temp.length) {
        temp[temp.length-1].border = 'bottom';
    }

    return temp;
};

