import React, {useEffect, useRef, useState} from "react";
import {useLazyQuery} from "@apollo/react-hooks";
import debounce from "lodash.debounce";

import BasisStatsFilters from "components/PriceStatisticBasisComponent/BasisStatsFilters";
import PriceStatisticBasisComponent from "components/PriceStatisticBasisComponent";
import WHOLESALE_STATS from "lib/graphql/queries/WholesaleStats";
import {LineChartData} from "lib/graphql/queries/PriceStatistic/types";
import {WholesaleStats, WholesaleStatsInput} from "lib/graphql/queries/WholesaleStats/types";

const PriceStatisticBasisContainer: React.FC = () => {
    const [basis, setBasis] = useState("");
    const [traders, setTraders] = useState<Array<string>>([]);
    const [products, setProducts] = useState<Array<string>>([]);
    const [analytics, setAnalytics] = useState<Array<LineChartData>>([]);

    const [
        getStatistic,
        {
            data: statsData,
            loading: statsLoading
        }
    ] = useLazyQuery<WholesaleStats, WholesaleStatsInput>(WHOLESALE_STATS);

    const getStatisticRef = useRef(debounce((inputBasis, traders, products) => {
        getStatistic({
            variables: {
                input: {
                    basis: inputBasis,
                    traders,
                    products
                }
            }
        })
    }, 200)).current;

    useEffect(() => {
        if (basis.length && products.length) {
            getStatisticRef(basis, traders, products);
        }

    }, [basis, traders, products]);

    useEffect(() => {
        if (statsData) {
            const lines: Array<LineChartData> = [];

            statsData.wholesaleStats.forEach((stat) => {
                lines.push({
                    id: `${stat.product.name} ${stat.trader.name}`,
                    data: stat.data
                })
            });

            setAnalytics(lines);
        }
    }, [statsData]);

    const Filters = (
        <BasisStatsFilters
            basis={basis}
            traders={traders}
            products={products}
            loading={statsLoading}
            setBasis={setBasis}
            setTraders={setTraders}
            setProducts={setProducts}
        />
    );

    return (
        <PriceStatisticBasisComponent
            data={analytics}
            basis={basis}
            traders={traders}
            products={products}
            loading={statsLoading}
            Filters={Filters}
        />
    )
};

export default PriceStatisticBasisContainer;
