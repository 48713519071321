import React from "react";
import {InfoCircleOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";

import "./style.scss";

interface InfoIconTooltipProps {
    title: string
}

const InfoIconTooltip: React.FC<InfoIconTooltipProps> = ({title}) => {
    return (
        <Tooltip title={title}>
            <InfoCircleOutlined className="info-icon-tooltip" />
        </Tooltip>
    )
};

export default InfoIconTooltip;
