import gql from "graphql-tag";

const RECOMMENDED_PRICES = gql`
    query userProfile {
        userProfile {
            id
            customer {
                id
                gasStations {
                    items {
                        id
                        name
                        number
                        address
                        region {
                            id
                        }
                        fuelPrices {
                            product {
                                id
                                code
                                name
                                fullName
                            }
                            price
                            delta
                            recommendedPrice
                            recommendedAt
                        }
                    }
                    total
                }
                pricingEnabled
                pricingPresets {
                    state
                    strategy
                    priority
                    rounding
                }
            }
        }
    }
`;

export default RECOMMENDED_PRICES;
