import React from "react";
import {Redirect, Route} from 'react-router-dom';

import TokenStorage from "lib/TokenStorage";

interface SecuredRouteProps {
    Layout: React.FC
}

const SecuredRoute: React.FC<SecuredRouteProps> = ({children, Layout}) => {
    const token = TokenStorage.getAccessToken();

    if (!token) {
        return <Redirect to='/user/login' />
    }

    return (
        <Route path="/">
            <Layout>
                { children }
            </Layout>
        </Route>
    );
};

export default SecuredRoute;
