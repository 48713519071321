import React, {useEffect, useLayoutEffect, useRef} from "react";
import { useTranslation } from "react-i18next";
import { Col } from "antd";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { XYChart } from "@amcharts/amcharts4/charts";
import kelly from "@amcharts/amcharts4/themes/kelly";

import { ChartTranslateStrings } from "./helper";
import { LineChartData, Tick } from "lib/graphql/queries/PriceStatistic/types";

import "./style.scss";
import {ScrollBarValue} from "./index";

am4core.useTheme(kelly);

interface AnalyticsChartProps {
    loading: boolean;
    data: Array<LineChartData>;
    fuelTypes: Array<string>;
    ownerStation: string;
    secondStations?: Array<string>;
    translateStr: ChartTranslateStrings;
    scrollBarValue: ScrollBarValue;
    setScrollBarValue: (val: Partial<ScrollBarValue> | ((val: Partial<ScrollBarValue>) => Partial<ScrollBarValue>)) => void;
}

const PriceStatisticChart: React.FC<AnalyticsChartProps> = ({
    data,
    loading,
    fuelTypes,
    ownerStation,
    translateStr,
    secondStations,
    scrollBarValue,
    setScrollBarValue,
}) => {
    const { t } = useTranslation();
    const chartRef = useRef<XYChart>();
    const indicatorRef = useRef<am4core.Container>();
    const scrollbarX = new am4charts.XYChartScrollbar();


    const createSeries = (chart: XYChart, name: string, data: Array<Tick>) => {
        const series = chart.series.push(new am4charts.StepLineSeries());
        series.dataFields.valueY = "value";
        series.dataFields.dateX = "date";
        series.name = name;
        series.tooltipText = "{valueY}: [b]{name}[/]";
        series.strokeWidth = 2;
        series.minBulletDistance = 15;
        series.startLocation = 0.9;
        series.data = data;

        const defaultColor = am4core.color("#FF5733");

        // Проверяем, если цвет - белый, используем альтернативный цвет
        if (
            (series.stroke as am4core.Color).hex.toLowerCase() === "#ffffff" ||
            (series.stroke as am4core.Color).hex.toLowerCase() === "white" ||
            (series.stroke as am4core.Color).hex.toLowerCase() === "#fff"
        ) {
            series.stroke = defaultColor;
        }

        const bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.strokeWidth = 2;
        bullet.circle.radius = 4;
        bullet.circle.fill = am4core.color("#fff");

        const bullethover = bullet.states.create("hover");
        bullethover.properties.scale = 1.3;

        return series;
    };

    const generateMessage = () => {
        const indicator = indicatorRef.current;
        const item = indicator?.children.values[0] as am4core.Label;

        if (!ownerStation.length) {
            item.text = t(translateStr.firstInput);

            return;
        }

        if (secondStations && !secondStations.length && ownerStation.length) {
            item.text = t(translateStr.secondInput);

            return;
        }

        if (
            !fuelTypes.length &&
            ownerStation.length &&
            secondStations?.length
        ) {
            item.text = t(translateStr.thirdInput);

            return;
        }

        if (loading) {
            item.text = t("utils.loading");

            return;
        }

        if (!data.length) {
            item.text = t("analytics.chart.noInfo");

            return;
        }
    };

    useEffect(() => {
        // Тут нужно вызывать функцию generateMessage и показывать нужное сообщение.
        // Далее нужно понимать, когда нужно убирать это сообщение и окно серое это в целом
        const chart = chartRef.current;
        const indicator = indicatorRef.current;

        generateMessage();

        if (!loading && fuelTypes.length && ownerStation.length) {
            if (chart && indicator) {
                const key = chart.tooltipContainer?.children.indexOf(indicator);

                key && chart.tooltipContainer?.children.values[key].hide(2000);
            }
        }
    }, [loading, data, fuelTypes, ownerStation, secondStations]);

    useLayoutEffect(() => {
        // Create axes
        const chart = am4core.create("chartdiv", am4charts.XYChart);
        let indicator: am4core.Container = new am4core.Container();
        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        const scrollbarX = new am4charts.XYChartScrollbar();
        chart.dateFormatter.inputDateFormat = "YYYY-M-DTH:m:sZ";
        chart.yAxes.push(new am4charts.ValueAxis());
        console.log(dateAxis,"1")
        chart.scrollbarX = scrollbarX;
        dateAxis.renderer.grid.template.location = 1;
        dateAxis.renderer.minGridDistance = 50;
        scrollbarX.start = scrollBarValue.start !== null ? scrollBarValue.start : 0.9;
        scrollbarX.end = scrollBarValue.end !== null ? scrollBarValue.end : 1;

        dateAxis.keepSelection = true;
        chart.legend = new am4charts.Legend();
        chart.cursor = new am4charts.XYCursor();


        dateAxis.events.on("startchanged", () => {
            console.log("Start changed:", dateAxis.start);

            setScrollBarValue(prev => ({start:dateAxis.start, ...prev}))
            console.log(dateAxis)

        });

        dateAxis.events.on("endchanged", () => {

            setScrollBarValue(prev => ({...prev ,end:dateAxis.end }))
            console.log("End changed:", dateAxis.end);

        });
        if (chart.tooltipContainer) {
            indicator = chart.tooltipContainer.createChild(am4core.Container);
            indicator.background.fill = am4core.color("grey");
            indicator.background.fillOpacity = 0.8;
            indicator.width = am4core.percent(100);
            indicator.height = am4core.percent(100);

            const indicatorLabel = indicator.createChild(am4core.Label);

            indicatorLabel.align = "center";
            indicatorLabel.valign = "middle";
            indicatorLabel.fontSize = 20;
        }

        indicatorRef.current = indicator;
        chartRef.current = chart;




        return () => {
            chart.dispose();
        };
    }, []);

    useEffect(() => {
        const chart = chartRef.current;

        if (chart) {
            chart.series.clear();

            data.forEach((item: LineChartData, i) => {
                const serie = createSeries(chart, item.id, item.data);
                scrollbarX.series.push(serie);

                const scrollSeriesItem = scrollbarX.scrollbarChart.series.getIndex(i);

                if (scrollSeriesItem) {
                    scrollSeriesItem.strokeWidth = 0.5;
                }
            });

            chart.scrollbarX = scrollbarX;
            chart.scrollbarX.parent = chart.bottomAxesContainer;

            chart.events.on("datavalidated", () => {
                if (scrollBarValue.start !== null) {
                    //@ts-ignore
                    chart.xAxes.getIndex(0).start = scrollBarValue.start;
                }
                if (scrollBarValue.end !== null) {
                    //@ts-ignore
                    chart.xAxes.getIndex(0).end = scrollBarValue.end;
                }
            });
        }
    }, [data]);


    return (
        <Col xl={16} lg={16} xs={24} md={24}>
            <div id="chartdiv" />
        </Col>
    );
};

export default PriceStatisticChart;
