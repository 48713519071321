import React, { ReactNode, useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import InfoMessage from "./InfoMessage";
import {
    GasStationIndicators,
    GasStationsItem,
    IndicatorsProps,
    RegionSelect,
    UserProfileGasStations,
} from "lib/graphql/queries/GasStations/gasStations";
import { actualPrices } from "containers/ActualPriceContainer/dataMapping";
import ActualPriceComponent from "components/ActualPriceComponent";
import { ActualPriceTableRow } from "components/ActualPriceComponent/types";
import ActualPriceRegionPicker from "components/ActualPriceComponent/_extras/ActualPriceRegionPicker";
import { DEFAULT_PAGINATION_SIZE } from "lib/helpers";
import { actualPriceHeaders } from "lib/helpers/fuelTypes";
import { GasStationPagination } from "lib/graphql/globalTypes";
import { useGasStationColumns } from "lib/hooks/useGasStationColumns";
import G_S_WITHOUT_COMPETITORS from "lib/graphql/queries/GasStations/GSWithoutCompetitors";
import MARKET_INDICATORS from "lib/graphql/queries/GasStations/MarketIndicators";

const ActualPriceContainer: React.FC = () => {
    const [pageTotal, setPageTotal] = useState(0);
    const [onFilters, setOnFilters] = useState("");
    const [listOffset, setListOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [actualPriceData, setActualPriceData] = useState<
    Array<ActualPriceTableRow>
  >([]);
    const [gasStationItems, setGasStationItems] = useState<
    Array<GasStationsItem>
  >([]);
    const [defaultPageSize, setDefaultPageSize] = useState(
        DEFAULT_PAGINATION_SIZE
    );
    const [tableHeaders, setTableHeaders] = useState<Array<string>>([
        ...actualPriceHeaders,
    ]);
    const [regions, setRegions] = useState<Array<RegionSelect>>([]);
    const [pickedRegion, setPickedRegion] = useState<Array<string>>([]);

    const { data, loading } = useQuery<
    UserProfileGasStations,
    GasStationPagination
  >(G_S_WITHOUT_COMPETITORS);
    const [getIndicators, { data: indicatorsData }] = useLazyQuery<
    GasStationIndicators,
    IndicatorsProps
  >(MARKET_INDICATORS);

    useEffect(() => {
        if (gasStationItems) {
            const slicedData: Array<GasStationsItem> = gasStationItems.slice(
                listOffset,
                listOffset + defaultPageSize
            );
            setActualPriceData(actualPrices(slicedData));

            getIndicators({
                variables: {
                    gasStations: slicedData.map((item) => item.id),
                },
            });
        }
    }, [listOffset, defaultPageSize, currentPage]);

    useEffect(() => {
        if (gasStationItems) {
            const actualPricesData = actualPrices(gasStationItems);

            if (!pickedRegion.length) {
                const sliced = actualPricesData.slice(
                    listOffset,
                    listOffset + defaultPageSize
                );
                setActualPriceData(sliced);

                setPageTotal(actualPricesData.length);

                return;
            }

            const filtered = actualPricesData.filter(
                (item) => item.region && pickedRegion.includes(item.region)
            );

            setActualPriceData(
                filtered.slice(listOffset, listOffset + defaultPageSize)
            );

            setPageTotal(filtered.length);
        }
    }, [pickedRegion, defaultPageSize, listOffset, pageTotal]);

    useEffect(() => {
        if (!loading && data && data.userProfile.customer) {
            const { gasStations } = data.userProfile.customer;

            const tempRegions: { [key: string]: RegionSelect } = {};
            const slicedData: Array<GasStationsItem> = gasStations.items.slice(
                0,
                listOffset
            );

            setGasStationItems([]);

            setGasStationItems(gasStations.items);

            setActualPriceData(actualPrices(slicedData));

            getIndicators({
                variables: {
                    gasStations: slicedData.map((item) => item.id),
                },
            });

            setPageTotal(gasStations.total);

            gasStations.items.forEach((station) => {
                const { id } = station.region;

                if (tempRegions[id]) {
                    tempRegions[id].count += 1;
                } else {
                    tempRegions[id] = Object.assign(station.region, {
                        count: 1,
                    });
                }
            });

            setRegions(Object.values(tempRegions));
        }
    }, [loading]);

    useEffect(() => {
        if (indicatorsData) {
            const data = [...actualPriceData];

            const indicatorsObj: { [key: string]: Array<string> } =
        indicatorsData.marketIndicators.reduce((obj, item) => {
            return {
                ...obj,
                [item.stationId]: item.fuels,
            };
        }, {});

            data.forEach((item) => {
                const station = indicatorsObj[item.key];

                if (!station) {
                    return;
                }

                station.forEach((fuel) => {
                    if (item.products[fuel]) {
                        item.products[fuel].changed = true;
                    }
                });
            });

            setActualPriceData(data);
        }
    }, [indicatorsData]);

    const { columns } = useGasStationColumns({
        headers: tableHeaders,
        translateString: "reports.actualPrice.fuel",
        onFilters,
        zeroHeader: "brand",
        sorter: false,
    });

    const FilterByRegion: ReactNode = (
        <ActualPriceRegionPicker
            regions={regions}
            pickedRegion={pickedRegion}
            setPickedRegion={setPickedRegion}
        />
    );

    if (data?.userProfile.customer === null) {
        return <InfoMessage />;
    }

    return (
        <ActualPriceComponent
            data={actualPriceData}
            total={pageTotal}
            loading={loading}
            columns={columns}
            tableHeaders={tableHeaders}
            FilterByRegion={FilterByRegion}
            defaultPageSize={defaultPageSize}
            setDefaultPageSize={setDefaultPageSize}
            setListOffset={setListOffset}
            setTableHeaders={setTableHeaders}
            setOnFilters={setOnFilters}
            listOffset={listOffset}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
        />
    );
};

export default ActualPriceContainer;
