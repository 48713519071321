import React,{Fragment} from "react";

const AuthLogo: React.FC = () => {
    return (
        <Fragment>
            <img
                className="login__form-logo"
                src={require("../../assets/logo_auth3.png")}
                alt=""
            />
        </Fragment>
    );
}

export default AuthLogo;