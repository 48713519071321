import React from 'react';
import { Alert, Button, Form, Input } from 'antd';
import {useFormik} from "formik";
import { ExclamationCircleFilled, LockOutlined, UserOutlined } from '@ant-design/icons/lib';
import * as Yup from "yup";
import {CreateTokenInput} from "lib/graphql/globalTypes";
import {LoginFormValues} from "lib/types";
import {useTranslation} from "react-i18next";

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleSubmit: (params: CreateTokenInput, status: any) => void;
    validationSchema: Yup.ObjectSchema;
    initialValues: LoginFormValues;
    loading: boolean;
}

const LoginForm: React.FC<Props> = ({
    handleSubmit,
    loading,
    initialValues,
    validationSchema,
}) => {
    const {t} = useTranslation();

    const {
        handleSubmit: formikHandleSubmit,
        setSubmitting,
        status,
        touched,
        errors,
        values,
        handleChange,
        isSubmitting,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleSubmit,
    });

    const error = status ? (
        <Alert
            className="login-form-alert-error"
            message={status}
            type="error"
            icon={<ExclamationCircleFilled />}
            showIcon
        />
    ) : null;

    return (
        <Form
            size="large"
            name="basic"
            onFinish={() => {
                formikHandleSubmit();
                setSubmitting(false);
            }}
        >
            <Form.Item
                name="email"
                help={touched.email && errors.email ? errors.email : undefined}
                validateStatus={
                    touched.email && errors.email ? 'error' : undefined
                }
            >
                <Input
                    id="email"
                    name="email"
                    className="login__form-input"
                    value={values.email}
                    prefix={<UserOutlined className="primary-color"/>}
                    onChange={handleChange}
                    placeholder={t('login.loginPlaceholder')}
                />
            </Form.Item>

            <Form.Item
                name="password"
                help={touched.password && errors.password ? errors.password : undefined}
                validateStatus={
                    touched.password && errors.password ? 'error' : undefined
                }
            >
                <Input.Password
                    id="password"
                    name="password"
                    className="login__form-input"
                    value={values.password}
                    prefix={<LockOutlined className="primary-color"/>}
                    onChange={handleChange}
                    placeholder={t('login.passwordPlaceholder')}
                />
            </Form.Item>
  
            {error}
   
            <Form.Item>
                <Button
                    size="large"
                    className="login__form-submit"
                    type="primary"
                    htmlType="submit"
                    disabled={isSubmitting}
                    loading={loading}
                    block
                >
                    { t('login.submitText') }
                </Button>
            </Form.Item>
        </Form>
    );
};


export default LoginForm;
