export default {
    "title": "Рекомендованные цены",
    "noData": "No data",
    "loading": "Идёт загрузка данных... Среднее время ожидания 10 минут.",
    "download": "Сформировать отчёт",
    "form": {
        "levelOfMarginality": "Уровень маржинальности",
        "levelOfMarginalityTooltip": "Выберите файл для загрузки с динамикой маржинальности",
        "priority": "Приоритет",
        "priorityTooltip": "Если вместо удержания доли рынка нужно более активно завоевывать реализацию на рынке сдвиньте бегунок правее",
        "sizeOfSales": "Объем реализации",
        "sizeOfSalesTooltip": "Выберите файл для загрузки с объёмами реализации",
        "strategy": "Стратегия",
        "strategyList": {
            "earning": "Объём",
            "margin": "Маржа",
            "volume": "Выручка"
        },
        "strategyTooltip": "Выберите основную цель, которую нужно достичь при оптимизации розничных цен на топливо",
        "submit": "Загрузить",
        "rounding": "Округление до (коп.)",
        "upload": "Загрузить"
    }
}
