import {GasStationStatus, Point} from "lib/graphql/queries/UserProfile/__generated__/userProfile";

import {BasisList} from "lib/graphql/queries/SmallWholesale/types";
import {LngLatLike} from "mapbox-gl";
import {ReactNode} from "react";

export interface Region {
   id: string;
   name: string;
   country: Country;
}

export enum PricingState {
   EMPTY = "EMPTY",
   PENDING = "PENDING",
   READY = "READY"
}

export enum PricingStrategy {
   EARNING = "EARNING",
   MARGIN = "MARGIN",
   VOLUME = "VOLUME"
}

export interface IndicatorsProps {
   gasStations: Array<string>;
}

export interface Indicator {
   stationId: string;
   fuels: Array<string>;
}

export interface GasStationIndicators {
   marketIndicators: Array<Indicator>;
}

export type RegionSelect = Region & {count: number};

export interface Country {
   id: string;
   name: string;
}

export interface FuelPriceProduct {
   id: string;
   code: string;
   name: string;
   fullName: string;
   title: string;
}

export interface GasStationFuelPrice {
   price: number;
   delta: number;
   updatedAt: string;
   product: FuelPriceProduct
   recommendedPrice: number;
   recommendedAt: string;
}

export interface GasStationManagementCompany {
   id: string;
   name: string;
   shortName: string;
}

export interface GasStationsItem {
   __typename: "GasStation";
   id: string;
   name: string;
   number: string;
   address: string;
   dynamicPricing: boolean;
   status: GasStationStatus;
   location: Point;
   fuelPrices: Array<GasStationFuelPrice>;
   managementCompany: GasStationManagementCompany;
   competitors: Array<GasStationsItem>;
   region: Region;
   hasIndicator: boolean;
}

export interface GasStations {
   __typename: "GasStationList";
   items: Array<GasStationsItem>;
   total: number;
}

export interface PricingPresets {
   state: PricingState;
   strategy: PricingStrategy;
   priority: number;
   rounding: number[];
}

export interface Customer {
   __typename: "Customer";
   gasStations: GasStations;
   basises: BasisList
   pricingEnabled: boolean;
   pricingPresets: PricingPresets;
}

export interface User {
   __typename: "User"
   id: string;
   firstName: string;
   lastName: string;
   email: string;
   authority: Array<string>;
   customer: Customer;
}

export interface UserProfileGasStations {
   userProfile: User
}


export interface GasStationYandexMapProperties{
   region: Region,
   brand: string,
   number: string | undefined,
   address: string | undefined,
   prices: number | undefined ,
   color: string,
   hoverColor: string,
   competitors?: string[],
  zIndex:number
}
export interface GasStationYandexMapGeometry{
   type: string,
   coordinates: number[]
}

export interface GasStationYandexMapData {
   id: string,
   type: string | undefined,
   properties: GasStationYandexMapProperties,
   geometry: GasStationYandexMapGeometry
}

export interface GasStationYandexMapCompetitors {
  id: string
   address: string,
   brand: string,
   prices: Array<GasStationYandexMapFuelPrices>,
}

export interface GasStationYandexMapFuelPrices{
   delta: number,
   price: number,
   product: GasStationYandexMapFuelPricesProduct,
   updatedAt: string,
}
export interface GasStationYandexMapFuelPricesProduct{
   code: string
   fullName: string
   id: string
   name: string
}


export interface GasStationYandexMapGeometry{
   type: string,
   coordinates: number[]
}

export interface GasStationYandexMapData {
   id: string,
   type: string | undefined,
   properties: GasStationYandexMapProperties,
   geometry: GasStationYandexMapGeometry
}

export interface ITemplateLayoutFactory {
   createClass: (template: string, options?: Record<string, unknown>) => HTMLElement;
}


export interface ICustomYMapsApi {
   templateLayoutFactory: ITemplateLayoutFactory;
}
export interface IYmaps {
   ymaps: ICustomYMapsApi;
}


export interface CircleLayoutProps {
   ymaps?: ICustomYMapsApi ;
   mark: GasStationYandexMapData;
}

export interface GasStationMapProps {
   points: Array<GasStationsItem>;
   FilterByRegion: ReactNode;
   ymaps?: ICustomYMapsApi;
}

export interface FeaturesMappingResult {
   coordinates: Array<LngLatLike>;
   data: GasStationYandexMapData[];
}

export interface GetOptimalZoomProps {
   (minLat: number, maxLat: number, minLng: number, maxLng: number): number;
}

export interface ToRadians {
   (angle: number): number;
}

export interface GetDistanceBetweenCoordinates {
   (lat1: number, lng1: number, lat2: number, lng2: number): number;
}

export type ImageModule = {
   default: string;
};