import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

import { DEFAULT_PAGINATION_SIZE } from "lib/helpers";
import { UserProfileGasStations } from "lib/graphql/queries/PersonalGasStation/PersonalGasStations";
import PERSONAL_GAS_STATIONS_LIST from "lib/graphql/queries/PersonalGasStation";
import { GasStationPagination } from "lib/graphql/globalTypes";
import { GasStationColumnType, GasStationType } from "lib/types";
import PersonalGasStationsComponent from "components/PersonalGasStationsComponent";
import { petrol, diesel, gas } from "lib/helpers/fuelTypes";

const PersonalGasStationsContainer: React.FC = () => {
    const { t } = useTranslation();
    const tempGasStations: Array<GasStationColumnType> = [];
    const [gasStations, setGasStations] = useState<Array<GasStationColumnType>>(
        []
    );
    const [listOffset, setListOffset] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);
    const [defaultPageSize, setDefaultPageSize] = useState(
        DEFAULT_PAGINATION_SIZE
    );
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [tableData, setTableData] = useState<Array<GasStationColumnType>>([]);
    const hashObject: { [key: string]: number } = {};
    [...petrol, ...diesel, ...gas].forEach(
        (item, index) => (hashObject[item] = index + 1)
    );

    const { data, loading, refetch } = useQuery<
    UserProfileGasStations,
    GasStationPagination
  >(PERSONAL_GAS_STATIONS_LIST, {
      variables: {
          pagination: {
              limit: 150,
              offset: listOffset,
          },
      },
  });
    const statuses = {
        ACTIVE: t("gasStation.personalArea.statuses.active"),
        RECONSTRUCTION: t("gasStation.personalArea.statuses.reconstruction"),
        UNDER_CONSTRUCTION: t("gasStation.personalArea.statuses.underConstruction"),
        CLOSED: t("gasStation.personalArea.statuses.closed"),
    };

    useEffect(() => {
        void refetch();
    }, [listOffset, defaultPageSize]);

    useEffect(() => {
        if (!loading && data) {
            setGasStations([]);
            let key = 500;

            setPageTotal(data.userProfile.customer.gasStations.total);

            // TODO: Мне не нравится, можно будет отрефакторить
            data.userProfile.customer.gasStations.items.forEach((item) => {
                const result: { [key: number]: GasStationType } = {};

                item.fuelPrices.forEach((fuel) => {
                    if (!fuel.product.code) {
                        return;
                    }
                    const code = hashObject[String(fuel.product.code)];
                    if (code) {
                        result[code] = fuel.product;
                    }
                });
                const fuelTypes = Object.values(result);
                tempGasStations.push({
                    id: item.id,
                    key,
                    brandName: item.name,
                    number: item.number,
                    brandAddress: item.address,
                    managementCompany: item.managementCompany?.name || "",
                    status: item.hasOwnProperty("status") ? statuses[item.status] : "",
                    fuelTypes,
                });

                key += 10;
            });
            setGasStations(tempGasStations);
        }
    }, [data, listOffset, pageTotal]);

    useEffect(() => {
        const tableData = [...gasStations];
        if (searchValue) {
            const filtered = tableData.filter(
                (it) =>
                    it?.number?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
          it?.brandAddress
              ?.toLowerCase()
              ?.includes(searchValue?.toLowerCase()) ||
          it?.brandName?.toLowerCase()?.includes(searchValue?.toLowerCase())
            );
            setTableData(filtered);
            setCurrentPage(1);
            setListOffset(0);
        }
        if (gasStations && !searchValue) {
            const slicedData: Array<GasStationColumnType> = gasStations.slice(
                0,
                defaultPageSize
            );

            setTableData(slicedData);
        }
    }, [searchValue, listOffset, defaultPageSize, gasStations]);

    return (
        <PersonalGasStationsComponent
            loading={loading}
            total={pageTotal}
            setListOffset={setListOffset}
            tableData={tableData}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setSearchValue={setSearchValue}
            defaultPageSize={defaultPageSize}
            setDefaultPageSize={setDefaultPageSize}
        />
    );
};

export default PersonalGasStationsContainer;
