import dayjs from "dayjs";

export const ACTUAL_PRICE_HISTORY_PAGINATION = 5;

export const periodDateFormat = (date: string): string => {
    return dayjs(date).utc(false).format("DD.MM.YYYY")
};

export const createdAtDateFormat = (date: string): string => {
    return dayjs(date).utc(false).format("DD.MM.YYYY HH:mm")
};
