import gql from "graphql-tag";
import ActualPricesGasStationFragment from "lib/graphql/fragments/ActualPrices/gasStation";

const G_S_WITHOUT_COMPETITORS= gql`
    query userProfile {
        userProfile {
            id
            customer {
                id
                gasStations {
                    items {
                        ...ActualPricesGasStationFragment
                    }
                    total
                }
            }
        }
    }
    ${ActualPricesGasStationFragment}
`;

export default G_S_WITHOUT_COMPETITORS;
