import gql from "graphql-tag";

const WHOLESALE_STATS_BASISES = gql`
    query basises {
        userProfile {
            id
            email
            customer {
                id
                basises {
                    items{
                        id
                        name
                        assortment {
                            id
                            trader {
                                id
                                name
                            }
                            product {
                                id
                                code
                                name
                                fullName
                            }
                        }
                    }
                    total
                }
            }
        }
    }
`;

export default WHOLESALE_STATS_BASISES;
