import gql from "graphql-tag";

const MARKET_INDICATORS = gql`
    query marketIndicators($gasStations: [ID!]!) {
        marketIndicators(gasStations: $gasStations) {
            stationId
            fuels
        }
    }
`;

export default MARKET_INDICATORS;
