import React from "react";
import {useTranslation} from "react-i18next";

import "./style.scss";

const NoData: React.FC = () => {
    const {t} = useTranslation();

    return (
        <div className="no-data">
            {t("recommendedPrices.noData")}
        </div>
    )
};

export default NoData;
