import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Select, Typography} from "antd";

import NoContent from "components/_common/NoContent";
import {filterBasisSelect} from "lib/helpers";
import {FilterBasis} from "lib/graphql/queries/WholesaleStats/types";

const {Option} = Select;
const {Title, Paragraph} = Typography;

interface SellerFilterProps {
    loading: boolean;
    showSearch: boolean;
    basis: string;
    traders: Array<string>
    filterSeller: Array<FilterBasis>;
    setTraders: (value: (((prevState: Array<string>) => Array<string>) | Array<string>)) => void
}

const SellerFilter: React.FC<SellerFilterProps> = ({
    loading,
    showSearch,
    basis,
    traders,
    filterSeller,
    setTraders
}) => {
    const {t} = useTranslation();
    const noContent = (
        <NoContent description={<div>{t("analytics.chart.noInfo")}</div>} />
    );

    useEffect(() => {
        setTraders([]);
    }, [basis]);

    return (
        <div className="gas-station-filter">
            <Title level={5}>
                {t("analytics.chart.wholesaleFilter.seller")}
            </Title>

            <Select
                mode="multiple"
                loading={loading}
                disabled={!basis.length}
                value={traders}
                placeholder={t("analytics.chart.wholesaleFilter.sellerPlaceholder")}
                style={{width: "100%"}}
                onChange={(val) => {setTraders(val)}}
                notFoundContent={noContent}
                showSearch={showSearch}
                filterOption={filterBasisSelect}
            >
                {filterSeller.map((item) =>
                    <Option
                        value={item.value}
                        key={item.value}
                    >
                        {item.label}
                    </Option>
                )}
            </Select>

            <Paragraph
                className="gas-station-filter__desc"
                type="secondary"
            >
                {t("analytics.chart.wholesaleFilter.sellerPlaceholder")}
            </Paragraph>
        </div>
    )
};

export default SellerFilter;
