import {
    GasStationsItem,
    RegionSelect,
    UserProfileGasStations,
} from "../../lib/graphql/queries/GasStations/gasStations";
import React, { useEffect, useState } from "react";
import { YMaps, withYMaps } from "react-yandex-maps";

import GAS_STATIONS_LIST from "lib/graphql/queries/GasStations";
import { GasStationPagination } from "../../lib/graphql/globalTypes";
import GasStationYandexMapComponent from "components/GasStationsYandexMapComponent";
import GasStationYandexMapRegionPicker from "../../components/GasStationsYandexMapComponent/GasStationYandexMapRegionPicker";
import Loading from "../../components/_common/Loading";
import { useQuery } from "@apollo/react-hooks";

const GasStationsYandexMapContainer: React.FC = () => {
    const [points, setPoints] = useState<Array<GasStationsItem>>([]);
    const [regions, setRegions] = useState<Array<RegionSelect>>([]);
    const [pickedRegion, setPickedRegion] = useState<Array<string>>([]);
    const [actualPoints, setActualPoints] = useState<Array<GasStationsItem>>([]);
    const { data, loading } = useQuery<
    UserProfileGasStations,
    GasStationPagination
  >(GAS_STATIONS_LIST);

    useEffect(() => {
        if (data) {
            const items = data.userProfile.customer.gasStations.items;
            setPoints(items);

            const tempRegions = items.reduce((acc, station) => {
                const { id } = station.region;

                if (acc[id]) {
                    acc[id].count += 1;
                } else {
                    acc[id] = { ...station.region, count: 1 };
                }

                return acc;
            }, {} as { [key: string]: RegionSelect });

            setRegions(Object.values(tempRegions));
        }
    }, [data]);

    useEffect(() => {
        const filtered = pickedRegion.length
            ? points.filter(
                (item) => item.region && pickedRegion.includes(item.region.id)
            )
            : points;
        setActualPoints(filtered);
    }, [pickedRegion, points]);

    const FilterByRegion = (
        <GasStationYandexMapRegionPicker
            regions={regions}
            pickedRegion={pickedRegion}
            setPickedRegion={setPickedRegion}
        />
    );

    if (loading) {
        return <Loading />;
    }

    const MapComponentWithYMaps = withYMaps(GasStationYandexMapComponent, true, [
        "geoObject.addon.balloon",
        "templateLayoutFactory",
    ]);

    return (
        <>
            <YMaps>
                <MapComponentWithYMaps
                    points={actualPoints}
                    FilterByRegion={FilterByRegion}
                />
            </YMaps>
        </>
    );
};

export default GasStationsYandexMapContainer;
