import React, { Fragment, useState } from "react";

import { ACTUAL_PRICE_HISTORY_PAGINATION } from "../../../lib/helpers/actualPriceHistory";
import ActualPricePriceHistoryModal from "../ActualPricePriceHistoryModal";
import { Button } from "antd";
import NEW_STATISTIC_REPORT from "../../../lib/graphql/queries/StatisticReport/newStatic";
import { useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

const ActualPriceReports: React.FC = () => {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { data, loading: reportLoading } = useQuery(NEW_STATISTIC_REPORT, {
        variables: {
            pagination: {
                limit: ACTUAL_PRICE_HISTORY_PAGINATION,
                offset: 0,
            },
        },
    });

    const handleModal = () => {
        setIsModalVisible(true);
    };

    return (
        <Fragment>
            <Button onClick={handleModal}>
                {t("reports.actualPrice.priceHistory.reports")}
            </Button>
            <ActualPricePriceHistoryModal
                loading={reportLoading}
                data={data}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />
        </Fragment>
    );
};

export default ActualPriceReports;
