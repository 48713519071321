import React from "react"
import { Spin, Space } from "antd";

import "./style.scss";

const Loading: React.FC = () => {
    return (
        <div className="app-loading">
            <img 
                className="app-loading__logo" 
                src={require('../../../assets/logo_auth3.png')}
            />
            <Space className="app-loading__spin" size="middle">
                <Spin size="large" />
            </Space>
        </div>
    );
}

export default Loading;