import React, {Fragment, ReactNode, useState} from "react";
import { Row } from "antd";
import { useTranslation } from "react-i18next";

import PriceStatisticChart from "components/PriceStatisticComponent/PriceStatisticChart";
import PageMainHeader from "components/_common/PageMainHeader";
import CardWrapper from "components/_common/CardWrapper";
import { LineChartData } from "lib/graphql/queries/PriceStatistic/types";
import { ChartTranslateStrings } from "../PriceStatisticComponent/helper";

interface PriceStatisticBasisProps {
    loading: boolean;
    basis: string;
    products: Array<string>;
    traders: Array<string>;
    data: Array<LineChartData>;
    Filters: ReactNode;
}

export interface ScrollBarValue {
    start: number | null;
    end: number | null;
}

const PriceStatisticBasisComponent: React.FC<PriceStatisticBasisProps> = ({
    Filters,
    loading,
    data,
    basis,
    products,
    traders,
}) => {
    const { t } = useTranslation();
    const title = t("analytics.title");
    const translate: ChartTranslateStrings = {
        firstInput: "analytics.chart.wholesaleFilter.myWholesalePlaceholder",
        secondInput: "analytics.chart.wholesaleFilter.sellerPlaceholder",
        thirdInput: "analytics.chart.wholesaleFilter.fuelTypesPlaceholder",
    };

    const [scrollBarValue, setScrollBarValue] = useState<ScrollBarValue>({
        start: null,
        end: null,
    } as ScrollBarValue);


    return (
        <Fragment>
            <PageMainHeader title={title} />

            <CardWrapper title={t("analytics.wrapperTitle")}>
                <Row className="analytics__row">
                    {Filters}

                    <PriceStatisticChart
                        data={data}
                        loading={loading}
                        ownerStation={basis} // это первый инпут - НБ basis
                        fuelTypes={products} // третий инпут - Номенклатуры products
                        translateStr={translate}
                        secondStations={traders}
                        scrollBarValue={scrollBarValue}
                        // @ts-ignore
                        setScrollBarValue={setScrollBarValue}
                    />
                </Row>
            </CardWrapper>
        </Fragment>
    );
};

export default PriceStatisticBasisComponent;
