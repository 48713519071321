import React from "react";
import {
    Route,
    Switch,
    BrowserRouter as Router,
    Redirect,
} from "react-router-dom";

import SecuredRoute from "./SecuredRoute";
import TokenStorage from "lib/TokenStorage";
import MainLayout from "layouts/MainLayout";
import AuthLayout from "layouts/AuthLayout";
import LoginPage from "pages/LoginPage";
import ActualPricePage from "pages/ActualPricePage";
import SmallWholesalePage from "pages/SmallWholesalePage";
import PriceStatisticPage from "pages/PriceStatisticPage";
import PersonalGasStationsPage from "pages/PersonalGasStationsPage";
import UserSettingsPage from "pages/UserSettingsPage";
import PriceStatisticBasisPage from "pages/PriceStatisticBasisPage";
import RecommendedPricesPage from "pages/RecommendedPricesPage/RecommendedPricesPage";
import GasStationYandexMapPage from "pages/GasStationYandexMapPage";

const Routers: React.FC = () => {
    return (
        <Router>
            <Switch>
                <Route path="/user/:path">
                    <AuthLayout>
                        <Switch>
                            <Route
                                path="/user/login"
                                render={() => {
                                    const { accessToken } = TokenStorage;

                                    if (accessToken) {
                                        return <Redirect to="/" />;
                                    }

                                    return <LoginPage />;
                                }}
                            />
                        </Switch>
                    </AuthLayout>
                </Route>

                <SecuredRoute Layout={MainLayout}>
                    <Switch>
                        <Route path="/" exact component={ActualPricePage} />
                        <Route path="/actual-prices" exact component={ActualPricePage} />
                        <Route
                            path="/recommended-prices"
                            exact
                            component={RecommendedPricesPage}
                        />
                        <Route path="/wholesale" exact component={SmallWholesalePage} />
                        <Route path="/analytics" exact component={PriceStatisticPage} />
                        <Route
                            path="/wholesale-analytics"
                            exact
                            component={PriceStatisticBasisPage}
                        />
                        <Route
                            path="/my-stations"
                            exact
                            component={PersonalGasStationsPage}
                        />
                        <Route path="/user-settings" exact component={UserSettingsPage} />
                        <Route path="/map" exact component={GasStationYandexMapPage} />
                    </Switch>
                </SecuredRoute>
            </Switch>
        </Router>
    );
};

export default Routers;
