import "./styles.scss";

import React, { useEffect, useState } from "react";

import { Button } from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { LoadingOutlined } from "@ant-design/icons";
import ReactGA from "react-ga";
import Spin from "antd/es/spin";
import TokenStorage from "lib/TokenStorage";

interface Props {
  id: string;
}

const PriceHistoryDownloadButton: React.FC<Props> = ({ id }) => {
    const url = `/download/statistic-report/${id}`;

    const [file, setFile] = useState<Blob>();
    const [fileName, setFileName] = useState("price-stats");
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);

    useEffect(() => {
        if (!loading && file) {
            const url = window.URL.createObjectURL(file);
            const a = document.createElement("a");

            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);

            a.click();
            a.remove();

            setButtonLoading(false);
            setFile(undefined);
        }
    }, [loading, file]);

    const handleClick = () => {
        const token = TokenStorage.getAccessToken();

        setLoading(true);
        setButtonLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 1000);

        const options = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${String(token)}`,
                "Content-Type": "application/octet-stream",
            },
        };

        fetch(url, options)
            .then((res) => {
                const responseFileName = res.headers
                    .get("content-disposition")
                    ?.split("filename=")[1]
                    ?.split(";")[0]
                    .replaceAll('"', "");

                responseFileName && setFileName(responseFileName);
                return res.blob();
            })
            .then(
                (blob) =>
                    new Blob([blob], {
                        type: "application/octet-stream;charset=utf-8",
                    })
            )
            .then((blobFile) => {
                setFile(blobFile);
                ReactGA.event({
                    category: "Price History report",
                    action: "Download CSV-report",
                });
            })
            .catch((err) => {
                setButtonLoading(false);
                setLoading(false);

                throw new Error(err);
            });
    };
    const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

    return (
        <Button
            className="download-button"
            onClick={handleClick}
            type="link"
            disabled={buttonLoading}
            color="#1890FF"
        >
            {loading ? <Spin indicator={antIcon} /> : <CloudDownloadOutlined />}
        </Button>
    );
};

export default PriceHistoryDownloadButton;
