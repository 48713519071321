import React from "react";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { LineChartOutlined } from "@ant-design/icons/lib";

const DynamicGasStationTooltip: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Tooltip
            color="#262626"
            placement="topLeft"
            arrowPointAtCenter
            title={t("reports.actualPrice.tooltip.dynamic")}
        >
            <LineChartOutlined style={{ color: "#1890FF" }} />
        </Tooltip>
    );
};

export default DynamicGasStationTooltip;
