import React, {useEffect, useMemo, useRef, useState} from "react";
import debounce from "lodash.debounce";
import {useLazyQuery} from '@apollo/react-hooks';

import PriceStatisticPage from "lib/graphql/queries/PriceStatistic";
import {AnalyticsData, LineChartData, StatisticInput} from "lib/graphql/queries/PriceStatistic/types";
import AnalyticsComponent from "components/PriceStatisticComponent";
import AnalyticsFilters from "components/PriceStatisticComponent/AnalyticsFilters";

const AnalyticsContainer: React.FC = () => {
    const [ownerStation, setOwnerStation] = useState<string>('');
    const [fuelTypes, setFuelTypes] = useState<Array<string>>([]);
    const [gasStations, setGasStations] = useState<Array<string>>([]);
    const [analytics, setAnalytics] = useState<Array<LineChartData>>([]);

    const [getStatistic, {data, loading}] = useLazyQuery<AnalyticsData, StatisticInput>(PriceStatisticPage);

    const getStatisticRef = useRef(debounce((gasStationIds, productCode) => {
        getStatistic({
            variables: {
                input: {
                    gasStationId: gasStationIds,
                    productCode: productCode,
                }
            }
        })
    }, 200)).current;

    useEffect(() => {
        if (ownerStation.length && fuelTypes.length) {
            getStatisticRef([ownerStation, ...gasStations], fuelTypes);
        }

    }, [ownerStation, gasStations, fuelTypes]);

    useEffect(() => {
        if (data) {
            const lines: Array<LineChartData> = [];

            data.statistic.forEach((statistic) => {
                lines.push({
                    id: `${statistic.product.name} ${statistic.gasStation.name}`,
                    data: statistic.data
                })
            });

            setAnalytics(lines);
        }
    }, [data]);

    const filters = useMemo(() => {
        return <AnalyticsFilters
            fuelTypes={fuelTypes}
            gasStations={gasStations}
            ownerStation={ownerStation}
            setFuelTypes={setFuelTypes}
            setGasStations={setGasStations}
            setOwnerStation={setOwnerStation}
        />

    }, [fuelTypes, gasStations, ownerStation]);

    return (
        <AnalyticsComponent
            loading={loading}
            fuelTypes={fuelTypes}
            ownerStation={ownerStation}
            analytics={analytics}
            Filters={filters}
        />
    )
};

export default AnalyticsContainer;
