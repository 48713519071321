import jwt_decode from "jwt-decode";
import {JwtToken, TokenType} from './types';

export const TOKEN_EXP_TIMEOUT = 10000;

export const jwtExp = (token?: string | null): number | null => {
    if (!(typeof token === 'string')) {
        return null;
    }

    try {
        const jwt: JwtToken = jwt_decode(token);
        if (jwt && jwt.exp && Number.isFinite(jwt.exp)) {
            return jwt.exp * 1000;
        }

        return null;
    } catch (e) {
        return null;
    }
};

export const isExpired = (exp?: number): boolean => {
    if (!exp) {
        return false;
    }

    return Date.now() > exp;
};

export const defaultToken: TokenType = {
    accessToken: null,
};

export const copyDefaultToken = (): TokenType => {
    return { ...defaultToken}
};
