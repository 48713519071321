import React from "react";
import * as Yup from 'yup';
import {LoginFormValues} from "lib/types";
import {CreateTokenInput} from "lib/graphql/globalTypes";
import {Checkbox, Col, Row, Typography} from "antd";
import {useTranslation} from "react-i18next";

import LoginForm from "./LoginForm";
import AuthLogo from "./AuthLogo";

import "./style.scss";

const {Title, Paragraph} = Typography;

// TODO: Поправить any
interface LoginComponentProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleSubmit: (params: CreateTokenInput, status: any) => void;
    validationSchema: Yup.ObjectSchema;
    initialValues: LoginFormValues;
    loading: boolean;
    setRememberMe: (value: (((prevState: boolean) => boolean) | boolean)) => void
}

const LoginComponent: React.FC<LoginComponentProps> = ({
    handleSubmit,
    loading,
    initialValues,
    validationSchema,
    setRememberMe,
}) => {
    const {t} = useTranslation();
    return (
        <Row className="login__form-row">
            <Col span={24}>
                <div className="text-align-center">
                    <AuthLogo/>
                </div>

                <Title className="login__form-title text-align-center" level={2}>
                    {t("login.pleaseAuth")}
                </Title>

                <LoginForm
                    handleSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    loading={loading}
                    initialValues={initialValues}
                />

                <div>
                    <Checkbox
                        defaultChecked={true}
                        onChange={(event) => setRememberMe(event.target.checked)}
                    >
                        {t("login.rememberMeText")}
                    </Checkbox>

                    <a
                        style={{float: 'right'}}
                        className="login-form-forgot"
                        href="#"
                    >
                        {t("login.forgotPasswordText")}
                    </a>
                </div>

                <Paragraph className="login__form-forgot text-align-center">
                    {t("login.didNotHaveAccount")} {' '}
                    <a href="#">{t("login.registration")} </a>
                </Paragraph>
            </Col>
        </Row>
    )
};

export default LoginComponent;
