import React from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {Button, Form, Select, Slider, Upload} from "antd";

import {FilterFormInput} from "../types";
import SizeOfSalesButton from "./SizeOfSalesButton";
import FilterFormItemLabel from "./FilterFormItemLabel";
import {strategyArray} from "lib/helpers/recommended";

import "./style.scss";

const {Option} = Select;

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleSubmit: (params: FilterFormInput, status: any) => void;
    validationSchema: Yup.ObjectSchema;
    initialValues: FilterFormInput;
    loading: boolean;
}

const FilterForm: React.FC<Props> = ({
    handleSubmit,
    loading,
    initialValues,
    validationSchema
}) => {
    const {t} = useTranslation();

    const {
        handleSubmit: formikHandleSubmit,
        setSubmitting,
        // status,
        touched,
        errors,
        values,
        // handleChange,
        isSubmitting,
        setFieldValue
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleSubmit,
    });

    const clearSizeOfSales = () => void setFieldValue('sizeOfSales', undefined);
    const clearLevelOfMarginality = () => void setFieldValue('levelOfMarginality', undefined);

    return(
        <Form
            className="filter-form"
            size="large"
            layout="inline"
            colon={false}
            onFinish={() => {
                formikHandleSubmit();
                setSubmitting(false);
            }}
        >

            <Form.Item
                name="sizeOfSales"
                className="filter-form__size-of-sales"
                labelAlign="left"
                validateStatus={
                    touched.sizeOfSales && errors.sizeOfSales ? 'error' : undefined
                }
                label={
                    <FilterFormItemLabel
                        title={t("recommendedPrices.form.sizeOfSales")}
                        tooltipText={t("recommendedPrices.form.sizeOfSalesTooltip")}
                    />
                }
                required
            >
                <Upload
                    id="size-of-sales"
                    name="sizeOfSales"
                    beforeUpload={() => false}
                    accept=".csv"
                    onChange={(e) => {
                        void setFieldValue('sizeOfSales', e.file);
                    }}
                    itemRender={() => null}
                >

                    <SizeOfSalesButton
                        file={values.sizeOfSales}
                        clearFile={clearSizeOfSales}
                    />
                </Upload>
            </Form.Item>

            <Form.Item
                name="level-of-marginality"
                className="filter-form__level-of-marginality"
                labelAlign="left"
                label={
                    <FilterFormItemLabel
                        title={t("recommendedPrices.form.levelOfMarginality")}
                        tooltipText={t("recommendedPrices.form.levelOfMarginalityTooltip")}
                    />
                }
            >
                <Upload
                    id="size-of-sales"
                    name="levelOfMarginality"
                    beforeUpload={() => false}
                    accept=".csv"
                    onChange={(e) => {
                        void setFieldValue('levelOfMarginality', e.file)
                    }}
                    itemRender={() => null}
                >
                    <SizeOfSalesButton
                        file={values.levelOfMarginality}
                        clearFile={clearLevelOfMarginality}
                    />
                </Upload>
            </Form.Item>

            <Form.Item
                name="strategy"
                labelAlign="left"
                className="filter-form__strategy"
                validateStatus={
                    touched.strategy && errors.strategy ? 'error' : undefined
                }
                label={
                    <FilterFormItemLabel
                        title={t("recommendedPrices.form.strategy")}
                        tooltipText={t("recommendedPrices.form.strategyTooltip")}
                    />
                }
                required
            >
                <Select
                    id="strategy"
                    onChange={(value: string) => void setFieldValue("strategy", value)}
                >
                    {strategyArray.map((strat) => (
                        <Option key={strat} value={strat.toUpperCase()}>
                            {t(`recommendedPrices.form.strategyList.${strat}`)}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                name="priority"
                className="filter-form__priority"
                validateStatus={
                    touched.priority && errors.priority ? 'error' : undefined
                }
                label={
                    <FilterFormItemLabel
                        title={t("recommendedPrices.form.priority")}
                        tooltipText={t("recommendedPrices.form.priorityTooltip")}
                    />
                }
            >
                <Slider
                    min={0}
                    max={1}
                    step={0.1}
                    id="priority"
                    style={{width: 100}}
                    tooltipPlacement="bottom"
                    onChange={(value: number) => void setFieldValue("priority", value)}
                />
            </Form.Item>

            <Form.Item
                name="rounding"
                labelAlign="left"
                className="filter-form__rounding"
                label={t("recommendedPrices.form.rounding")}
            >
                <Select
                    id="rounding"
                    mode="tags"
                    style={{ width: '100%'}}
                    onChange={(val: number[]) => void setFieldValue("rounding", val)}
                    allowClear
                >
                    {Array.from(Array(10).keys()).map(
                        (item) => <Option key={item} value={item}>{item}</Option>)
                    }
                </Select>
            </Form.Item>

            <Form.Item className="filter-form__submit">
                <Button
                    size="large"
                    className="filter-form_form-submit"
                    type="primary"
                    htmlType="submit"
                    disabled={isSubmitting || (!values.sizeOfSales || !values.strategy)}
                    loading={loading}
                    block
                >
                    {t("recommendedPrices.form.submit")}
                </Button>
            </Form.Item>

        </Form>
    )
};

export default FilterForm;
