import React from "react";

import ChangeLang from "components/_common/ChangeLang";
import AvatarDropdown from "components/_common/AvatarDropdown";

interface MainHeaderProps {
    collapsed: boolean;
}

const MainHeader: React.FC<MainHeaderProps> = ({collapsed}) => {
    const classRight = collapsed ? "right" : "right-no-collapsed";
    return (
        <div className={classRight}>
            <AvatarDropdown/>
            <ChangeLang />
        </div>
    )
};

export default MainHeader;
