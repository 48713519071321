import {notification} from 'antd';
import ReactGA from "react-ga";
import client from "lib/graphql/client";

import {TOKEN_EXP_TIMEOUT} from './helpers';
import TokenStorage from './tokenStorage'
import {Logout} from "lib/graphql/mutations/Logout/types";
import LOGOUT from "lib/graphql/mutations/Logout";

const onTokenExpiredCallback = (timer?: number) => {
    if (timer) {
        clearTimeout(timer);

        ReactGA.set({
            userId: null,
        });

        try {
            void client.mutate<Logout>({
                mutation: LOGOUT
            }).then(() => {
                void client.clearStore().then(
                    () => {
                        notification.info({message: 'Ваша сессия подошла к концу'});
                        document.location.pathname = '/user/login';
                    });
            })

        } catch (e) {
            throw new Error(e);
        }

    }
};

export const createTokenService = (): TokenStorage => {
    window.tokenStorage = window.tokenStorage || new TokenStorage({
        storage: localStorage,
        tokenExpTimeout: TOKEN_EXP_TIMEOUT,
        onTokenExpiredCallback
    });

    return window.tokenStorage
};

export default createTokenService()
