import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import dayjs from "dayjs";
import ReactGA from "react-ga";
import utc from "dayjs/plugin/utc";
import { ApolloProvider } from "react-apollo";

import { isEnvForDebug } from "lib/helpers";
import client from "lib/graphql/client";
import Routers from "components/Router";
import Loading from "components/_common/Loading";

import "./lib/i18n";
import "./index.scss";

dayjs.extend(utc);

const trackingId = "UA-188383870-2";
ReactGA.initialize(trackingId, { debug: isEnvForDebug() });

ReactDOM.render(
    <ApolloProvider client={client}>
        <Suspense fallback={<Loading />}>
            <Routers />
        </Suspense>
    </ApolloProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
