import React, {useEffect, useState} from "react";
import {Col} from "antd";
import {useQuery} from "@apollo/react-hooks";

import SellerFilter from "./SellerFilter";
import TankFarmFilter from "./TankFarmFilter";
import NomenclatureFilter from "./NomenclatureFilter";
import WHOLESALE_STATS_BASISES from "lib/graphql/queries/WholesaleStats/WholesaleStatsBasises";
import {Product} from "lib/graphql/queries/CustomerStations/__generated__/customerStations";
import {FilterBasis, WholesaleStatsBasis, WholesaleStatsBasisInput} from "lib/graphql/queries/WholesaleStats/types";

interface BasisStatsFilters {
    loading: boolean;
    basis: string;
    traders: Array<string>;
    products: Array<string>;
    setBasis: (value: (((prevState: string) => string) | string)) => void;
    setTraders: (value: (((prevState: Array<string>) => Array<string>) | Array<string>)) => void;
    setProducts: (value: (((prevState: Array<string>) => Array<string>) | Array<string>)) => void;
}

const BasisStatsFilters: React.FC<BasisStatsFilters> = ({
    loading,
    basis,
    traders,
    products,
    setBasis,
    setTraders,
    setProducts
}) => {
    const [filterBasis, setFilterBasis] = useState<Array<FilterBasis>>([]);
    const [filterSeller, setFilterSeller] = useState<Array<FilterBasis>>([]);
    const [filterNomenclature, setFilterNomenclature] = useState<{[key: string]: Array<Product>}>({});
    const [showSearch, setShowSearch] = useState(true);

    const {
        data: dataBasis,
        loading: basisLoading
    } = useQuery<WholesaleStatsBasis, WholesaleStatsBasisInput>(WHOLESALE_STATS_BASISES);

    useEffect(() => {
        const deviceWidth = window.innerWidth;

        if (deviceWidth <= 968) {
            setShowSearch(false);
        }
    }, []);

    useEffect(() => {
        if (dataBasis) {
            setFilterBasis(dataBasis.userProfile.customer.basises.items.map((item) => (
                {
                    value: item.id,
                    label: item.name
                }
            )));
        }
    }, [dataBasis]);

    useEffect(() => {
        if (!dataBasis || !filterBasis.length) {
            return;
        }

        const assortmentDictionary: {[key: string]: FilterBasis} = {};
        const tempProducts: {[key: string]: Array<Product>} = {};
        const {items: basisItems} = dataBasis.userProfile.customer.basises;

        const current = basisItems.find((item) => item.id === basis);

        // формируем список traders && products
        current && current.assortment.forEach((item) => {
            const traderID = item.trader.id;

            assortmentDictionary[traderID] = {
                value: traderID,
                label: item.trader.name
            };

            if (Array.isArray(tempProducts[traderID])) {
                tempProducts[traderID] = [...tempProducts[traderID], item.product]
            } else {
                tempProducts[traderID]= [item.product];
            }
        });

        setFilterSeller(Object.values(assortmentDictionary));
        setFilterNomenclature(tempProducts);
        setProducts([]);

    }, [basis]);

    return (
        <Col
            className="analytics__filters"
            xl={7}
            lg={7}
            xs={24}
            md={24}
        >
            <TankFarmFilter
                data={filterBasis}
                loading={loading}
                showSearch={showSearch}
                setBasis={setBasis}
            />
            <SellerFilter
                loading={basisLoading}
                basis={basis}
                traders={traders}
                showSearch={showSearch}
                filterSeller={filterSeller}
                setTraders={setTraders}
            />
            <NomenclatureFilter
                loading={basisLoading}
                products={products}
                traders={traders}
                filterNomenclature={filterNomenclature}
                setProducts={setProducts}
            />
        </Col>
    )
};

export default BasisStatsFilters
