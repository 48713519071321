import React, { ReactNode, useEffect, useState } from "react";
import { Badge, Modal, Table } from "antd";
import { ColumnGroupType, ColumnType } from "antd/lib/table";

import ExpandedTable from "./ExpandedTable";
import { getGasStationTitle } from "lib/helpers";
import { ActualPriceTableRow } from "components/ActualPriceComponent/types";

import "./style.scss";

interface Props {
  loading: boolean;
  zeroHeader: string;
  data: Array<ActualPriceTableRow>;
  tableHeaders: Array<string>;
  setOnFilters: (value: ((prevState: string) => string) | string) => void;
  columns: (
    | ColumnGroupType<ActualPriceTableRow>
    | ColumnType<ActualPriceTableRow>
  )[];
}

const ActualPriceNestedTable: React.FC<Props> = ({
    loading,
    data,
    columns,
    tableHeaders,
}) => {
    const [tableData, setTableData] = useState<ActualPriceTableRow[]>(
        data.slice()
    );
    const [expandedRowKeys, setExpandedRowKeys] = useState<Array<string>>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalView, setModalView] = useState<ReactNode>(null);
    const [modalTitle, setModalTitle] = useState("");

    useEffect(() => {
        setTableData(data.slice());
    }, [data]);

    useEffect(() => {
        if (!isModalVisible) {
            setExpandedRowKeys([]);
        }
    }, [isModalVisible]);

    const scrollCoordinates = () => {
        const deviceWidth = window.innerWidth;

        if (deviceWidth <= 500) {
            return {
                x: 1300,
                y: 500,
            };
        }

        return {
            y: "calc(100vh - 150px)",
        };
    };

    const onModalClose = () => {
        setIsModalVisible(false);
    };

    const badge = <Badge className="indicator-badge" color="#1890FF" />;

    return (
        <>
            <Table
                scroll={scrollCoordinates()}
                columns={columns}
                dataSource={tableData}
                showSorterTooltip={false}
                pagination={false}
                loading={loading}
                expandable={{
                    expandedRowRender: () => undefined,
                    expandedRowKeys,
                    expandRowByClick: true,
                    expandIcon: ({ record }) => {
                        return record.hasIndicator ? badge : null;
                    },
                    onExpand: (expanded: boolean, record: ActualPriceTableRow) => {
                        setModalTitle(`
                            ${getGasStationTitle(
            record.brand,
            record.internalNumber
        )} ${record.address}
                        `);

                        setModalView(
                            <ExpandedTable
                                tableHeaders={tableHeaders}
                                expanded={expanded}
                                gasStationId={record.key}
                            />
                        );

                        setIsModalVisible(true);

                        expandedRowKeys.includes(record.key)
                            ? setExpandedRowKeys(
                                [...expandedRowKeys].filter((item) => item !== record.key)
                            )
                            : setExpandedRowKeys([...expandedRowKeys, record.key]);
                    },
                }}
                sticky
            />

            <Modal
                title={modalTitle}
                width={1400}
                visible={isModalVisible}
                footer={null}
                maskClosable
                onCancel={onModalClose}
                centered
            >
                {modalView}
            </Modal>
        </>
    );
};

export default ActualPriceNestedTable;
