import React from "react";
import { Select, Typography } from "antd";
import { useTranslation } from "react-i18next";

import "./style.scss";

const { Option } = Select;
const { Text } = Typography;

const sizes = [10, 20, 30, 40, 50];

interface PaginationSizeProps {
    setDefaultPageSize: (
        value: ((prevState: number) => number) | number | number
    ) => void;
    setCurrentPage: (value: ((prevState: number) => number) | number) => void;
    setListOffset: (value: ((prevState: number) => number) | number) => void;
}

const PaginationSize: React.FC<PaginationSizeProps> = ({
    setDefaultPageSize,
    setCurrentPage,
    setListOffset,
}) => {
    const { t } = useTranslation();
    const handleChange = (item: number) => {
        setCurrentPage(1);
        setListOffset(0);
        setDefaultPageSize(item);
    };

    return (
        <div className="gas-station-list-pagination">
            <Text className="gas-station-list-pagination__text">
                {t(`reports.actualPrice.extras.perPage`)}
            </Text>
            <Select
                defaultValue={sizes[0]}
                className="pagination-default-size"
                onChange={handleChange}
            >
                {sizes.map((item) => {
                    return (
                        <Option key={item} value={item}>
                            {item}
                        </Option>
                    );
                })}
            </Select>
        </div>
    );
};

export default PaginationSize;
