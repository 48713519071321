import React,{Fragment} from "react";
import LoginContainer from "containers/LoginContainer";
import {useTranslation} from "react-i18next";

import {Helmet} from "react-helmet";

const LoginPage: React.FC = () => {
    const {t} = useTranslation();

    return (
        <Fragment>
            <Helmet>
                <title>{`${t(`title.pages.login`)} - BenzUp`}</title>
            </Helmet>
            <LoginContainer />
        </Fragment>
    )
};

export default LoginPage;
