import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {useQuery, useMutation} from "@apollo/react-hooks";

import {actualPrices} from "../ActualPriceContainer/dataMapping";
import {actualPriceHeaders} from "lib/helpers/fuelTypes";
import {GasStationPagination} from "lib/graphql/globalTypes";
import {useGasStationColumns} from "lib/hooks/useGasStationColumns";
import RECOMMENDED_PRICES from "lib/graphql/queries/RecommendedPrices";
import UPDATE_PRICING_PRESETS from "lib/graphql/mutations/UpdatePricingPresets";
import {UpdatePresetsInput, UpdatePresetsMutation} from "lib/types/recommended-prices";
import {PricingState, UserProfileGasStations} from "lib/graphql/queries/GasStations/gasStations";
import {FilterFormInput} from "components/RecommendedPricesComponent/types";
import RecommendedPriceCell from "components/_common/ActualPriceTable/ActualPriceCell/RecommendedPriceCell";
import RecommendedPricesComponent from "components/RecommendedPricesComponent";
import {ActualPriceTableRow, ProductData} from "components/ActualPriceComponent/types";

const initFormData: FilterFormInput = {
    sizeOfSales: undefined,
    levelOfMarginality: undefined,
    strategy: undefined,
    priority: 0,
    rounding: [],
};

const RecommendedPricesContainer: React.FC = () => {
    const [recommendedData, setRecommendedData] = useState<Array<ActualPriceTableRow>>([]);
    const [pricingEnabled, setPricingEnabled] = useState(false);
    const [presetStatus, setPresetStatus] = useState<PricingState>(PricingState.EMPTY);
    const [initialValues, setInitialValues]=  useState<FilterFormInput>(initFormData);

    const { data, loading } = useQuery<UserProfileGasStations, GasStationPagination>(RECOMMENDED_PRICES);
    const [sendRequest, {loading: presetLoading}] = useMutation<UpdatePresetsMutation, UpdatePresetsInput>(UPDATE_PRICING_PRESETS);

    const validationSchema: Yup.ObjectSchema = Yup.object().shape({
        sizeOfSales: Yup.mixed().required("Ошибка"),
        priority: Yup.number().required("Ошибка"),
        strategy: Yup.string().required("Ошибка"),
    });

    const handleSubmit = async (params: FilterFormInput) => {
        try {

            const  {strategy, priority, rounding, sizeOfSales, levelOfMarginality} = params;

            await sendRequest({
                variables: {
                    input: {
                        strategy,
                        priority,
                        rounding: rounding,
                        marginsFile: levelOfMarginality,
                        volumesFile: sizeOfSales
                    }
                }
            }).then((response) => {
                if (response && response.data) {
                    const {state, priority, rounding, strategy} = response.data.updatePricingPresets;
                    setInitialValues({
                        strategy,
                        priority,
                        rounding: rounding,
                        sizeOfSales: undefined,
                        levelOfMarginality: undefined,
                    });

                    setPresetStatus(state);
                }

            });
        }
        catch (err) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            // const message = 'graphQLErrors' in err ? err.graphQLErrors[0].message : err.message;
            // const tMessage = t(`notification.${String(message)}`);
            // setStatus(tMessage);
            //TODO: нужно куда-то вывести ошибку
        }


    };

    useEffect(() => {
        if (!loading && data) {
            const {gasStations, pricingEnabled, pricingPresets} = data.userProfile.customer;
            const {priority, rounding, strategy} = pricingPresets;
            setRecommendedData(actualPrices(gasStations.items));

            setPricingEnabled(pricingEnabled);

            setPresetStatus(pricingPresets.state);

            setInitialValues({
                strategy,
                priority,
                rounding: rounding,
                sizeOfSales: undefined,
                levelOfMarginality: undefined,
            })

        }
    }, [loading]);

    const {columns} = useGasStationColumns({
        headers: ['A0100', ...actualPriceHeaders],
        translateString: "reports.actualPrice.fuel",
        onFilters: "",
        zeroHeader: "brand",
        renderFunction: (value: ProductData, header: string) => {
            if (!value) return;

            return <RecommendedPriceCell value={value} header={header} />;
        },
        sorter: false
    });

    return (
        <RecommendedPricesComponent
            loading={presetLoading}
            columns={columns}
            status={presetStatus}
            handleSubmit={handleSubmit}
            pricingEnabled={pricingEnabled}
            initialValues={initialValues}
            validationSchema={validationSchema}
            recommendedData={recommendedData}
        />
    )
};

export default RecommendedPricesContainer;
