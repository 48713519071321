import React, {useEffect, useState} from "react";
import {Select, Typography} from "antd";
import {useTranslation} from "react-i18next";

import {filterSelect} from "lib/helpers";
import {Product} from "lib/graphql/queries/CustomerStations/__generated__/customerStations";

const {Title, Paragraph} = Typography;
const {Option} = Select;

interface NomenclatureFilterProps {
    loading: boolean;
    products: Array<string>;
    traders: Array<string>;
    filterNomenclature: {[p: string]: Array<Product>};
    setProducts: (value: (((prevState: Array<string>) => Array<string>) | Array<string>)) => void
}

const NomenclatureFilter: React.FC<NomenclatureFilterProps> = ({
    loading,
    products,
    traders,
    filterNomenclature,
    setProducts
}) => {
    const {t} = useTranslation();
    const [selectData, setSelectData] = useState<Array<Product>>([]);

    useEffect(() => {
        const map = new Map();
        const result: Array<Product> = [];
        let tempData: Array<Product> = [];

        traders.forEach((trader) => {
            tempData = tempData.concat(filterNomenclature[trader]);
        });

        for (const item of tempData) {
            if (!map.has(item.id)) {
                map.set(item.id, true);

                result.push({
                    id: item.id,
                    name: item.name,
                    fullName: item.fullName,
                    code: item.code
                });
            }
        }

        setSelectData(result);

    }, [traders]);

    return (
        <div>
            <Title level={5}>
                {t("analytics.chart.wholesaleFilter.fuelTypes")}
            </Title>

            <Select
                mode="multiple"
                className="fuel-types-select"
                loading={loading}
                value={products}
                disabled={!traders.length}
                placeholder={t("analytics.chart.wholesaleFilter.fuelTypesPlaceholder")}
                onChange={setProducts}
                defaultActiveFirstOption
                filterOption={filterSelect}
                showSearch={false}
            >
                {selectData.map((item) =>
                    <Option
                        value={item.code}
                        key={item.id}
                    >
                        {t(`reports.actualPrice.analyticsFilters.${item.code}`)}
                    </Option>
                )}
            </Select>

            <Paragraph
                className="gas-station-filter__desc"
                type="secondary"
            >
                {t("analytics.chart.wholesaleFilter.fuelTypesPlaceholder")}
            </Paragraph>
        </div>
    );
};

export default NomenclatureFilter;
