import React, {Fragment} from "react";
import {Button} from "antd";
import * as Yup from "yup";
import {DownloadOutlined} from "@ant-design/icons"
import {useTranslation} from "react-i18next";
import {ColumnsType} from "antd/lib/table";

import {FilterFormInput} from "./types";
import FilterForm from "./FilterForm/FilterForm";
import PageMainHeader from "components/_common/PageMainHeader";
import CardWrapper from "components/_common/CardWrapper";
import RecommendedPriceTable from "./DataRender/RecommendedPriceTable";
import {ActualPriceTableRow} from "../ActualPriceComponent/types";
import {PricingState} from "lib/graphql/queries/GasStations/gasStations";

import "./style.scss"

interface Props {
    loading: boolean;
    pricingEnabled: boolean;
    status: PricingState;
    columns: ColumnsType<ActualPriceTableRow>;
    initialValues: FilterFormInput;
    validationSchema: Yup.ObjectSchema;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleSubmit: (params: FilterFormInput, status: any) => void;
    recommendedData: Array<ActualPriceTableRow>
}

const RecommendedPricesComponent: React.FC<Props> = ({
    loading,
    handleSubmit,
    status,
    columns,
    recommendedData,
    initialValues,
    validationSchema
}) => {
    const {t} = useTranslation();

    return (
        <Fragment>
            <PageMainHeader
                title={t("recommendedPrices.title")}
            />

            <section className="form-section" about="form">
                <CardWrapper>
                    <FilterForm
                        loading={loading}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        handleSubmit={handleSubmit}
                    />
                </CardWrapper>
            </section>

            <section className="main-table-section" about="main table">
                <CardWrapper>
                    <div className="download-report">
                        <Button icon={<DownloadOutlined />} size="large" disabled>
                            {t("recommendedPrices.download")}
                        </Button>
                    </div>

                    <div className="actual-price-table">

                        <RecommendedPriceTable
                            loading={loading}
                            columns={columns}
                            presetStatus={status}
                            recommendedData={recommendedData}
                        />

                    </div>
                </CardWrapper>
            </section>

        </Fragment>
    )
};

export default RecommendedPricesComponent;
