import React from 'react';
import PersonalGasStationsContainer from 'containers/PersonalGasStationsContainer';

const PersonalGasStationsPage: React.FC = () => {

    return (
        <PersonalGasStationsContainer />
    );
}

export default PersonalGasStationsPage;