import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Button, Checkbox, Dropdown, Menu } from "antd";
import { FilterOutlined } from "@ant-design/icons/lib";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useTranslation } from "react-i18next";

import { CustomStorage } from "lib/helpers/Storage";
import { getDefaultHeaders, getTranslateString } from "lib/helpers/fuelTypes";

interface ColumnFilterProps {
    fuelStorageName: string;
    setTableHeaders: (
        value: ((prevState: Array<string>) => Array<string>) | Array<string>
    ) => void;
}

const ColumnFilter: React.FC<ColumnFilterProps> = ({
    fuelStorageName,
    setTableHeaders,
}) => {
    const { t } = useTranslation();
    const defaultPrices = getDefaultHeaders(fuelStorageName);
    const fuelTypes = CustomStorage.getValue(fuelStorageName);
    const translateString = getTranslateString(fuelStorageName);
    const [columnMenuVisible, setColumnMenuVisible] = useState(false);
    const [filterItems, setFilterItems] = useState<Array<string>>(fuelTypes);
    const columnsFilterMenuItems: Array<ReactNode> = [];

    useEffect(() => {
        setTableHeaders(filterItems);
    }, [filterItems]);

    const checkboxHandle = useCallback(
        (item: CheckboxChangeEvent) => {
            setFilterItems((prev) => {
                const { value } = item.target;

                const newItems = defaultPrices.filter((elem) => {
                    if (prev.includes(elem) && elem === value) {
                        return false;
                    }

                    if (prev.includes(elem) && elem !== value) {
                        return true;
                    }

                    if (!prev.includes(elem) && elem === value) {
                        return true;
                    }
                });

                CustomStorage.setValue(fuelStorageName, newItems);
                return newItems;
            });
        },
        [filterItems]
    );

    defaultPrices.forEach((type) => {
        columnsFilterMenuItems.push(
            <Menu.Item key={type}>
                <Checkbox
                    onChange={checkboxHandle}
                    checked={filterItems.indexOf(type) !== -1}
                    value={type}
                >
                    {t(`${translateString}.${type}`)}
                </Checkbox>
            </Menu.Item>
        );
    });

    const FilterMenu = <Menu>{columnsFilterMenuItems}</Menu>;

    return (
        <Dropdown
            onVisibleChange={(flag) => setColumnMenuVisible(flag)}
            visible={columnMenuVisible}
            overlay={FilterMenu}
            placement="bottomRight"
            arrow
        >
            <Button
                icon={<FilterOutlined />}
                size="middle"
                className="prices-extras-buttons__filter"
            />
        </Dropdown>
    );
};

export default ColumnFilter;
