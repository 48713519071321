import gql from "graphql-tag";
import ActualPricesGasStationFragment from "lib/graphql/fragments/ActualPrices/gasStation";

const GAS_STATION = gql`
    query gasStation($id: ID!) {
        gasStation(id: $id) {
            ...ActualPricesGasStationFragment
            competitors {
                ...ActualPricesGasStationFragment
            }
        }
    }
    ${ActualPricesGasStationFragment}
`;

export default GAS_STATION;
