import React, { useEffect, useState } from "react";
import "./style.scss";
import dayjs from "dayjs";
import classNames from "classnames";
import { Card, Collapse, List, Skeleton, Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons/lib";
import { useTranslation } from "react-i18next";

import { FeatureProperties } from "./types";
import { getGasStationTitle } from "lib/helpers";

interface MapTooltipProps {
  tooltipData: FeatureProperties;
  setTooltipVisible: (
    value: ((prevState: boolean) => boolean) | boolean
  ) => void;
}

const { Text } = Typography;

const MapTooltip: React.FC<MapTooltipProps> = ({
    tooltipData,
    setTooltipVisible,
}) => {
    const { t } = useTranslation();
    const fuelPriceTitle = t("map.tooltip.fuelPricesDate");
    const [classes, setClasses] = useState<string>("map-tooltip");
    const [updatedAt, setUpdatedAt] = useState("");

    const classesObj = {
        "map-tooltip": true,
        "map-tooltip__open": !!tooltipData,
    };
    const closeButton = (
        <CloseOutlined
            className="map-tooltip__close"
            onClick={() => {
                setClasses(
                    classNames({
                        ...classesObj,
                        "map-tooltip__hidden": true,
                    })
                );
                setTimeout(() => {
                    setTooltipVisible(false);
                }, 400);
            }}
        />
    );
    const customPanelStyle = {
        background: "white",
        border: "none",
    };

    useEffect(() => {
        setClasses(
            classNames({
                ...classesObj,
                "map-tooltip__hidden": !tooltipData,
            })
        );

        if (tooltipData.prices.length) {
            let maxDate = tooltipData.prices[0].updatedAt;

            tooltipData.prices.forEach((item) => {
                const formattedDate = dayjs(maxDate).isAfter(item.updatedAt);

                if (formattedDate) {
                    maxDate = item.updatedAt;
                }
            });

            setUpdatedAt(maxDate);
        }
    }, [tooltipData]);

    const updatedAtTitle = tooltipData.prices.length
        ? `${fuelPriceTitle} ${dayjs(updatedAt)
            .utc(false)
            .format("DD.MM.YYYY hh:mm")}`
        : "";

    const competitorsList =
    tooltipData.competitors && tooltipData.competitors.length ? (
        <Collapse>
            {tooltipData.competitors.map((competitor, index) => {
                return (
                    <Collapse.Panel
                        style={customPanelStyle}
                        key={index}
                        header={competitor.brand}
                    >
                        <div className="map-tooltip__gas-stations">
                            <List
                                itemLayout="horizontal"
                                dataSource={competitor.prices}
                                renderItem={(item, index) => {
                                    return (
                                        <List.Item
                                            key={index}
                                            className="map-tooltip__prices-item"
                                        >
                                            <List.Item.Meta
                                                className="color-list-item"
                                                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                                title={`${item.product.name}`}
                                            />
                                            <div>{item.price}</div>
                                        </List.Item>
                                    );
                                }}
                            />
                            <p className="title-secondary" style={{ marginTop: "5px" }}>
                                {updatedAtTitle}
                            </p>
                        </div>
                    </Collapse.Panel>
                );
            })}
        </Collapse>
    ) : null;

    const competitors =
    tooltipData.competitors && tooltipData.competitors.length ? (
        <div className="map-tooltip__competitors">{competitorsList}</div>
    ) : null;

    if (!tooltipData.brand) {
        return (
            <Card
                style={{ position: "absolute" }}
                className={classNames({
                    classes,
                    "map-tooltip-loading": true,
                })}
                bordered={false}
                extra={closeButton}
            >
                <Skeleton active />
            </Card>
        );
    }

    return (
        <Card
            style={{ position: "absolute" }}
            className={classes}
            title={
                <div>
                    <Text>
                        {getGasStationTitle(tooltipData.brand, tooltipData.number)}
                    </Text>
                    <br />
                    <div className="title-secondary">{tooltipData.address}</div>
                </div>
            }
            bordered={false}
            extra={closeButton}
        >
            <div className="map-tooltip__gas-stations">
                <List
                    itemLayout="horizontal"
                    dataSource={tooltipData.prices}
                    renderItem={(item, index) => (
                        <List.Item key={index} className="map-tooltip__prices-item">
                            <List.Item.Meta
                                className="color-list-item"
                                title={item.product.name}
                            />
                            <div>{item.price}</div>
                        </List.Item>
                    )}
                />

                <p className="title-secondary" style={{ marginTop: "5px" }}>
                    {updatedAtTitle}
                </p>
            </div>

            {competitors}
        </Card>
    );
};

export default MapTooltip;
