import React, {useEffect, useState} from "react";
import {Col} from "antd";
import {useQuery, useLazyQuery} from "@apollo/react-hooks";

import OwnStationFilter from "./OwnStationFilter";
import FuelTypesFilter from "./FuelTypesFilter";
import GasStationsFilter from "./GasStationsFilter";
import {AnalyticsPaginationInput} from "lib/graphql/queries/PriceStatistic/types";
import ANALYTICS_GAS_STATIONS from "lib/graphql/queries/PriceStatistic/AnalyticsGasStations";
import ANALYTICS_COMPETITORS from "lib/graphql/queries/PriceStatistic/AnalyticsCompetitors";
import {GasStationsItem, UserProfileGasStations} from "lib/graphql/queries/GasStations/gasStations";
import {GasStationProfile, GasStationVariables} from "lib/graphql/queries/GasStation/gasStation";

interface AnalyticsFiltersProps {
    ownerStation: string;
    fuelTypes: Array<string>
    gasStations: Array<string>
    setOwnerStation: (value: (((prevState: string) => string) | string)) => void
    setFuelTypes: (value: (((prevState: Array<string>) => Array<string>) | Array<string>)) => void
    setGasStations: (value: (((prevState: Array<string>) => Array<string>) | Array<string>)) => void
}

const AnalyticsFilters: React.FC<AnalyticsFiltersProps> = ({
    ownerStation,
    setOwnerStation,
    setGasStations,
    gasStations,
    setFuelTypes,
    fuelTypes
}) => {
    const [own, setOwn] = useState<Array<GasStationsItem>>([]);
    const [firstLoad, setFirstLoad] = useState(false);
    const [showSearch, setShowSearch] = useState(true);
    const [competitors, setCompetitors] = useState<Array<GasStationsItem>>([]);
    const {data, loading} = useQuery<UserProfileGasStations, AnalyticsPaginationInput>(ANALYTICS_GAS_STATIONS);

    const [
        getCompetitors,
        {
            data: competitorsData,
            loading: competitorsLoading
        }
    ] = useLazyQuery<GasStationProfile, GasStationVariables>(ANALYTICS_COMPETITORS);

    useEffect(() => {
        const deviceWidth = window.innerWidth;

        if (deviceWidth <= 968) {
            setShowSearch(false);
        }
    }, []);

    useEffect(() => {
        if (data) {
            const {items} = data.userProfile.customer.gasStations;

            setOwn(items);

            if (items.length) {
                setOwnerStation(items[0].id.toString());
                setFirstLoad(true);
            }

        }
    }, [data]);

    useEffect(() => {
        if (ownerStation.length) {
            getCompetitors({
                variables: {
                    id: ownerStation
                }
            })
        }
    }, [ownerStation]);

    useEffect(() => {
        if (competitorsData && ownerStation.length) {
            setCompetitors(competitorsData.gasStation.competitors || [])
        }

    }, [competitorsData, ownerStation]);

    useEffect(() => {
        if (firstLoad && data) {
            const {items} = data.userProfile.customer.gasStations;

            setFirstLoad(false);

            items[0].fuelPrices.length && setFuelTypes([items[0].fuelPrices[0].product.code])
        }
    }, [firstLoad]);

    return (
        <Col
            className="analytics__filters"
            xl={7}
            lg={7}
            xs={24}
            md={24}
        >
            <OwnStationFilter
                data={own}
                ownerStation={ownerStation}
                loading={loading}
                showSearch={showSearch}
                setOwnerStation={setOwnerStation}
            />
            <GasStationsFilter
                data={competitors}
                loading={competitorsLoading}
                owner={ownerStation}
                showSearch={showSearch}
                gasStations={gasStations}
                setGasStations={setGasStations}
            />
            <FuelTypesFilter
                loading={loading}
                fuelTypes={fuelTypes}
                setFuelTypes={setFuelTypes}
            />
        </Col>
    )
};

export default AnalyticsFilters;
