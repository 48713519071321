import React, { Fragment, useMemo, useState } from "react";
import { Pagination } from "antd";
import { useTranslation } from "react-i18next";

import { GasStationColumnType } from "lib/types";
import { gas, diesel, petrol } from "lib/helpers/fuelTypes";
import ActualPriceExtras from "components/ActualPriceComponent/ActualPriceExtras";
import ActualPriceSearch from "components/ActualPriceComponent/_extras/ActualPriceSearch";
import { DEFAULT_PAGINATION_SIZE } from "lib/helpers";
import PaginationSize from "components/_common/Pagination/PaginationSize";
import GasStationsTable from "components/_common/PersonalGasStations";
import CardWrapper from "components/_common/CardWrapper";
import PageMainHeader from "components/_common/PageMainHeader";

import "./style.scss";

interface Props {
  total: number;
  loading: boolean;
  defaultPageSize: number;
  setListOffset: (value: ((prevState: number) => number) | number) => void;
  setDefaultPageSize: (value: ((prevState: number) => number) | number) => void;
  tableData: Array<GasStationColumnType>;
  setSearchValue: (value: ((prevState: string) => string) | string) => void;
  currentPage: number;
  setCurrentPage: (value: ((prevState: number) => number) | number) => void;
}

const PersonalGasStationsComponent: React.FC<Props> = ({
    loading,
    total,
    tableData,
    defaultPageSize,
    setDefaultPageSize,
    setListOffset,
    setSearchValue,
    currentPage,
    setCurrentPage,
}) => {
    const { t } = useTranslation();

    // const [searchValue, setSearchValue] = useState("")
    const [tableHeaders, setTableHeaders] = useState<Array<string>>([
        ...petrol,
        ...diesel,
        ...gas,
    ]);
    const title: string = t("gasStation.list.title");

    const controlPanel = useMemo(
        () => (
            <div className="card-table__control-panel">
                <div>
                    <ActualPriceSearch setSearchValue={setSearchValue} />
                </div>
                <div>
                    <ActualPriceExtras
                        setTableHeaders={setTableHeaders}
                        fuelStorageName="selectedFuels"
                    />
                </div>
            </div>
        ),
        []
    );

    const paginationChange = (page: number) => {
        setCurrentPage(page);
        setListOffset(() => DEFAULT_PAGINATION_SIZE * (page - 1));
    };

    const pagination = useMemo(
        () => (
            <div className="gas-station-table-pagination">
                <PaginationSize
                    setDefaultPageSize={setDefaultPageSize}
                    setListOffset={setListOffset}
                    setCurrentPage={setCurrentPage}
                />
                <Pagination
                    total={total}
                    current={currentPage}
                    pageSize={defaultPageSize}
                    onChange={paginationChange}
                    defaultPageSize={defaultPageSize}
                />
            </div>
        ),
        [total, currentPage, defaultPageSize]
    );

    const table = useMemo(
        () => (
            <GasStationsTable
                loading={loading}
                headers={tableHeaders}
                tableData={tableData}
            />
        ),
        [total, tableData, loading]
    );

    return (
        <Fragment>
            <PageMainHeader title={title} />
            <CardWrapper>
                {controlPanel}

                {table}

                {pagination}
            </CardWrapper>
        </Fragment>
    );
};

export default PersonalGasStationsComponent;
