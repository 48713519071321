import {ErrorResponse, onError} from "apollo-link-error";
import {InMemoryCache, IntrospectionFragmentMatcher} from "apollo-cache-inmemory";

import {ApolloClient} from "apollo-client";
import {ApolloLink} from "apollo-link";
import TokenStorage from '../TokenStorage';
import { createUploadLink } from 'apollo-upload-client'
import i18next from "i18next";
import { notification } from 'antd';
import { setContext } from "apollo-link-context";

const fragmentMatcher = new IntrospectionFragmentMatcher ({
    introspectionQueryResultData: {
        __schema: {
            types: [
                {
                    kind: "UNION",
                    name: "statisticReport",
                    possibleTypes: [
                        {
                            name: "PricesReport"
                        },
                        {
                            name: "StatisticReport"
                        }
                    ]

                },
            ],
        },
    },
});


const cache = new InMemoryCache({fragmentMatcher});

const errorLink = onError(({ networkError }: ErrorResponse) => {

    const errorTitle = i18next.t('notification.error');

    if (networkError && 'statusCode' in networkError) {
        switch (networkError.statusCode) {
        case 401:
        case 403:
            // resetToken();
            break;
        case 500:
            notification.error({
                message: errorTitle,
                description: i18next.t('notification.networkError.500')
            });
            break;
        case 503:
            notification.error({
                message: errorTitle,
                description: i18next.t('notification.networkError.503')
            });
            break;
        default:
            notification.error({
                message: errorTitle,
                description: i18next.t('notification.networkError.other')
            });
        }
    } /* else {
           notification.error({
               message: errorTitle,
               description: i18next.t('notification.networkError.other')
           });
       } */
});

const requestLink = setContext((_, {headers, ...context}) => {
    const { accessToken } = TokenStorage;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return {
        headers: {
            ...headers,
            ...(accessToken ? {authorization: `Bearer ${accessToken}`} : {}),
        },
        ...context,
    };
});

const client = new ApolloClient({
    cache,
    link: ApolloLink.from([
        requestLink,
        errorLink,
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        createUploadLink({
            uri: process.env.REACT_APP_BENZUP_GRAPH_API_URL
        }),
        //createUploadLink({
        //    uri: 'http://82.146.35.228/api/query'
        //}),
    ])


});

export default client;