import gql from "graphql-tag";

const USER_PROFILE = gql`
    query userProfile {
        userProfile {
            id
            firstName
            lastName
            email
            authority
        }
    }
`;

export default USER_PROFILE;
