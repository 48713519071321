import React, {useState} from "react";
import { Menu } from "antd";
import { Form, Input, Button, Switch } from "antd";
import {useTranslation} from "react-i18next";

import "./style.scss";

const UserSettingsContainer: React.FC = () => {
    const {t} = useTranslation();
    const [notifications, setNotifications] = useState(false);

    const handleSubmit = () => {
        document.location.pathname = '/';
    };

    const switchChange = (checked: boolean) => {
        setNotifications(checked);
    };

    const menuRender = (
        <Menu
            className="user-settings__menu"
            style={{ width: 256 }}
            defaultSelectedKeys={['1']}
            theme={"light"}
        >
            <Menu.Item key='1'>
                {t('user.settingsMenu.basicSettings')}
            </Menu.Item>
            <Menu.Item key='2'>
                {t('user.settingsMenu.otherSettings')}
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="user-settings">
            {menuRender}
            <Form className="user-settings__form" name="userData" onFinish={handleSubmit}>
                <div className="user-settings__form-basic">
                    <p className="form-basic-title">{t('user.settingsMenu.basicSettings')}</p>
                    <div>{t('user.settingsItem.mail') + ':'}</div>
                    <Form.Item name={'mail'}>
                        <Input />
                    </Form.Item>
                    <div>{t('user.settingsItem.notification')}</div>
                    <Form.Item name={'notifications'} valuePropName="checked" initialValue={notifications}>
                        <Switch 
                            checked={notifications} 
                            onChange={switchChange}
                            checkedChildren={t('user.settingsItem.on')}
                            unCheckedChildren={t('user.settingsItem.off')}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {t('user.settingsItem.btnSubmit')}
                        </Button>
                    </Form.Item> 
                </div>
            </Form>
        </div>  
    );
};

export default UserSettingsContainer;
