import gql from "graphql-tag";

const GasStationMapItem = gql`
    fragment GasStationMapItem on GasStation {
        id
        location {
            latitude
            longitude
        }
    }
`;

export default GasStationMapItem;
