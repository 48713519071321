import React from "react";
import {Table} from "antd";
import {ColumnsType} from "antd/lib/table";
import {useVT} from 'virtualizedtableforantd4';

import NoData from "./NoData";
import DataLoading from "./DataLoading";
import Loading from "components/_common/Loading";
import {ActualPriceTableRow} from "components/ActualPriceComponent/types";
import {PricingState} from "lib/graphql/queries/GasStations/gasStations";

interface RecommendedPriceTableProps {
    loading: boolean;
    presetStatus: PricingState;
    columns: ColumnsType<ActualPriceTableRow>;
    recommendedData: Array<ActualPriceTableRow>;
}

const RecommendedPriceTable: React.FC<RecommendedPriceTableProps> = ({
    loading,
    columns,
    presetStatus,
    recommendedData
}) => {
    const [vt] = useVT(() => ({scroll: {y: 600}}), []);

    const scrollCoordinates = () => {
        const deviceWidth = window.innerWidth;

        if (deviceWidth <= 500) {
            return {
                x: 1000,
                y: 500
            }
        }

        return {
            y: "90vh"
        };
    };

    if (loading) {
        return <Loading />
    }

    if (presetStatus === PricingState.PENDING) {
        return <DataLoading />
    }

    if (presetStatus === PricingState.EMPTY) {
        return <NoData />
    }

    return (
        <Table
            scroll={scrollCoordinates()}
            dataSource={recommendedData}
            components={vt}
            pagination={{
                defaultPageSize: 50,
            }}
            columns={columns}
            sticky
        />
    )
};

export default RecommendedPriceTable;
