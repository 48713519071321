import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { GasStationType } from './types';
import { gas, petrol } from "lib/helpers/fuelTypes";

import "./style.scss";

interface Props {
    tagsData: Array<GasStationType>
}



const GasStationTypes: React.FC<Props> = ({tagsData}) => {
    const {t} = useTranslation();
    const tags: Array<ReactNode> = [];
    let key = 550;

    tagsData.forEach((item) => {
        const code = item.code || 'fake';
        const classes = ['gas-station-types__item'];
        let colorClass = 'grey-tag';

        if (petrol.includes(t(`${code}`))) {
            colorClass = 'green-tag';
        }

        if (gas.includes(t(`${code}`))) {
            colorClass = 'blue-tag'
        }

        classes.push(colorClass);

        tags.push(
            <Tag key={key} className={classes.join(' ')}>
                { t(`reports.personalPrice.fuel.${item.code}`) } 
            </Tag>)
        key += 10;
    });

    return (
        <div className="gas-station-types">
            {tags}
        </div>
    )
};

export default GasStationTypes;

