import React from "react";
import {Dropdown, Menu} from "antd";
import { GlobalOutlined } from '@ant-design/icons';
import {useTranslation} from "react-i18next";

import "./style.scss";

interface Locale {
    code: string;
    title: string;
    icon: string;
}

const ChangeLang: React.FC = () => {
    const { i18n } = useTranslation();

    // Нужно заменить тип any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const changeLanguage = ({ key }: any) => {
        void i18n.changeLanguage(key);
    };

    const locales: Array<Locale> = [
        {
            code: 'ru',
            title: 'Русский',
            icon: '🇷🇺'
        },
        {
            code: 'en',
            title: 'English',
            icon: '🇺🇸'
        }
    ];

    const langMenu = (
        <Menu id="lang-list" className="menu" selectedKeys={[i18n.language]} onClick={changeLanguage}>
            {locales.map((locale) => (
                <Menu.Item key={locale.code}>
                    <span role="img" aria-label={locale.code}>
                        {locale.icon}
                    </span>{' '}
                    {locale.title}
                </Menu.Item>
            ))}
        </Menu>
    );

    const currentLanguage = locales.find((item) => item.code === i18n.language);
    const langIcon = currentLanguage ? currentLanguage.icon : <GlobalOutlined />;

    return (
        <Dropdown className="change-lang" overlay={langMenu} placement="bottomRight">
            <span className="dropdown">
                {langIcon}
            </span>
        </Dropdown>
    );
};

export default ChangeLang;
