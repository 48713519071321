import React from 'react';
import {useTranslation} from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';

import { GasStationColumnType, GasStationsTableProps } from 'lib/types';
import GasStationTypes from 'components/_common/PersonalGasStations/GasStationTypes';
import { getGasStationNumber } from 'lib/helpers';

interface Props {
    headers: Array<string>;
    loading: boolean;
    tableData: Array<GasStationColumnType>;
    tableProps?: GasStationsTableProps;
}

const GasStationsTable: React.FC<Props> = ({
    loading,
    tableData,
    tableProps,
}) => {

    const {t} = useTranslation();
    const width = window.innerWidth;
    let statusWidth: string | number = 110;
    let fuelTypesWidth: string | number = 200;

    const brandHeaderSize = (): number | string => {
        if (width < 500) {
            return '50vw';
        }

        return 120;
    };

    let columns: ColumnsType<GasStationColumnType> = [
        {
            title: t('gasStation.list.tabs.brand'),
            width: brandHeaderSize(),
            dataIndex: 'brandName',
            key: 'brandName',
            fixed: 'left',
            render: (value, item) => (
                <div>
                    {value}{`${getGasStationNumber(item.number)}` }
                    <br/>
                    <div className="brand__address">
                        {item.brandAddress}
                    </div>
                </div>
            ),
        },
        {
            title: t('gasStation.list.tabs.status'),
            width: statusWidth,
            dataIndex: 'status',
            key: 'status',
        },
        // TODO: Нужно как-то угадывать ширину блока fuelTypes в зависимости от количества элементов
        {
            title: t('gasStation.list.tabs.fuelTypes'),
            width: fuelTypesWidth,
            dataIndex: 'fuelTypes',
            key: 'fuelTypes',
            render: (value => (<GasStationTypes tagsData={value}/>)),
        },
    ];

    if (tableProps) {

        if (tableProps.hasOwnProperty('columnWidth')) {
            const { columnWidth } = tableProps;

            if (columnWidth.status) {
                statusWidth = columnWidth.status;
            }

            if (columnWidth.fuelTypes) {
                fuelTypesWidth = columnWidth.fuelTypes;
            }
        }

        if (tableProps.hasOwnProperty('forProfile') && tableProps.forProfile) {
            columns = [
                {
                    title: t('gasStation.list.tabs.brand'),
                    width: brandHeaderSize(),
                    dataIndex: 'brandName',
                    key: 'brandName',
                    fixed: 'left',
                    render: (value, item) => (
                        <div>
                            {value}{`${getGasStationNumber(item.number)}` }
                            <br/>
                            <div className="brand__address">
                                {item.brandAddress}
                            </div>
                        </div>
                    ),
                },
                {
                    title: t('gasStation.list.tabs.status'),
                    width: statusWidth,
                    dataIndex: 'status',
                    key: 'status',
                },
                {
                    title: t('gasStation.list.tabs.fuelTypes'),
                    width: fuelTypesWidth,
                    dataIndex: 'fuelTypes',
                    key: 'fuelTypes',
                    render: (value => (<GasStationTypes tagsData={value}/>)),
                },
            ];
        }
    }

    const scrollCoordinates = () => {
        const deviceWidth = window.innerWidth;

        if (deviceWidth <= 500) {
            return {
                x: 500,
                y: 500,
            };
        }

        return {
            x: 900,
            y: 500,
        };
    };

    return (
        <Table
            loading={loading}
            columns={columns}
            dataSource={tableData}
            pagination={false}
            scroll={scrollCoordinates()}
        />
    );
}

export default GasStationsTable;