import React from "react";
import { Empty } from 'antd';
import "./style.scss";

const InfoMessage: React.FC = () => {
    const message = (
        <div>
            <p><b>Здравствуйте! В настоящее время у Вас нет активных подписок. Чтобы начать пользоваться сервисом, просим связаться с нами по телефону +7 (495)    971-23-30 или по почте
                <a href="mailto:info@omt-consult.ru"> info@omt-consult.ru</a>
            </b></p>
        </div>
    );

    return (
        <div className="main-layout__content-message">
            <Empty
                className="content-message"
                description={message}
            />
        </div>
    );
}

export default InfoMessage;
