import "./style.scss";

import { ColumnGroupType, ColumnType } from "antd/lib/table";
import React, { Fragment, ReactNode, useCallback, useMemo } from "react";

import ActualPriceExtras from "./ActualPriceExtras";
import ActualPriceNestedTable from "components/_common/ActualPriceTable/ActualPriceNestedTable";
import ActualPricePicker from "./ActualPricePicker";
import ActualPriceReports from "./_extras/ActualPriceReports";
import { ActualPriceTableRow } from "./types";
import CardWrapper from "components/_common/CardWrapper";
import PageMainHeader from "components/_common/PageMainHeader";
import { Pagination } from "antd";
import PaginationSize from "components/_common/Pagination/PaginationSize";
import { useTranslation } from "react-i18next";

interface Props {
  data: ActualPriceTableRow[];
  total: number;
  loading: boolean;
  defaultPageSize: number;
  columns: (
    | ColumnGroupType<ActualPriceTableRow>
    | ColumnType<ActualPriceTableRow>
  )[];
  FilterByRegion: ReactNode;
  tableHeaders: Array<string>;
  listOffset: number;
  setDefaultPageSize: (value: ((prevState: number) => number) | number) => void;
  setListOffset: (value: ((prevState: number) => number) | number) => void;
  setOnFilters: (value: ((prevState: string) => string) | string) => void;
  setTableHeaders: (
    value: ((prevState: Array<string>) => Array<string>) | Array<string>
  ) => void;
  currentPage: number;
  setCurrentPage: (value: ((prevState: number) => number) | number) => void;
}

const ActualPriceComponent: React.FC<Props> = ({
    data,
    total,
    loading,
    columns,
    defaultPageSize,
    FilterByRegion,
    tableHeaders,
    setDefaultPageSize,
    setListOffset,
    setTableHeaders,
    setOnFilters,
    setCurrentPage,
    currentPage,
}) => {
    const { t } = useTranslation();
    const title = t("reports.actualPrice.title");

    const paginationChange = useCallback(
        (page: number) => {
            setCurrentPage(page);
            setListOffset(() => defaultPageSize * (page - 1));
        },
        [defaultPageSize, setListOffset]
    );

    const pagination = useMemo(() => {
        return (
            <Pagination
                total={total}
                showSizeChanger={false}
                current={currentPage}
                pageSize={defaultPageSize}
                onChange={paginationChange}
                defaultPageSize={defaultPageSize}
            />
        );
    }, [total, paginationChange, currentPage, defaultPageSize]);

    return (
        <Fragment>
            <PageMainHeader title={title} />

            <CardWrapper>
                <div className="card-table__control-panel">
                    <div className="card-table__region-select">{FilterByRegion}</div>

                    <div className="card-table__control-panel-right">
                        <div className="card-table__control-panel-item">
                            <ActualPriceReports />
                        </div>
                        <div className="card-table__control-panel-item">
                            <ActualPriceExtras
                                fuelStorageName="selectedFuels"
                                setTableHeaders={setTableHeaders}
                            />
                        </div>
                    </div>
                </div>

                <div className="card-table__mobile-picker">
                    <ActualPricePicker />
                </div>

                <div className="actual-price-table">
                    <ActualPriceNestedTable
                        data={data}
                        zeroHeader="brand"
                        loading={loading}
                        columns={columns}
                        tableHeaders={tableHeaders}
                        setOnFilters={setOnFilters}
                    />
                </div>

                <div className="gas-station-table-pagination">
                    <PaginationSize
                        setListOffset={setListOffset}
                        setCurrentPage={setCurrentPage}
                        setDefaultPageSize={setDefaultPageSize}
                    />
                    {pagination}
                </div>
            </CardWrapper>
        </Fragment>
    );
};

export default ActualPriceComponent;
