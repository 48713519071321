import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { isEnvForDebug } from 'lib/helpers';

import enTranslation from "locales/en/translation"
import ruTranslation from "locales/ru/translation"

const resources = {
    en: {
        translation: enTranslation,
    },
    ru: {
        translation: ruTranslation,
    }
};

void i18n.use(Backend).use(languageDetector).use(initReactI18next).init({
    fallbackLng: 'ru',
    resources,
    debug: isEnvForDebug(),
    detection: {
        order: ['localStorage', 'queryString', 'cookie'],
        cache: ['cookie']
    },
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
