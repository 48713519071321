import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useVT } from "virtualizedtableforantd4";

import { ActualPriceTableRow } from "components/ActualPriceComponent/types";
import { eachRowClass } from "lib/helpers/actualPrices";
import { ColumnGroupType, ColumnType } from "antd/lib/table";

interface Props {
  loading: boolean;
  zeroHeader: string;
  data: Array<ActualPriceTableRow>;
  setOnFilters: (value: ((prevState: string) => string) | string) => void;
  columns: (
    | ColumnGroupType<ActualPriceTableRow>
    | ColumnType<ActualPriceTableRow>
  )[];
}

const ActualPriceTable: React.FC<Props> = ({
    loading,
    zeroHeader,
    data,
    columns,
    setOnFilters,
}) => {
    const [tableData, setTableData] = useState<ActualPriceTableRow[]>(
        data.slice()
    );

    const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

    useEffect(() => {
        setTableData(data.slice());
    }, [data]);

    const scrollCoordinates = () => {
        const deviceWidth = window.innerWidth;

        if (deviceWidth <= 500) {
            return {
                x: 1000,
                y: 500,
            };
        }

        return {
            y: "calc(100vh - 150px)",
        };
    };

    const sortPrices = (
        items: Array<ActualPriceTableRow>,
        field: string,
        sortDirection: "descend" | "ascend" | undefined | null
    ): Array<ActualPriceTableRow> => {
        const compare = (a: ActualPriceTableRow, b: ActualPriceTableRow) => {
            let direction = 1;

            const fieldA = a.products.hasOwnProperty(field)
                ? a.products[field].price
                : 0;

            const fieldB = b.products.hasOwnProperty(field)
                ? b.products[field].price
                : 0;

            if (sortDirection === "ascend") {
                direction = -1;
            }

            return a.groupId - b.groupId || (fieldA - fieldB) * direction;
        };

        if (sortDirection === undefined) {
            return data.slice();
        }

        return items.sort(compare);
    };

    return (
        <Table
            scroll={scrollCoordinates()}
            components={vt}
            columns={columns}
            dataSource={tableData}
            showSorterTooltip={false}
            sticky
            rowClassName={eachRowClass}
            pagination={false}
            loading={loading}
            onChange={(_pagination, _filters, sorter) => {
                // проверяем объект sorter и делаем сортировку
                // columnKey: "ai98b"
                // field: "ai98b"
                // order: "descend" | "ascend" | undefined

                let result: Array<ActualPriceTableRow> = [];

                if (!Array.isArray(sorter) && sorter.columnKey !== undefined) {
                    result = sortPrices(
                        tableData,
                        sorter.columnKey.toString(),
                        sorter.order
                    );

                    if (zeroHeader !== "tankFarm") {
                        setOnFilters(sorter.columnKey.toString());
                        if (sorter.order === undefined) {
                            setOnFilters("");
                        }
                    }

                    for (let j = 0; j < result.length; j += 1) {
                        result[j].border = null;
                    }

                    let tempGroup = {
                        id: result[0].groupId,
                        count: 0,
                    };

                    for (let i = 0; i < result.length; i += 1) {
                        if (tempGroup.id !== result[i].groupId) {
                            result[i - 1].border = "bottom";
                            result[i].border = "top";

                            if (tempGroup.count === 1) {
                                result[i - 1].border = "both";
                            }

                            tempGroup = {
                                id: result[i].groupId,
                                count: 1,
                            };
                        } else {
                            tempGroup.count += 1;
                        }
                    }

                    result[0].border = "top";
                    result[result.length - 1].border = "bottom";
                }

                setTableData(result);
            }}
        />
    );
};

export default ActualPriceTable;
